<template>
  <div id="app" :class="{'test-mode': isTestMode, 'mobile-app': isNativePlatform || isMobileAppSimulationMode, 'web-app': !(isNativePlatform || isMobileAppSimulationMode)}" >
    <app-master-main @backToHome="backToHome" @showAccount="showAccount=true" @showLogin="showLogin" @disconnect="Logout" @doSynchronizeNow="doSynchronizeNow">
      <template #environment-marker>
            <hr class="env env-top" :class="environment" />
      </template>
      <template #new-version-marker>
        <b-alert variant="warning" v-if="newVersion!='version'">{{ $t("new-version-available", newVersion) }}</b-alert>
        <div>version : {{ version }} - new version : {{ newVersion }}</div>
      </template>
      <template #synchro-indicator-placeholder>
        <b-nav-item right class="synchro" :class="{'required': needSynchro}" @click="doSynchronizeNow">
          <b-icon icon="exclamation-triangle-fill" variant="danger" style="width: 1.5em; height:1.5em;" v-if="serverNotResponding"></b-icon>
          <!--<b-icon :icon="needSynchro ? 'cloud-slash' : 'cloud-check'" style="width: 1.5em; height: 1.5em;" />-->
          <b-badge variant="danger" pill v-text="nbLogs" v-show="nbLogs > 0" />
        </b-nav-item>
      </template>
      <template #test-mode-indicator>
        <div class="test-mode" v-if="isTestMode">TEST-MODE</div>
      </template>
      <template #impersonation-mode-indicator>
        <div class="test-mode impersonated-user" v-if="isImpersonated">EMPRUNT D'IDENTITE</div>
      </template>
      <template #authenticated-icon-placeholder>
      </template>
      <template #sidebar-menu-placeholder="scope" >
        <b-sidebar 
          id="sidebar-menu"
          title="Menu"
          shadow
          :no-header="scope.isMobileApp=='true'"
          backdrop
          bg-variant="light"
          text-variant="dark"
          sidebar-class="border-right border-danger"
          lazy
        >
          <mobile-app-area>
            <b-icon icon="gear" style="float:right; margin-top:1em; margin-right:1em; width:1.5em; height: 1.5em; " @click="showConfiguration" />
            <b-container style="display:flex; justify-content:center; " v-b-toggle.sidebar-menu>
              <div class="app-icon">
                <img src="@/assets/FFVL_mono.png" style="height: 5em; margin:2px; margin-right:0.5em;" />
                <br/>
                <span class="version" style="font-size:0.8em; margin-right:1em; padding-right: 5px;float:right;">v.{{ version }}</span>
              </div>
            </b-container>
            <b-container style="display:flex; flex-direction: column; align-items: center;"  v-b-toggle.sidebar-menu>
              <span class="app-title" style="font-size: 1.3em;">Compétition Cerf-Volant</span>
            </b-container>
            <div style="display:flex; flex-direction: column; align-items: center; margin:1em 2em; padding:8px 3px; border:solid 1px white; border-radius: 1em;" v-if="!isAuthenticated" >
              <b-avatar variant="secondary" />
              <b-button @click="showLogin">
                <b-icon icon="person" />&nbsp;Connexion
              </b-button>
            </div>
            <div v-else style="display:flex; flex-direction: column; align-items:center; margin:1em 2em; padding:8px 3px; border:solid 1px white; border-radius: 1em;">
              <b-avatar @click="showAccount=true" variant="primary"></b-avatar>
              <div :class="{'impersonated-user' : isImpersonated}" style="border:solid 1px black; border-radius: 10px; padding:1px 5px;" @click="showAccount=true">
                <span style="font-size:1.1em; ">{{ currentUserFirstname }}&nbsp;</span>
                <b-icon icon="pencil" style="color:"></b-icon>
              </div>
              <div style="display:flex; justify-content: space-around;">
                <b-button @click="Logout" style="font-size:0.9em; font-weight: lighter;">
                  <b-icon icon="power" variant="danger" />&nbsp;Déconnexion
                </b-button>
                <secured-area :operations="['IMPERSONATE']" :hideWhenAccessIsRejected="true">
                  <b-button @click="showImpersonationDialog=true" style="font-size:0.9em; font-weight: lighter;">
                    <b-icon />&nbsp;Emprunt d'identité
                  </b-button>
                </secured-area>
              </div>
            </div>
          </mobile-app-area>
          <ul>
            <left-aside-menu-content :displayConfiguration="!scope.isMobileApp" />
          </ul>
        </b-sidebar>
      </template>
      <template v-slot:default>
        <router-view></router-view>
      </template>
    </app-master-main>

    
    

    <my-account-dialog v-model="showAccount" @hide="showAccount = false" />
    <impersonation-dialog v-model="showImpersonationDialog" @hide="showImpersonationDialog = false; backToHome();" />

      <login-dialog ref="loginDialog">
      </login-dialog>
    

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SynchroHelper from "@/services/helpers/synchroHelper";
import LoginDialog from '@/components/Dialogs/LoginDialog.vue';
import ImpersonationDialog from '@/components/Dialogs/ImpersonationDialog.vue';
import { RST_LOG } from "@/LocalDB";
import SecuredArea from "@/components/Security/NewSecuredArea";
//import NavBarMenu from '@/components/Utilities/NavBarMenu.vue';
//import WebArea from './components/WebArea.vue';
import MobileAppArea from './components/MobileAppArea.vue';
import MyAccountDialog from './components/Dialogs/MyAccountDialog.vue';
import LeftAsideMenuContent from '@/components/PageTemplates/LeftAsideMenuContent.vue';
//import SideBarMenu from '@/components/Panels/SideBarMenu.vue';
import AppMasterMain from '@/views/AppMasterMain.vue';
export default {
  name: 'App',
  components: { AppMasterMain, LeftAsideMenuContent , /*SideBarMenu, */ LoginDialog, SecuredArea, /* NavBarMenu, WebArea,*/ MobileAppArea, ImpersonationDialog, MyAccountDialog },
  data(){
    return {
      showAccount: false,
      loginShown: false,
      showImpersonationDialog: false,
    }
  },
  computed:{
    ...mapGetters('auth', ['isAuthenticated', 'isImpersonated', 'currentUserFullname', 'userHasOneOfRoles', 'currentUserFirstname' ]),
    ...mapGetters('config', ['isTestMode', 'isDevEnvironment', 'isProdEnvironment', 'isNativePlatform', 'environment', 'isDebugMode', 'isMobileAppSimulationMode' ]),
    needSynchro(){
      return SynchroHelper.IsThereRemainingLogsToSynchronize();
    },
    nbLogs(){
      return RST_LOG.all().length;
    },
    newVersion(){
      var v = this.getDownloadableAppVersions();
      console.log('newVersion', v);
      return v;
    }
  },
  methods:{
    ...mapGetters('config', ['serverBaseUrl']),
    ...mapActions('auth', ['logout']),
    ...mapActions('synchro', ['getDownloadableAppVersions']),
    getServerBaseUrl(){
      return this.serverBaseUrl;
    },
    /**
     * @param {string} url
     */
    getUrl(url)
    {
      return this.serverBaseUrl + '' + url;
    },
    async backToHome(){
      if(this.$route.name != 'Index' && this.$route.name != 'Home'){
        await this.$showLoader();
        this.$router.push({ name: 'Home'});
        this.$hideLoader();
      }
    },
    showLogin(){
      this.$refs["loginDialog"].show();
    },
    async Logout(){
      await this.$showLoader();
      this.showAccount = false;
      this.logout();
      if(this.$route.name != 'Home')
        this.$router.push({ name: 'Home' });
      this.$hideLoader();
    },
    doSynchronizeNow(){
      this.$showLoader()
        .then(() => {
          SynchroHelper.TryToSynchronize();
          this.$hideLoader();
        })
    },
    showConfiguration(){
      this.$router.push({ name: 'ConfigurationHome'});
    }
  },
}
</script>

<style>

@media screen and (min-width: 1600px){
  html{font-size: 14pt; }
}
@media screen and (max-width: 1300px){
  html{font-size: 12pt;  } body{ border: solid 1px magenta;}
}
@media screen and (max-width: 1200px){
  html{font-size: 14pt;  } body{ border: solid 1px darkgreen;}
}
@media screen and (max-width: 1100px){
  html{font-size: 13pt;  } body{ border: solid 1px red;}
}
@media screen and (max-width: 1000px){
  html{font-size: 11pt;  } body{ border: solid 1px pink;}
}
@media screen and (max-width: 800px){
  html{font-size: 12pt;  } body{ border: solid 1px navy;}
}
@media screen and (max-width: 800px){
  html{font-size: 10pt;  } body{ border: solid 1px aliceblue;}
}
@media screen and (max-width:700px){
  html{font-size: 9pt;  } body{ border: solid 1px violet;}
  .b-sidebar { font-size:1.1em; }
  .b-sidebar-body ul, .b-sidebar-body a { font-size: 13pt; margin-top:2px; margin-bottom: 1px; }
  div.header{ font-size: 11px; }
  .page-header ul { font-size: 10pt;}
}
@media screen and (max-width:600px){
  html{font-size: 9pt;  } body{ border: solid 2px brown;}
  .b-sidebar { font-size:1.2em; }
  .b-sidebar-body ul, .b-sidebar-body a { font-size: 14pt; margin-top:2px; margin-bottom: 1px; }
  div.header{ font-size: 12px; }
  .page-header ul { font-size: 11pt;}
}
@media screen and (max-width:500px) {
  html{ font-size: 9pt; } body{ border: solid 2px green;}
  .b-sidebar-body ul, .b-sidebar-body a { font-size: 13pt; margin-top:2px; margin-bottom: 1px; }
  div.header{ font-size: 12px; }
  .page-header { font-size: 11pt;}
}
@media screen and (max-width:400px) {
  html{ font-size: 9pt; } body{ border: solid 2px yellow;}
  .b-sidebar-body ul, .b-sidebar-body a { font-size: 12pt; margin-top:2px; margin-bottom: 1px; }
  div.header{ font-size: 12px; }
  .page-header ul { font-size: 11pt;}
}
/*






*/
</style>

