<template>
    <span class="capitalize"><slot></slot></span>
</template>

<script>
export default{}
</script>

<style>
    .capitalize{ text-transform: capitalize; }
</style>