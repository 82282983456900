import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate";
import VuexORM from "@vuex-orm/core";

import auth from './modules/auth';
import config from "./modules/config";
import synchro from "./modules/synchro";
import current from "./modules/current";

import {
    DATA_SYNC_SUBSCRIPTIONS,
    REF_REGULATION,
    REF_PENALTY,
    REF_REGISTRATION_MODE,
    REF_RESULT_CALCULATION_MODE,
    REF_CATEGORY,
    REF_COMPETITION_TYPE,
    REF_COMPETITION_TYPE_SCOPE,
    REF_CHAMPIONSHIP,
    REF_COMPULSORY,
    REF_EVENT,
    REF_LEVEL,
    REF_RULE,
    REF_SCOPE,
    REF_GN_NOTATION_GRID,
    REF_GN_CRITERION,
    REF_GN_PENALTY,
    PEOPLE,
    STAFF,
    STAFF_CAPABILITY,
    COMPETITOR,
    COMPETITOR_MEMBER,
    SEASON,
    SEASON_COMPETITION_TYPE_SCOPE,
    SEASON_COMPETITION_TYPE_SCOPE_LEVEL,
    SEASON_COMPETITION_TYPE_SCOPE_EVENT,
    SEASON_COMPETITION_TYPE_CATEGORY_LEVEL,
    SEASON_CHAMPIONSHIP_SCOPE,
    SEASON_COMPULSORY,
    SEASON_RULE,
    SEASON_RULE_EVENT,
    LICENSEE,
    RST_COMPETITION,
    RST_COMPETITION_STAFF,
    RST_REF_EVENT,
    RST_REF_CATEGORY,
    RST_COMPETITOR,
    RST_COMPETITOR_COMPOSITION,
    RST_ROUND,
    RST_ROUND_COMPETITOR,
    RST_EVENT_COMPULSORY,
    RST_LEVEL,
    RST_EVENT_COMPETITOR,
    RST_BALLET_NOTE,
    RST_COMPULSORY_NOTE,
    RST_ROUTINE_NOTE,
    RST_CATEGORY,
    RST_EVENT,
    RST_EVENT_STAFF,
    RST_FIELD,
    RST_DELEGATE,
    RST_LOG,
    VISA,
    RST_COMPETITION_ADMIN,
  } from "@/LocalDB";

Vue.use(Vuex);

const database = new VuexORM.Database();

database.register(DATA_SYNC_SUBSCRIPTIONS);
database.register(REF_REGULATION);
database.register(REF_PENALTY);
database.register(REF_REGISTRATION_MODE);
database.register(REF_RESULT_CALCULATION_MODE);
database.register(REF_CATEGORY);
database.register(REF_COMPETITION_TYPE);
database.register(REF_COMPETITION_TYPE_SCOPE);
database.register(REF_CHAMPIONSHIP);
database.register(REF_COMPULSORY);
database.register(REF_EVENT);
database.register(REF_LEVEL);
database.register(REF_RULE);
database.register(REF_SCOPE);
database.register(REF_GN_NOTATION_GRID);
database.register(REF_GN_CRITERION);
database.register(REF_GN_PENALTY);
//--------------
database.register(PEOPLE);
database.register(STAFF);
database.register(STAFF_CAPABILITY);
database.register(COMPETITOR);
database.register(COMPETITOR_MEMBER);
//--------------
database.register(SEASON);
database.register(SEASON_COMPETITION_TYPE_SCOPE);
database.register(SEASON_COMPETITION_TYPE_SCOPE_LEVEL);
database.register(SEASON_COMPETITION_TYPE_SCOPE_EVENT);
database.register(SEASON_COMPETITION_TYPE_CATEGORY_LEVEL);
database.register(SEASON_CHAMPIONSHIP_SCOPE);
database.register(SEASON_COMPULSORY);
database.register(SEASON_RULE);
database.register(SEASON_RULE_EVENT);
database.register(LICENSEE);
//--------------
database.register(RST_COMPETITION);
database.register(RST_FIELD);
database.register(RST_COMPETITION_STAFF);
database.register(RST_REF_EVENT);
database.register(RST_REF_CATEGORY);
database.register(RST_COMPETITOR);
database.register(RST_COMPETITOR_COMPOSITION);
database.register(RST_ROUND_COMPETITOR);
database.register(RST_ROUND);
database.register(RST_EVENT_COMPULSORY);
database.register(RST_LEVEL),
database.register(RST_CATEGORY);
database.register(RST_EVENT);
database.register(RST_EVENT_STAFF);
database.register(RST_EVENT_COMPETITOR);
database.register(RST_BALLET_NOTE);
database.register(RST_COMPULSORY_NOTE);
database.register(RST_ROUTINE_NOTE);
database.register(RST_LOG);
database.register(VISA);
database.register(RST_COMPETITION_ADMIN);
database.register(RST_DELEGATE);

const storeInstance = new Vuex.Store({
    modules: {
        config,
        auth,
        current,
        synchro,
    },
    plugins: [
        VuexORM.install(database),
        createPersistedState()
    ]
});
export default storeInstance;
export const store = storeInstance;
