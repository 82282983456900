<template>
  <div>
    <b-modal
         centered
        scrollable
        :static="true"
        :lazy="true"
        size="xl"
        v-if="d_visible"
        v-model="d_visible"
        @hide="onHide()"
        button-size="lg"
        :cancel-title="$t('button.cancel')"
        :ok-title="$t('button.ok')"
    >
      <template #modal-title>
        <b-icon icon="person" /> <capitalize>{{ $t("dialogs.my-account.title")}}</capitalize>
      </template>
    
      <collapse-panel>
        <template #title><capitalize>{{ $t('dialogs.my-account.login-panel.title') }}</capitalize></template>
        <form-field :caption="$t('dialogs.my-account.login-panel.login')" :required="true">
          <template #info-helper-placeholder>
            <info-helper>
              <template v-html="$t('dialogs.my-account.login-panel.login-info-helper')"></template>
            </info-helper>
          </template>
          <b-input type="text" readonly v-model="currentUser.username" placeholder="Login" />
        </form-field>
        <b-button size="xs" :disabled="isImpersonated" variant="primary" @click="passwordChangeDialogVisible=true">{{ $t("dialogs.my-account.login-panel.modify-button") }}</b-button>
      </collapse-panel>

        <collapse-panel>
          <template #title><capitalize>{{  $t('dialogs.my-account.marital-status-panel.title') }}</capitalize></template>
          <form-field :caption="$t('dialogs.my-account.marital-status-panel.lastname')" :required="true">
            <b-input type="text" readonly v-model="currentUser.lastname" placeholder="Nom" />
          </form-field>
          <form-field :caption="$t('dialogs.my-account.marital-status-panel.firstname')">
            <b-input type="text" readonly v-model="currentUser.firstname" placeholder="Prénom" />
          </form-field>
          <form-field :caption="$t('dialogs.my-account.marital-status-panel.gender')">
            <b-input type="text" readonly v-model="currentUserSexeLabel" placeholder="Sexe" />
          </form-field>
          <form-field unplugged :caption="$t('dialogs.my-account.marital-status-panel.birthdate')">
            <b-input type="text" readonly />
          </form-field>
          <b-button size="xs" :disabled="isImpersonated" variant="primary" @click="maritalStatusDialogVisible=true">{{ $t('dialogs.my-account.marital-status-panel.modify-button')}}</b-button>
        </collapse-panel>

        <collapse-panel>
          <template #title><capitalize>{{ $t('dialogs.my-account.contactme-panel.title') }}</capitalize></template>
          <form-field unplugged :caption="$t('dialogs.my-account.contactme-panel.email')" :required="true">
            <b-input type="mail" readonly />
          </form-field>
          <form-field unplugged :caption="$t('dialogs.my-account.contactme-panel.mobile-phone-number')">
            <b-input type="phone" readonly />
          </form-field>
          <form-field unplugged :caption="$t('dialogs.my-account.contactme-panel.home-phone-number')">
            <b-input type="phone" readonly />
          </form-field>
          <b-button size="xs" :disabled="isImpersonated" variant="primary" @click="contactMeInfoDialogVisible=true">Corriger mes informations de contact</b-button>
          <form-field unplugged :caption="$t('dialogs.my-account.contactme-panel.fb-messenger')">
            <b-input type="phone" readonly />
          </form-field>
          <b-button size="xs" :disabled="isImpersonated" variant="primary" @click="contactmeDialogVisible=true">{{ $t('dialogs.my-account.contactme-panel.modify-button') }}</b-button>
        </collapse-panel>

        <b-button size="xs" variant="outline-secondary" @click="ppdpVisible=true"><capitalize>{{ $t('dialogs.my-account.privacy-link-button') }}</capitalize></b-button>
        
        <collapse-panel>
          <template #title><capitalize>{{  $t('dialogs.my-account.application-roles-panel.title') }}</capitalize></template>
          <ul><li v-for="role in currentUserRoles" :key="role">{{ role}}</li></ul>
        </collapse-panel>
    </b-modal>

    <ppdp-dialog
      :visible="ppdpVisible"
      @hide="ppdpVisible=false"
    ></ppdp-dialog>

    <change-password-dialog
      :visible="passwordChangeDialogVisible"
      @hide="passwordChangeDialogVisible=false"
    ></change-password-dialog>

    <marital-status-dialog
      :visible="maritalStatusDialogVisible"
      @hide="maritalStatusDialogVisible=false"
    ></marital-status-dialog>

    <contact-me-info-dialog
      :visible="contactMeInfoDialogVisible"
      @hide="contactMeInfoDialogVisible=false"
    ></contact-me-info-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FormField from '@/components/Panels/FormField.vue';
import CollapsePanel from '@/components/Panels/CollapsePanel.vue';
import InfoHelper from '@/components/InfoHelper.vue';
import PpdpDialog from "@/components/Dialogs/PpdpDialog.vue";
import ChangePasswordDialog from "@/components/Dialogs/ChangePasswordDialog.vue";
import MaritalStatusDialog from "@/components/Dialogs/MaritalStatusDialog.vue";
import ContactMeInfoDialog from "@/components/Dialogs/ContactMeInfoDialog.vue";
import Capitalize from '../Utilities/Capitalize.vue';

export default {
  model:{
    prop: 'visible',
    event: 'change'
  },
  props: { visible: { type:Boolean, default: false}},
  components: { FormField, CollapsePanel, InfoHelper, PpdpDialog, ChangePasswordDialog, MaritalStatusDialog, ContactMeInfoDialog, Capitalize },
  data(){
    return {
      d_visible: this.visible,
      ppdpVisible: false,
      passwordChangeDialogVisible: false,
      maritalStatusDialogVisible: false,
      contactMeInfoDialogVisible: false,
    }
  },
  computed:{
    ...mapGetters('auth', ['isAuthenticated', 'isImpersonated' ]),
    currentUserSexeLabel(){
      return '';
    }
  },
  methods: {
    onHide(){
      this.$emit('change');
      this.$emit('hide');
    }
  },
  watch:{
    visible: function(newValue){
      this.d_visible = newValue;
    },
  }
}
</script>
