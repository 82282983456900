import Vue from 'vue'
import Vuex from 'vuex'
import { Capacitor } from "@capacitor/core";
import { store } from '..';

Vue.use(Vuex)

const config = {
    get defaultProdServerProtocol() { return 'https'; },
    get defaultProdServerHost(){ return 'cv-competition.ffvl.fr'; },
    get defaultProdServerBaseUrl(){ return this.defaultProdServerProtocol + '://' + this.defaultProdServerHost; },
    get defaultTestServerProtocol() {return 'https';},
    get defaultTestServerHost() { return 'cv-competition-dev.ffvl.fr'},
    get defaultTestServerBaseUrl(){ return this.defaultTestServerProtocol + '://' + this.defaultTestServerHost; },
    get isNativePlatform(){ return Capacitor.isNativePlatform(); },
    get environment(){
        if(location.host == this.defaultProdServerHost)
            return "production";
        if(location.host == this.defaultTestServerHost)
            return "tests";
        if(this.isNativePlatform)
        {
            console.log('isNativePlatform', store.getters["config/serverBaseUrl"], this.defaultProdServerBaseUrl);
            if(store.getters["config/serverBaseUrl"] == this.defaultProdServerBaseUrl)
                return "production";
            if(store.getters["config/serverBaseUrl"] == this.defaultTestServerBaseUrl)
                return "tests";
            return "unknown";
        }
        return "DEBUG";
    },
    get isDebugMode(){
        return process.env.NODE_ENV == "development";
    },
    get isDevEnvironment(){ 
        return location.host != this.defaultProdServerHost;
    },
    get isProdEnvironment(){ return location.host == this.defaultProdServerHost; },
    namespaced: true,
    get locales(){ return ['fr', 'en']; },
    state : {
        _locale: 'fr',
        _verboseNoteSummary: false,
        _confirmNoteEntryByPopup : true,
        _skipNoteByComponent : false,
        _serverBaseUrl: 'https://cv-competition.ffvl.fr',
        _isTestMode: false,
        _checkNoteOfOtherJudgesDelay: 5000,
        _byPassSecurity: false,
        _simulMobileApp: false,
        _homePage: {
            predictedCompetitionsExpanded: true,
            runningCompetitionsExpanded: true,
            completedCompetitionsExpanded: false,
            missedCompetitionsExpanded: false,
        },
        _balletNoteEditor: {
            executionPaneVisible: true,
            choregraphyPaneVisible: true,
            penaltiesPaneVisible: false,
            commentsPaneVisible: false,
        },
        _routineNoteEditor: {
            executionPaneVisible: true,
            contentPaneVisible: true,
            penaltiesPaneVisible: false,
            commentsPaneVisible: false,
        },
        _compulsoryNoteEditor: {
            compulsoryPaneVisible: true,
            compulsoryPaneIncludeModel: true,
            compulsoryPaneIncludeText: false,
            penaltiesPaneVisible: false,
            commentsPaneVisible: false,
        },
        _competitionEditWizard: {
            showPresentCompetitorsOnly: false,
        }
    },
    getters : {
        cfgVerboseNoteSummary: (state) => state._verboseNoteSummary,
        cfgConfirmNoteEntryByPopup: (state) => state._confirmNoteEntryByPopup,
        cfgSkipNoteByComponent: (state) => state._skipNoteByComponent,
        cfgDelayBetweenCallsToCheckNotesOfOtherJudges: (state) => state._checkNoteOfOtherJudgesDelay,

        cfgHomePredictedCompetitionsExpandes: (state) => state._homePage.predictedCompetitionsExpanded,
        cfgHomeMissedCompetitionsExpandes: (state) => state._homePage.missedCompetitionsExpanded,
        cfgHomeRunningCompetitionsExpandes: (state) => state._homePage.runningCompetitionsExpanded,
        cfgHomeCompletedCompetitionsExpandes: (state) => state._homePage.completedCompetitionsExpanded,

        serverBaseUrl: (state) => config.isNativePlatform ? (state._serverBaseUrl || this.defaultServerBaseUrl) : '',
        getUrl: (state) => (url) => {
          var ret = (config.isNativePlatform ? (state._serverBaseUrl || this.defaultServerBaseUrl) : '') + url;
          console.log('getUrl', { url: url, result: ret });
          return ret;
        },
        isTestMode: (state) => state._isTestMode,
        isNativePlatform: () => config.isNativePlatform,
        isDevEnvironment: () => config.isDevEnvironment,
        isProdEnvironment: () => config.isProdEnvironment,
        isMobileAppSimulationMode: (state) => config.isDebugMode && state._simulMobileApp,
        environment: () => config.environment,
        isDebugMode: () => config.isDebugMode,
        isMobileApp:() =>  config.isNativePlatform || this.isMobileAppSimulationMode,
        cfgByPassSecurity : (state) => config.isDebugMode ? state._byPassSecurity : false,

        cfgCompetitionWizard_showPresentCompetitorsOnly: (state) => state._competitionEditWizard.showPresentCompetitorsOnly,
        cfgBalletNoteEditor_executionPaneVisible: (state) => state._balletNoteEditor.executionPaneVisible,
        cfgBalletNoteEditor_choregraphyPaneVisible: (state) => state._balletNoteEditor.choregraphyPaneVisible,
        cfgBalletNoteEditor_penaltiesPaneVisible: (state) => state._balletNoteEditor.penaltiesPaneVisible,
        cfgBalletNoteEditor_commentsPaneVisible: (state) => state._balletNoteEditor.commentsPaneVisible,

        cfgRoutineNoteEditor_executionPaneVisible: (state) => state._routineNoteEditor.executionPaneVisible,
        cfgRoutineNoteEditor_contentPaneVisible: (state) => state._routineNoteEditor.contentPaneVisible,
        cfgRoutineNoteEditor_penaltiesPaneVisible: (state) => state._routineNoteEditor.penaltiesPaneVisible,
        cfgRoutineNoteEditor_commentsPaneVisible: (state) => state._routineNoteEditor.commentsPaneVisible,

        cfgCompulsoryNoteEditor_compulsoryPaneVisible: (state) => state._compulsoryNoteEditor.compulsoryPaneVisible,
        cfgCompulsoryNoteEditor_compulsoryPaneIncludeModel: (state) => state._compulsoryNoteEditor.compulsoryPaneIncludeModel,
        cfgCompulsoryNoteEditor_compulsoryPaneIncludeText: (state) => state._compulsoryNoteEditor.compulsoryPaneIncludeText,
        cfgCompulsoryNoteEditor_penaltiesPaneVisible: (state) => state._compulsoryNoteEditor.penaltiesPaneVisible,
        cfgCompulsoryNoteEditor_commentsPaneVisible: (state) => state._compulsoryNoteEditor.commentsPaneVisible,

        locale: (state) => state._locale,

    },
    actions : {
    },
    mutations : {
        setCfgVerboseNoteSummary(state, value){
            state._verboseNoteSummary = value;
        },
        setCfgConfirmNoteEntryByPopup(state, value){
            state._confirmNoteEntryByPopup = value;
        },
        setCfgSkipNoteByComponent(state, value){
            state._skipNoteByComponent = value;
        },
        setCfgDelayBetweenCallsToCheckNotesOfOtherJudges(state, value){
            state._checkNoteOfOtherJudgesDelay = value;
        },

        setCfgHomePredictedCompetitionsExpandes (state, value) {state._homePage.predictedCompetitionsExpanded = value},
        setCfgHomeMissedCompetitionsExpandes (state, value) {state._homePage.missedCompetitionsExpanded = value},
        setCfgHomeRunningCompetitionsExpandes (state, value) {state._homePage.runningCompetitionsExpanded = value},
        setCfgHomeCompletedCompetitionsExpandes (state, value) {state._homePage.completedCompetitionsExpanded = value},

        setCfgCompetitionWizard_showPresentCompetitorsOnly (state, value) {state._competitionEditWizard.showPresentCompetitorsOnly = value},

        setCfgBalletNoteEditor_executionPaneVisible(state, value) { state._balletNoteEditor.executionPaneVisible = value},
        setCfgBalletNoteEditor_choregraphyPaneVisible(state, value) { state._balletNoteEditor.choregraphyPaneVisible = value},
        setCfgBalletNoteEditor_penaltiesPaneVisible(state, value) { state._balletNoteEditor.penaltiesPaneVisible = value},
        setCfgBalletNoteEditor_commentsPaneVisible(state, value) { state._balletNoteEditor.commentsPaneVisible = value},
        
        setCfgRoutineNoteEditor_executionPaneVisible(state, value) { state._routineNoteEditor.executionPaneVisible = value},
        setCfgRoutineNoteEditor_contentPaneVisible(state, value) { state._routineNoteEditor.contentPaneVisible = value},
        setCfgRoutineNoteEditor_penaltiesPaneVisible(state, value) { state._routineNoteEditor.penaltiesPaneVisible = value},
        setCfgRoutineNoteEditor_commentsPaneVisible(state, value) { state._routineNoteEditor.commentsPaneVisible = value},
        
        setCfgCompulsoryNoteEditor_compulsoryPaneVisible(state, value) { state._compulsoryNoteEditor.compulsoryPaneVisible = value},
        setCfgCompulsoryNoteEditor_compulsoryPaneIncludeModel(state, value) { state._compulsoryNoteEditor.compulsoryPaneIncludeModel = value},
        setCfgCompulsoryNoteEditor_compulsoryPaneIncludeText(state, value) { state._compulsoryNoteEditor.compulsoryPaneIncludeText = value},
        setCfgCompulsoryNoteEditor_penaltiesPaneVisible(state, value) { state._compulsoryNoteEditor.penaltiesPaneVisible = value},
        setCfgCompulsoryNoteEditor_commentsPaneVisible(state, value) { state._compulsoryNoteEditor.commentsPaneVisible = value},
        
        setServerBaseUrl(state, value){
            console.log('setServerBaseUrl', state, value);
            state._serverBaseUrl = value;
        },
        setTestMode(state, value){
            console.log('setTestMode', value);
            state._isTestMode = value;
        },
        setCfgByPassSecurity(state, value){
            state._byPassSecurity = value;
        },
        setMobileAppSimulationMode(state, value){
            if(config.isDebugMode)
                state._simulMobileApp = value;
        },

        setLocale(state, value){
            if(config.locales.includes(value)){
                state._locale = value;
            }
        }
    }
};

export default config;

