<template>
  <div v-if="isMobileAppContext">
    <slot></slot>
  </div>
</template>

<script>
//import { Capacitor } from "@capacitor/core";
//import config from "@/services/store/modules/config.js";
import { mapGetters } from 'vuex';

export default {
//  name: 'MobileAppArea',
  computed:{
    ...mapGetters('config', ['isNativePlatform', 'isMobileAppSimulationMode']),
    isMobileAppContext:{
      get(){ return this.isNativePlatform || this.isMobileAppSimulationMode;},
      set(newVal){ console.log('isMobileApp (set)', newVal); }
    }
  }
}
</script>


