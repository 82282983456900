<template>
  <div>
  <div v-if="value != null" >
    <b-iconstack :font-scale="fontScale" :class="level" >
      <b-icon v-show="isTeam" stacked :icon="personPlusIcon" shift-h="2" shift-v="4" scale="0.4" :variant="cVariant"></b-icon>
      <b-icon v-show="isTeam" stacked :icon="personIcon" shift-h="-3" shift-v="-1" scale="0.5" :variant="cVariant"></b-icon>
      <b-icon v-show="isTeam" stacked :icon="personIcon" shift-h="1" shift-v="-3" scale="0.5" :variant="cVariant"></b-icon>

      <b-icon v-show="isPair" stacked :icon="personIcon" shift-h="-3" shift-v="-2" scale="0.5" :variant="cVariant"></b-icon>
      <b-icon v-show="isPair" stacked :icon="personIcon" shift-h="1" shift-v="2" scale="0.5" :variant="cVariant"></b-icon>

      <b-icon v-show="isIndividual" stacked :icon="personIcon" scale="0.5" :variant="cVariant"></b-icon>

      <b-icon stacked :icon="borderIcon" :variant="bVariant"></b-icon>
    </b-iconstack>
  </div>
  </div>
</template>

<script>
import { REF_CATEGORY } from "@/LocalDB";

export default{
  name:"IconCategory",
  props:{
    value: { type:String, required:true }, // Code de la catégorie
    level: { type:String, default:"" },
    size: { type:String, default:''},
    variant: { type:String, default:''},
    borderVariant: { type:String, required:false},
    contentVariant: { type: String, required:false},
    competitorType: { type:String, default:'individual'},
  },
  computed:{
    borderIcon(){
      return "square";
    },
    personIcon(){
      return (this.isDualLines ? 'person-fill' : 'person');
    },
    personPlusIcon(){
      return (this.isDualLines ? 'person-plus-fill' : 'person-plus');
    },
    fontScale(){
      switch(this.size)
      {
        case "xsm": case "xs": return 1;
        case "sm": return 2;
        case "lg": return 4;
        case "xl": return 5;
        case "xxl": return 6;
        default: return 3;
      }
    },
    bVariant(){
      return (this.borderVariant != null) ? this.borderVariant : this.variant;
    },
    cVariant(){
      return (this.contentVariant != null) ? this.contentVariant : this.variant;
    },
    CATEGORY(){
      if(this.value != null)
        return REF_CATEGORY.query().where("code", this.value).first();
      return { isIndividual: false, isPair: false, isTeam: false, isDualLines: false, isMultiLines: false};
    },
    isIndividual(){
      return this.CATEGORY.isIndividual;
    },
    isPair(){
      return this.CATEGORY.isPair;
    },
    isTeam(){
      return this.CATEGORY.isTeam;
    },
    isDualLines(){
      return this.CATEGORY.isDualLines;
    },
    isMultiLines(){
      return this.CATEGORY.isMultiLines;
    }

  },
}
</script>

<style scoped>
  .expert { background-color:gold; }
  .inter { background-color:silver; }
  .novice { background-color: #bf8970 }
</style>

