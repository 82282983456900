import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"


import {
    REF_REGULATION,
    REF_PENALTY,
    REF_REGISTRATION_MODE,
    REF_RESULT_CALCULATION_MODE,
    REF_CATEGORY,
    REF_COMPETITION_TYPE,
    REF_COMPETITION_TYPE_SCOPE,
    REF_COMPULSORY,
    REF_EVENT,
    REF_LEVEL,
    REF_RULE,
    REF_SCOPE,
    REF_GN_NOTATION_GRID,
    REF_GN_CRITERION,
    REF_GN_PENALTY,
    //--------------
    PEOPLE,
    STAFF,
    STAFF_CAPABILITY,
    COMPETITOR,
    COMPETITOR_MEMBER,
    //--------------
    SEASON,
    SEASON_COMPETITION_TYPE_SCOPE,
    SEASON_COMPETITION_TYPE_SCOPE_LEVEL,
    SEASON_COMPETITION_TYPE_SCOPE_EVENT,
    SEASON_COMPETITION_TYPE_CATEGORY_LEVEL,
    SEASON_COMPULSORY,
    SEASON_RULE,
    SEASON_RULE_EVENT,
    LICENSEE,

VISA,
  } from "@/LocalDB";



Vue.use(Vuex)

const synchro = {
    VERBOSE: false,
    namespaced:true,
    state:  {
        _appVersions: null,
        _currentInstalledAppVersion: null,
        _isNetAvailable: false,
        _update_keys : {
            META: "",
            REF_COMPULSORIES: "",
            SEASON_CONFIGURATION: "",
            SEASON_COMPULSORIES: "",
            SEASON_RULES: "",
            STAFF: "",
            COMPETITORS: "",
            PEOPLE: "",
            REF_NOTATION_GRIDS: "",
        },
        _uptodate : {
            META: false,
            REF_COMPULSORIES: false,
            SEASON_CONFIGURATION: false,
            SEASON_COMPULSORIES: false,
            SEASON_RULES: false,
            STAFF: false,
            COMPETITORS: false,
            PEOPLE: false,
            REF_NOTATION_GRIDS: false,
        },
        _competitions_subscriptions: [],
        _logId: 0,
        _serverNotResponding: false,
    },
    mutations: {
        cleanUpAllUpdateKeys:(state) => {
            for(var prop in state._update_keys){
                state._uptodate[prop] = false;
                state._update_keys[prop] = '';
            }
        },
        setNetAvailability:(state, value) => state._isNetAvailable = value,
        setMetaKey:(state, value) => {
            state._update_keys.META = value;
        },
        setRefCompulsoriesKey:(state, value) => {
            state._update_keys.REF_COMPULSORIES = value;
        },
        setSeasonConfigurationKey:(state, value) => {
            state._update_keys.SEASON_CONFIGURATION = value;
        },
        setSeasonCompulsoriesKey:(state, value) => {
            state._update_keys.SEASON_COMPULSORIES = value;
        },
        setSeasonRulesKey:(state, value) => {
            state._update_keys.SEASON_RULES = value;
        },
        setStaffKey:(state, value) => {
            state._update_keys.STAFF = value;
        },
        setCompetitorsKey:(state, value) => {
            state._update_keys.COMPETITORS = value;
        },
        setPeopleKey:(state, value) => {
            state._update_keys.PEOPLE = value;
        },
        setNotationGridsKey:(state, value) => {
          state._update_keys.REF_NOTATION_GRIDS = value;
        },

        setMetaIsUptodate:(state, value) => {
            state._uptodate.META = value;
        },
        setRefCompulsoriesIsUptodate:(state, value) => {
            state._uptodate.REF_COMPULSORIES = value;
        },
        setSeasonConfigurationIsUptodate:(state, value) => {
            state._uptodate.SEASON_CONFIGURATION = value;
        },
        setSeasonCompulsoriesIsUptodate:(state, value) => {
            state._uptodate.SEASON_COMPULSORIES = value;
        },
        setSeasonRulesIsUptodate:(state, value) => {
            state._uptodate.SEASON_RULES = value;
        },
        setStaffIsUptodate:(state, value) => {
            state._uptodate.STAFF = value;
        },
        setCompetitorIsUptodate:(state, value) => {
            state._uptodate.COMPETITORS = value;
        },
        setPeopleIsUptodate:(state, value) => {
            state._uptodate.PEOPLE = value;
        },
        setNotationGridsIsUptodate:(state, value) => {
            state._uptodate.REF_NOTATION_GRIDS = value;
        },

        resetLogId: (state) => state._logId = 0,
        setLogId: (state, value) => state._logId = value,
        setAppVersions: (state, value) => state._appVersions = value,
        setServerNotResponding: (state, value) => {
            state._serverNotResponding = value;
            state._isNetAvailable = !value;
        },
    },
    actions: {
        checkIfNetIsAvailable:(context) => {
            var baseUrl = context.rootGetters["config/serverBaseUrl"];
            var url = baseUrl + '/api/test.php';

            axios
                .post(url, context.state._update_keys)
                .then(() => {
                    context.commit('setNetAvailability', true);
                    return true;
                })
                .catch(() => {
                    context.commit('setNetAvailability', false);
                    return false;
                });
        },
        checkForUpdates:(context) =>{
            var baseUrl = context.rootGetters["config/serverBaseUrl"];
            var url = baseUrl + '/api/checkUpdateKeys.php';

            axios.post(url, context.state._update_keys)
                .then(response => {
                     var data = response.data;
                    context.commit("setMetaIsUptodate", data.META);
                    context.commit("setRefCompulsoriesIsUptodate", data.REF_COMPULSORIES);
                    context.commit("setSeasonConfigurationIsUptodate", data.SEASON_CONFIGURATION);
                    context.commit("setSeasonCompulsoriesIsUptodate", data.SEASON_COMPULSORIES);
                    context.commit("setSeasonRulesIsUptodate", data.SEASON_RULES);
                    context.commit("setStaffIsUptodate", data.STAFF);
                    context.commit("setCompetitorIsUptodate", data.COMPETITORS);
                    context.commit("setPeopleIsUptodate", data.PEOPLE);
                    context.commit("setNotationGridsIsUptodate", data.REF_NOTATION_GRIDS);
                    context.commit('setNetAvailability', true);
                    context.commit('setServerNotResponding', false);
                })
                .catch(error => {
                    if(error.response && error.response.status == 500){
                        context.commit('setServerNotResponding', true);
                        context.commit('setNetAvailability', false);
                    } else {
                        console.error('checkForUpdates ERROR => ', error);
                    }
                });
        },
        getDownloadableAppVersions:(context) => {
        var baseUrl = context.rootGetters["config/serverBaseUrl"];
          var url = baseUrl + '/api/getAppVersions.php';
          axios
            .post(url)
            .then(response => {
              if(synchro.VERBOSE) console.log('getDownloadableAppVersions', response);
              var data = response.data;
              context.commit("setAppVersions", data.appVersions);
            })
            .catch(error => {
              console.error('getDownloadableAppVersions ERROR =>', error);
            })
        },
        updateWhenRequired:(context) => {
          var baseUrl = context.rootGetters["config/serverBaseUrl"];
          var url = baseUrl + '/api/checkUpdateKeys.php';

          axios.post(url, context.state._update_keys)
              .then(response => {
                  var data = response.data;
                  context.commit("setMetaIsUptodate", data.META);
                  context.commit("setRefCompulsoriesIsUptodate", data.REF_COMPULSORIES);
                  context.commit("setSeasonConfigurationIsUptodate", data.SEASON_CONFIGURATION);
                  context.commit("setSeasonCompulsoriesIsUptodate", data.SEASON_COMPULSORIES);
                  context.commit("setSeasonRulesIsUptodate", data.SEASON_RULES);
                  context.commit("setStaffIsUptodate", data.STAFF);
                  context.commit("setCompetitorIsUptodate", data.COMPETITORS);
                  context.commit("setPeopleIsUptodate", data.PEOPLE);
                  context.commit("setNotationGridsIsUptodate", data.REF_NOTATION_GRIDS);
                  context.commit('setNetAvailability', true);
                  if(!data.META){
                    context.dispatch('synchronizeMeta');
                  }
                  if(!data.REF_COMPULSORIES){
                    context.dispatch('synchronizeRefCompulsories');
                  }
                  if(!data.SEASON_CONFIGURATION){
                    context.dispatch('synchronizeSeasonConfiguration');
                  }
                  if(!data.SEASON_COMPULSORIES){
                    context.dispatch('synchronizeSeasonCompulsories');
                  }
                  if(!data.SEASON_RULES){
                    context.dispatch('synchronizeSeasonRules');
                  }
                  if(!data.STAFF){
                    context.dispatch('synchronizeStaffMembers');
                  }
                  if(!data.COMPETITORS){
                    context.dispatch('synchronizeCompetitors');
                  }
                  if(!data.PEOPLE){
                    context.dispatch('synchronizePeople');
                  }
                  if(!data.REF_NOTATION_GRIDS){
                    context.dispatch('synchronizeNotationGrids');
                  }
                  context.dispatch('synchronizeRunningCompetitions');
              })
              .catch(error => {
                if(error.response && error.response.status == 500){
                    context.commit('setServerNotResponding', true);
                    context.commit('setNetAvailability', false);
                } else 
                    console.error('checkForUpdates ERROR => ', error);
              });
        },
        synchronizeMeta:(context) => {
            var baseUrl = context.rootGetters["config/serverBaseUrl"];
            var url = baseUrl + '/api/getMeta.php';
            axios
                .post(url)
                .then(response => {
                    var data = response.data;
                    if(synchro.VERBOSE) console.log('synchronizeMeta', data);

                    REF_REGULATION.deleteAll();
                    data.regulations.forEach(regulation => {
                      REF_REGULATION.insertOrUpdate({
                        data: regulation
                      });
                    });

                    REF_PENALTY.deleteAll();
                    data.penalties.forEach(penalty => {
                        REF_PENALTY.insertOrUpdate({
                          data: penalty
                        });
                      });

                    REF_REGISTRATION_MODE.deleteAll();
                    data.registrationModes.forEach(mode => {
                        REF_REGISTRATION_MODE.insertOrUpdate({data : mode});
                    });

                    REF_RESULT_CALCULATION_MODE.deleteAll();
                    data.resultCalculationModes.forEach(mode => {
                      REF_RESULT_CALCULATION_MODE.insertOrUpdate({
                        data: mode
                      });
                    });

                    REF_COMPETITION_TYPE.deleteAll();
                    data.competitionTypes.forEach(type =>{
                        REF_COMPETITION_TYPE.insertOrUpdate({
                            data: type
                        });
                    });
                    REF_LEVEL.deleteAll();
                    data.levels.forEach(level =>{
                        REF_LEVEL.insertOrUpdate({
                            data: level
                        });
                    });
                    REF_CATEGORY.deleteAll();
                    data.categories.forEach(category =>{
                        REF_CATEGORY.insertOrUpdate({
                            data: category
                        });
                    });
                    REF_EVENT.deleteAll();
                    data.events.forEach(event => {
                        REF_EVENT.insertOrUpdate({
                            data: event
                        });
                    });
                    REF_SCOPE.deleteAll();
                    data.scopes.forEach(scope=>{
                        REF_SCOPE.insertOrUpdate({
                            data : scope
                        });
                    });
                    REF_RULE.deleteAll();
                    data.rules.forEach(rule => {
                        REF_RULE.insertOrUpdate({
                            data: rule
                        });
                    });
                    REF_COMPETITION_TYPE_SCOPE.deleteAll();
                    data.competitionTypesScopes.forEach(item => {
                      REF_COMPETITION_TYPE_SCOPE.insertOrUpdate({
                        data: item
                      });
                    });
                    context.commit('setMetaKey', data.update_key);
                })
                .catch(error => {
                    console.error('synchronizeMeta ERROR => ', error);
                });
        },

        synchronizeRefCompulsories:(context) => {
            var baseUrl = context.rootGetters["config/serverBaseUrl"];
            var url = baseUrl + '/api/getCompulsories.php';
             axios.post(url)
                .then(response => {
                    var data = response.data;
                    if(synchro.VERBOSE) console.log('synchronizeRefCompulsories', data);

                    REF_COMPULSORY.deleteAll();
                    data.compulsories.forEach(compulsory => {
                        REF_COMPULSORY.insertOrUpdate({
                            data: compulsory
                        })
                    });
                    context.commit('setRefCompulsoriesKey', data.update_key);
                })
                .catch(error => {
                    console.error('synchronizeRefCompulsories => ', error);
                })
        },

        synchronizeSeasonConfiguration:(context) => {
            var baseUrl = context.rootGetters["config/serverBaseUrl"];
            var url = baseUrl + '/api/getSeason.php';
            axios
                .post(url)
                .then(response => {
                    var data = response.data;
                    if(synchro.VERBOSE) console.log('synchronizeSeasonConfiguration', data);

                    SEASON.insertOrUpdate({
                        data : data.season
                    });

                    SEASON_COMPETITION_TYPE_SCOPE.delete(configuration => {
                        return configuration.year == data.season.year;
                    });
                    data.types_scopes.forEach(item => {
                        SEASON_COMPETITION_TYPE_SCOPE.insertOrUpdate({
                            data: item
                        });
                    });

                    SEASON_COMPETITION_TYPE_SCOPE_LEVEL.delete(configuration => {
                      return configuration.year == data.season.year;
                    });
                    data.types_scopes_levels.forEach(item => {
                        SEASON_COMPETITION_TYPE_SCOPE_LEVEL.insertOrUpdate({
                            data: item
                        });
                    });

                    SEASON_COMPETITION_TYPE_SCOPE_EVENT.delete(configuration => {
                      return configuration.year == data.season.year;
                    })
                    data.types_scopes_events.forEach(item => {
                      SEASON_COMPETITION_TYPE_SCOPE_EVENT.insertOrUpdate({
                        data: item
                      });
                    });

                    SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.delete(configuration => {
                        return configuration.year == data.season.year;
                    });
                    data.types_categories_levels.forEach(item => {
                        SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.insertOrUpdate({
                            data: item
                        });
                    });
                    context.commit('setSeasonConfigurationKey', data.update_key);
                })
                .catch(error => {
                    console.error('synchronizeSeasonConfiguration', error);
                })
        },

        synchronizeSeasonCompulsories:(context) => {
            var baseUrl = context.rootGetters["config/serverBaseUrl"];
            var url = baseUrl + '/api/getSeasonCompulsories.php';
            axios
                .post(url)
                .then(response => {
                    var data = response.data;
                    if(synchro.VERBOSE) console.log('synchronizeSeasonCompulsories', data);

                    SEASON_COMPULSORY.delete(compulsory => {
                        return compulsory.year == data.season.year;
                    });
                    data.compulsories.forEach(compulsory => {
                        SEASON_COMPULSORY.insertOrUpdate({
                            data : compulsory
                        });
                    });
                    context.commit('setSeasonCompulsoriesKey', data.update_key);
                })
                .catch(error => {
                    console.error('synchronizeSeasonCompulsories ERROR => ' + error);
                });
        },

        synchronizeSeasonRules:(context) => {
            var baseUrl = context.rootGetters["config/serverBaseUrl"];
            var url = baseUrl + '/api/getRules.php';
            axios
                .post(url)
                .then(response => {
                    var data = response.data;
                    if(synchro.VERBOSE) console.log('synchronizeSeasonRules', data);

                    SEASON_RULE.delete((rule) => {
                        return rule.year == data.season.year;
                    });
                    data.rules.forEach(rule => {
                        SEASON_RULE.insertOrUpdate({
                            data: rule
                        })
                    });

                    SEASON_RULE_EVENT.delete(rule => {
                        return rule.year == data.season.year;
                    })
                    data.rulesEvents.forEach(event => {
                        SEASON_RULE_EVENT.insertOrUpdate({
                            data: event
                        })
                    });

                    context.commit('setSeasonRulesKey', data.update_key);
                })
                .catch(error => {
                    console.error('synchronizeSeasonRules ERROR => ' + error);
                });
        },

        synchronizeStaffMembers:(context) => {
            var baseUrl = context.rootGetters["config/serverBaseUrl"];
            var url = baseUrl + '/api/getStaffMembers.php';
            axios
                .post(url)
                .then(response => {
                    var data = response.data;
                    if(synchro.VERBOSE) console.log('synchronizeStaffMembers', data);

                    data.people.forEach(one => {
                        PEOPLE.insertOrUpdate({
                            data: one
                        });
                    });
                    data.staffMembers.forEach(member => {
                        STAFF.insertOrUpdate({
                            data: member
                        });
                   });
                   data.licensee.forEach(lic => {
                       LICENSEE.insertOrUpdate({
                           data: lic
                       });
                   });
                   data.staffMembersCapabilities.forEach(caps => {
                       STAFF_CAPABILITY.insertOrUpdate({
                           data: caps
                       });
                    });

                    context.commit('setStaffKey', data.update_key);

                })
                .catch(error => {
                    console.error('synchronizeStaffMembers ERROR => ' + error);
                });
        },

        synchronizeCompetitors:(context)=>{
            var baseUrl = context.rootGetters["config/serverBaseUrl"];
            var url = baseUrl + '/api/getCompetitors.php';
             axios
                .post(url)
                .then(response => {
                    var data = response.data;
                    if(synchro.VERBOSE) console.log('synchronizeCompetitors', data);

                    COMPETITOR_MEMBER.deleteAll();
                    COMPETITOR.deleteAll();

                    data.competitors.forEach(comp => {
                        COMPETITOR.insertOrUpdate({
                            data: comp
                        });

                    });
                    data.competitorsMembers.forEach(member => {
                        COMPETITOR_MEMBER.insertOrUpdate({
                            data: member
                        });
                    });
                    data.people.forEach(one => {
                        PEOPLE.insertOrUpdate({
                            data : one
                        });
                    });
                    data.licensee.forEach(lic => {
                        LICENSEE.insertOrUpdate({
                            data: lic
                        });
                    });
                    context.commit('setCompetitorsKey', data.update_key);

                })
                .catch(error => {
                    console.error('synchronizeCompetitors ERROR => ' + error);
                });
        },

        synchronizePeople:(context)=>{
          var baseUrl = context.rootGetters["config/serverBaseUrl"];
          var url = baseUrl + '/api/getPeople.php';
           axios
              .post(url)
              .then(response => {
                  var data = response.data;
                  if(synchro.VERBOSE) console.log('synchronizePeople', data);

                  PEOPLE.deleteAll();

                  data.people.forEach(person => {
                      PEOPLE.insertOrUpdate({
                          data: person
                      });

                  });

                  VISA.deleteAll();
                  VISA.insertOrUpdate({ data: data.visas});

                  data.licensees.forEach(lic => {
                    LICENSEE.insertOrUpdate({ data: lic});
                  })

                  context.commit('setPeopleKey', data.update_key);

              })
              .catch(error => {
                  console.error('synchronizePeople ERROR => ' + error);
              });



            },

      synchronizeNotationGrids:(context)=>{
        var baseUrl = context.rootGetters["config/serverBaseUrl"];
        var url = baseUrl + '/api/getNotationGrids.php';
         axios
            .post(url)
            .then(response => {
                var data = response.data;
                if(synchro.VERBOSE) console.log('synchronizeNotationGrids', data);

                REF_GN_NOTATION_GRID.deleteAll();
                REF_GN_CRITERION.deleteAll();
                REF_GN_PENALTY.deleteAll();

                data.grids.forEach(grid => {
                    REF_GN_NOTATION_GRID.insertOrUpdate({
                        data: grid
                    });

                });

                data.criterions.forEach(criterion => {
                    REF_GN_CRITERION.insertOrUpdate({
                        data: criterion
                    });

                });

                data.penalties.forEach(penalty => {
                  REF_GN_PENALTY.insertOrUpdate({
                    data: penalty
                  });
                })

                context.commit('setNotationGridsKey', data.update_key);

            })
            .catch(error => {
                console.error('synchronizeNotationGrids ERROR => ' + error);
            });
      },

      synchronizeRunningCompetitions:()=>{

        },

    },
    getters: {
        isNetAvailable : (state) => state._isNetAvailable,
        areMetaUptodate:(state) => state._uptodate.META,
        areRefCompulsoriesUptodate:(state) => state._uptodate.REF_COMPULSORIES,
        isSeasonConfigurationUptodate:(state) => state._uptodate.SEASON_CONFIGURATION,
        areSeasonCompulsoriesUptodate:(state) => state._uptodate.SEASON_COMPULSORIES,
        areSeasonRulesUptodate:(state) => state._uptodate.SEASON_RULES,
        areStaffUptodate:(state) => state._uptodate.STAFF,
        areCompetitorsUptodate:(state) => state._uptodate.COMPETITORS,
        arePeopleUptodate:(state) => state._uptodate.PEOPLE,
        getLogId:(state) => state._logId,
        getLastAppStableVersionInfo: (state) => (state._appVersions == null) ? null : state._appVersions.stableVersion,
        getLastAppBetaVersionInfo: (state) => (state._appVersions == null) ? null : state._appVersions.betaVersion,
        getAppVersions: (state) => (state._appVersions),
        getStaffUpdateKey: (state) => (state._update_keys.STAFF),
        getServerNotResponding: (state) => state._serverNotResponding,
    }
  };

export default synchro;
