import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import { store } from "@/services/store";

Vue.use(Vuex)

const auth = {
    namespaced: true,
    state : {
        _user: null,
        _connectedUser: null,
        _lastLoginError: null,
        _noAuthOnThisDevice: false,
        _noAuthRoles: [],
    },
    getters : {
        currentUser: (state) => state._user,
        connectedUser: (state) => state._connectedUser || state._user,
        isAuthenticated: (state) => state._user !== null,
        isImpersonated: (state) => state._connectedUser != null && state._user.userName != state._connectedUser.userName,
        userHasRole: (state) => (role) => {
            if(state._user === null && !state._noAuthOnThisDevice)
                return false;

            var searched = (role + "").trim().toLocaleLowerCase();

            if(state._noAuthOnThisDevice && (role == 'Tester'))
              return true;
            if(state._noAuthOnThisDevice)
              if(state._noAuthRoles.findIndex((element) => (element == null) ? false : element.toLocaleLowerCase() === searched) >=0)
                  return true;
              else
                  return false;
            else{
              var found = state._user != null && state._user.roles.findIndex((element) => (element == null) ? false : element.toLocaleLowerCase() === searched) >= 0;
              return found;
            }
        },
        userHasOneOfRoles: (state) => (roles) => {
            if(state._user === null && !state._noAuthOnThisDevice)
                return false;

            var ret = false;
            roles.forEach(role => {
                if(state._noAuthOnThisDevice && role == 'Tester')
                  return true;

                var searched = (role + "").trim().toLocaleLowerCase();
                if(state._noAuthRoles.findIndex((element) => (element == null) ? false : element.toLocaleLowerCase() === searched) >=0){
                    ret = true;
                    return;
                }
                if(state._user != null && state._user.roles.findIndex((element) => (element == null) ? false : element.toLocaleLowerCase() === searched) >= 0){
                    ret = true;
                    return;
                }
            });
            return ret;
        },
        userOnlyHaveOneOfRoles: (state) => (roles) =>{
          if(state._user === null && !state._noAuthOnThisDevice)
            return false;

          var ret = true;
          if(state._noAuthOnThisDevice)
          {
            state._noAuthRoles.forEach(role => {
              var searched = (role + "").trim().toLocaleLowerCase();
              if(!roles.findIndex(element => (element == null) ? false : element.toLocaleLowerCase() === searched) < 0)
                return false;
            });
          }
          if(state._user != null){
            state._user.roles.forEach(role => {
              var searched = (role + "").trim().toLocaleLowerCase();
              if(!roles.findIndex(element => (element == null) ? false : element.toLocaleLowerCase() === searched) < 0)
                return false;
            });
          }
          return ret;
        },
        currentUserFullname: (state) => {
            if(state._user === null)
                return '';
            return state._user.firstname.trim() + ' ' + state._user.lastname.trim();
        },
        currentUserFirstname: (state) => {
          if(state._user === null)
            return '';
          return state._user.firstname.trim();
        },
        impersonatedUser: (state) => {
          return (state._user != null) && (state._connectedUser.username != state._user.username) ? state._user : null; },
        lastLoginError: (state) => state._lastLoginError,
        noAuthRequiredOnThisDevice: (state) => state._noAuthOnThisDevice,
        noAuthModeRoles: (state) => state._noAuthRoles,
        currentUserRoles: (state) => state._user.roles,
        getAuthorizationOnOperation: (state) => (operation) => {
          if(state._user == null || state._user.operations == null)
            return false;
          if(!state._user.operations[operation])
            return false;
          return state._user.operations[operation] === true;
        },
        userCanNew: (state, operation) => {
          return state._user.operations[operation] === true;
        },
    },
    actions : {
        async login(context, credentials)
        {
            context.commit('setLastLoginError', null);
            context.commit('setUser', null);

            var baseUrl = context.rootGetters["config/serverBaseUrl"];
            var url = baseUrl + '/api/login.php';
            await axios.post(url, { name: credentials.loginName, pass: credentials.password} )
                .then(response => {
                  if(response.data.logged){
                    response.data.visa = response.data.username;
                    context.commit('setUser', response.data);
                    context.commit('setConnectedUser', response.data);
                  } else {
                    console.log('login failed');
                    context.commit('setLastLoginError', 'Invalid username or password');
                  }
                })
                .catch(error => {
                    console.log('login error', error);
                    if (credentials.loginName.trim().toLocaleLowerCase() === 'dev' && credentials.password === 'AdminDev@123456')
                    {
                        context.commit('setUser', { firstname: 'Dev', lastname: 'DEV', roles: ['Developper' ]});
                        context.commit('setConnectedUser', { firstname: 'Dev', lastname: 'DEV', roles: ['Developper' ]});
                    } else if (error.response) {
                      if(error.response.status == 500)
                      {
                        context.commit('setLastLoginError', 'Server is not responding')
                      } else {
                          switch(error.response.code){
                              case 401:
                                  context.cmmit('setLastLoginError', 'Invalid username or password')
                                  break;
                              case 419:
                                  context.commit('setLastLoginError', 'Account is expired');
                                  break;
                              case 423:
                                  context.commit('setLastLoginError', 'Account locked');
                                  break;
                              default:
                                  context.commit('setLastLoginError', error);
                                  break;
                          }
                      }
                    } else {
                      context.commit('setLastLoginError', error);
                    }
            });
        },
        async logout(context){
            let userSession = context.state._user.session;
            let user = null;
            context.commit('setUser', user);
            context.commit('setConnectedUser', user);
            var baseUrl = context.rootGetters["config/serverBaseUrl"];
            var url = baseUrl + '/api/logout.php';
            try{
                await axios.post(url, { userSession: userSession });
            }catch(error){
                console.log('Logout error =>', error);
            }
        },
        async changePassword(context, newCredentials){
            let userSession = (context.state._connectedUser || context.state._user).session;
            var baseUrl = context.rootGetters["config/serverBaseUrl"];
            var url = baseUrl + '/api/changepass.php';
            try {
              var ctx = { userSession: userSession, old: newCredentials.oldPass, new: newCredentials.newPass};
              console.log('changePassword ctx', ctx);
              var result = await axios.post(url, ctx);
              return (result.data.done == 1);
            }catch(error){
              console.log('Change PWD error =>', error);
            }
        },

        async userCan(context, operationName){
          var ret = false;
          var baseUrl = context.rootGetters["config/serverBaseUrl"];
          var url = baseUrl + '/api/userCanOneOf.php';
          try{
            var session = (context.state._user == null) ? null : context.state._user.session;
            const response = await axios.post(url, { userSession: session, operations: operationName });
            if(response.data.isAuthorized)
            {
              if(response.data.authorizations)
                response.data.authorizations.forEach(operationName => {
                  context.commit('grantAuthorisationOnOperation', operationName );
                });
              else
                context.commit('grantAuthorisationOnOperation', operationName );
                if(response.data.rejections)
                  response.data.rejections.forEach(operationName => {
                    context.commit('revokeAuthorizationOnOperation', operationName);
                  });
            }
            else
            {
              if(response.authorizations || response.rejections){
                if(response.data.rejections)
                  response.data.rejections.forEach(operationName => {
                    context.commit('revokeAuthorizationOnOperation', operationName);
                  });
                if(response.data.authorizations)
                  response.data.authorizations.forEach(operationName => {
                    context.commit('grantAuthorisationOnOperation', operationName );
                  });
              } else {
                context.commit('revokeAuthorizationOnOperation', operationName);
              }
            }
            ret = response.data.isAuthorized;
          }
          catch(error){
              console.log('auth.js->userCan ERROR =>', error);
                if(context.getters["getAuthorizationOnOperation"](operationName)){
                  ret = true;
                }
          }
          return ret;
        },
        async userCanOneOf(context, operations){
          if(!Array.isArray(operations))
            throw "Le paramètre 'operations' doit être un tableau";
          var ret = false;
          var baseUrl = context.rootGetters["config/serverBaseUrl"];
          var url = baseUrl + '/api/userCanOneOf.php';
          try{
            var session = (context.state._user == null) ? null : context.state._user.session;
            const response = await axios.post(url, { userSession: session, operations: operations.join() });
            if(response.data.isAuthorized)
            {
              if(response.data.authorizations)
                response.data.authorizations.forEach(operationName => {
                  context.commit('grantAuthorisationOnOperation', operationName );
                });
              else
                operations.forEach(operationName => {
                  context.commit('grantAuthorisationOnOperation', operationName );
                })
              if(response.data.rejections)
                response.data.rejections.forEach(operationName => {
                  context.commit('revokeAuthorizationOnOperation', operationName );
                });
            }
            else
            {
              if(response.authorizations || response.rejections){
                if(response.data.rejections)
                  response.data.rejections.forEach(operationName => {
                    context.commit('revokeAuthorizationOnOperation', operationName);
                  });
                if(response.data.authorizations)
                  response.data.authorizations.forEach(operationName => {
                    context.commit('grantAuthorisationOnOperation', operationName );
                  });
              } else {
                operations.forEach(operationName => {
                  context.commit('revokeAuthorizationOnOperation', operationName);
                });
              }
            }
             ret = response.data.isAuthorized;
          }
          catch(error){                    
            if(error.response && error.response.status == 500){
                store.commit('synchro/setServerNotResponding', true);
                store.commit('synchro/setNetAvailability', false);
            } else {
              console.log('auth.js->userCan ERROR =>', error);
              for(var i=0; i < operations.length; i++){
                var operationName = operations[i];
                if(context.getters["getAuthorizationOnOperation"](operationName)){
                  ret = true;
                  break;
                }
              }
            }
          }
          return ret;
        },
        async userCanOneOfAsync(context, operations){
          if(!Array.isArray(operations))
            throw "Le paramètre 'operations' doit être un tableau";
          var ret = false;
          var baseUrl = context.rootGetters["config/serverBaseUrl"];
          var url = baseUrl + '/api/userCanOneOf.php';
          try{
            var session = (context.state._user == null) ? null : context.state._user.session;
            const response = await axios.post(url, { userSession: session, operations: operations.join() });
            if(response.data.isAuthorized)
            {
              if(response.data.authorizations)
                response.data.authorizations.forEach(operationName => {
                  context.commit('grantAuthorisationOnOperation', operationName );
                });
              else
                operations.forEach(operationName => {
                  context.commit('grantAuthorisationOnOperation', operationName );
                })
            }
            else
            {
              if(response.authorizations || response.rejections){
                if(response.data.rejections)
                  response.data.rejections.forEach(operationName => {
                    context.commit('revokeAuthorizationOnOperation', operationName);
                  });
                if(response.data.authorizations)
                  response.data.authorizations.forEach(operationName => {
                    context.commit('grantAuthorisationOnOperation', operationName );
                  });
              } else {
                operations.forEach(operationName => {
                  context.commit('revokeAuthorizationOnOperation', operationName);
                });
              }
            }
            ret = response.data.isAuthorized;
          }
          catch(error){
            if(error.response && error.response.status == 500){
              store.commit("synchro/setServerNotResponding", false);
              store.commit('synchro/setNetAvailability', true);
                  } else {
              console.log('auth.js->userCan ERROR =>', error);
              for(var i=0; i < operations.length; i++){
                var operationName = operations[i];
                if(context.getters["getAuthorizationOnOperation"](operationName)){
                  ret = true;
                  break;
                }
              }
            }
          }
          return ret;
        },
        async startImpersonationAsync(context, username){
          var ret = false;
          var isAuthenticated = context.getters['isAuthenticated'];
          if(isAuthenticated && username != null)
          {
            let userSession = context.state._user.session;
            var baseUrl = context.rootGetters["config/serverBaseUrl"];
            var url = baseUrl + '/api/login.php';
            try{
              const response = await axios.post(url, { session: userSession, name: username, impersonationMode: true, connected: context.state._connectedUser.username } )
                  if(response.data.logged){
                    response.data.visa = response.data.username;
                    context.commit('setUser', response.data);
                  } else {
                    console.log('impersonation failed');
                  }
                  ret = response.data.logged;
            }
            catch(error){
                    console.log('impersonation error', error);
            }
          }
          return ret;
        },
        async endImpersonation(context)
        {
          var ret = false;
          var isAuthenticated = context.getters['isAuthenticated'];


          if(isAuthenticated)
          {
            var connected = context.getters['connectedUser'];
            let userSession = context.state._user.session;
            var baseUrl = context.rootGetters["config/serverBaseUrl"];
            var url = baseUrl + '/api/login.php';
            try{
              const response = await axios.post(url, { session: userSession, impersonationMode: true } );
                  if(response.data.logged){
                    context.commit('setUser', connected);
                  } else {
                    console.log('impersonation stop failed');
                  }
                  ret = response.data.logged;
            }
            catch(error){
                    console.log('impersonation error', error);
            }
          }
          return ret;
        },

    },
    mutations : {
        setUser(state, user){
            state._user = user;
        },
        setConnectedUser(state, user)
        {
          state._connectedUser = user;
        },
        setLastLoginError(state, error){
            state._lastLoginError = error;
        },
        logout(state){
            state._user = null;
        },
        setnoAuthRequiredOnThisDevice(state, value){
            state._noAuthOnThisDevice = value;
        },
        setNoAuthModeRoles(state, value){
            state._noAuthRoles = value;
        },
        grantAuthorisationOnOperation(state, operationName)
        {
          if(!state._user.operations){
            state._user.operations = {};
          }
          state._user.operations[operationName] = true;
        },
        setAuthorisationOnOperation(){
          throw 'Deprecated : use grantAuthorisationOnOperation instead !';

        },
        revokeAuthorizationOnOperation(state, operationName){
          if(!state._user)
            return;
          if(!state._user.operations){
            state._user.operations = {};
          }
          state._user.operations[operationName] = false;
        },
        removeAuthorizationOnOperation(){
          throw 'Deprecated : use revokeAuthorizationOnOperation instead !';

        },

    }
};

export default auth;

