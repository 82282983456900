const SecurityMap = {
    VERBOSE: false,

    get Operations(){
      return {
        get Create_Competition(){return "Create_Competition"}, get CreateCompetition() { return this.Create_Competition },  get Competition_Create(){ return this.CreateCompetition; },
        get List_Competitions() { return "List_Competitions" }, get Competitions_List(){ return this.List_Competitions; },
        get Delete_Competition() { return "Delete_Competition"}, get Competition_Delete(){ return this.Delete_Competition; },
      }
    },
    get create_competition_operations(){
      return [this.Operations.Create_Competition]; 
    },
    get existingCompetitions_operations(){ 
      return [this.Operations.List_Competitions, 'View_Competition', 'Edit_Competition', 'Judge_Event', this.Operations.Delete_Competition];
    },
    get competitionsManagement_group_operations(){
      var ret = [].concat(
                    this.create_competition_operations, 
                    this.existingCompetitions_operations
                ).filter((value, index, self) => {return self.indexOf(value) === index; });
      return ret;
    },

    get securityUsers_operations(){ 
      return ['List_Users', 'View_User', 'Edit_User', 'Add_User_To_Role', 'Remove_User_From_Role', 'View_Users_Rights'];
    },
    get securityRoles_operations(){
      return ['List_Roles', 'View_Role', 'Edit_Role', 'Remove_Role', 'Add_User_To_Role', 'Remove_User_From_Role', 'View_Users_In_Roles', 'Grant_Operation_To_Role', 'Revoke_Operation_From_Role', 'View_Operations_For_Roles'];
    },
    get securityOperations_operations(){
      return ['List_Operations', 'View_Operation', 'Edit_Operation', 'Archive_Operation', 'Unarchive_Operation', 'Remove_Operation', 'Grant_Operation_To_Role', 'Revoke_Operation_From_Role', 'View_Operations_For_Roles', 'View_Users_Rights'];
    },
    get security_group_operations(){ 
      var ret = [].concat(
                    this.securityUsers_operations,
                    this.securityRoles_operations,
                    this.securityOperations_operations
                  )
                  .filter((value, index, self) => { return self.indexOf(value) === index; });
      return ret;
    },

    get staffAdmin_operations(){ 
      return ['List_Staff_Members', 'View_Staff_Member', 'Create_Staff_Member', 'Edit_Staff_Member', 'Remove_Staff_Member', 'View_Staff_Member_Capabilities', 'Set_Staff_Member_Capabilities'];
    },
    get competitorAdmin_operations(){ 
      return ['List_Competitors', 'View_Competitor', 'Create_Competitor', 'Edit_Competitor', 'Remove_Competitor'];
    },
    get masterDataGlobalView_operations(){ 
      return ['ViewMasterDataGlobalView'];
    },
    get referentialDataAdmin_operations(){ 
      return [].concat(
                  this.masterDataGlobalView_operations
                )
                .filter((value, index, self) => { return self.indexOf(value) === index; });
    },
    get seasonAdmin_compulsories_operations(){ 
      return ['List_Season_Compulsories', 'Set_Season_Compulsories'];
    },
    get seasonAdmin_group_operations(){ 
      return [].concat(
                  this.seasonAdmin_compulsories_operations
              )
              .filter((value, index, self) => { return self.indexOf(value) === index; });
    },
    get administration_group_operations(){ 
      return [].concat(
                  this.staffAdmin_operations,
                  this.competitorAdmin_operations,
                  this.referentialDataAdmin_operations,
                  this.seasonAdmin_group_operations,
                )
                .filter((value, index, self) => { return self.indexOf(value) === index; });
    },

    get testApi_operations(){ 
      return ['Test_Api'];
    },
    get graphicalCharter_operations(){ 
      return ['Graphical_Charter'];
    },
    get jsTips(){ 
      return ['JS_Tips'];
    },
    get devUsefulLinks(){ 
      return ['Dev_Useful_Links']; 
    },
    get testVue_operations(){
      return ['Test_Components'];
    },
    get tools_group_operations(){ 
      return [].concat(
                  this.testApi_operations,
                  this.graphicalCharter_operations,
                  this.jsTips,
                  this.devUsefulLinks,
                  this.testVue_operations
                )
                .filter((value, index, self) => { return self.indexOf(value) === index; });
    },
}

export default SecurityMap;
