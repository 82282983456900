<template>
    <div>
      <header class="page-header "  >
<div class="row"><div class="col">
      <b-nav class="bg-dark"
        type="dark" variant="dark" style="align-content:space-between !important"
      >
      <b-nav-item  no-caret v-b-toggle.sidebar-menu class="d-block d-xl-none">
          <b-icon icon="list" style="padding: 2px; width:1.6em; height:1.6em; border: solid 2px var(--ffvlOrangeLight); border-radius: 6px;" />
        </b-nav-item> 
        
        <nav-bar-menu mode='icon-bar' style="display:flex" />

        <!-- Logo -->
        <!--<b-navbar-brand @click="backToHome">
          <img src="./assets/Logo H.png" style="height: 1.2em;"  />
          <br class="d-none d-xl-block" />
          <small class="d-none d-xl-block" style="font-size:10px;">Compétition Cerf-Volant</small>
        </b-navbar-brand>-->



          <slot name="authenticated-icon-placeholder">
            <b-nav-item-dropdown text="Utilisateur" class="mr-auto me-auto" :class="{'impersonated-user' : isImpersonated}" right style="float:right !important;" v-if="isAuthenticated">
            <template #button-content>
             <span class="fas fa-user"></span>
             <b-icon icon="person" />&nbsp;
              <span >{{ currentUserFirstname }}</span>
            </template>
            <b-dropdown-item @click="showAccount"><b-icon icon="gear"/>&nbsp;Mon compte</b-dropdown-item>
            <b-dropdown-item @click="disconnect"><b-icon icon="power" variant="danger" />&nbsp;Déconnexion</b-dropdown-item>
            <secured-area :operations="['IMPERSONATE']" :hideWhenAccessIsRejected="true">
              <b-dropdown-item @click="showImpersonationDialog=true"><b-icon />&nbsp;Emprunt d'identité</b-dropdown-item>
            </secured-area>
            <b-dropdown-item class="impersonated-user" @click="showImpersonationDialog=true" v-if="isImpersonated"><b-icon />&nbsp;Emprunt d'identité</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item right v-if="!isAuthenticated"  @click="showLogin">
          <b-icon icon="person" />&nbsp;Connexion
        </b-nav-item>

          </slot> 

        <!-- Informations sur l'état de syncho -->
        <slot name="synchro-indicator-placeholder"></slot>
        <!--<b-nav-item right style="float:right;" no-caret v-b-toggle.sidebar-menu class="d-block d-xl-none">
          <b-icon icon="list" style="padding: 2px; width:1.1em; height:1.1em; border: solid 1px var(--ffvlOrangeLight); border-radius: 4px;" />
                </b-nav-item> -->
                <!-- Logo -->
                <b-navbar-brand right style="float:right" @click="backToHome">
                  <small style="min-width: 300px; width:300px;" class="d-none d-sm-inline">Compétition Cerf-Volant </small>
            <img src="@/assets/FFVL_mono.png" style="height: 1.9em; margin:0px; margin-right: 5px;"  />
<!--            <img src="./assets/FFVL_mono.png" class="d-none d-xl-block" style="height: 1.2em; margin:0px; margin-right: 5px;"  />
            <br class="d-none d-xl-block" />
            <small class="d-none d-xl-block" style="font-size:10px;">Compétition Cerf-Volant</small>-->
        </b-navbar-brand>
             </b-nav>

      <slot name="environment-marker"></slot>
      <slot name="test-mode-indicator"></slot>
      <slot name="impersonation-mode-indicator"></slot>
      </div></div>
    </header>
    <main role="main" >
      <div class="d-block d-xl-none">
        <slot name="sidebar-menu-placeholder"></slot>
      </div>

      <b-container>
      <b-row>
        <b-col md="3" class="d-none d-xl-block left-aside-menu" >
         
          <div style="position:fixed">
          <side-bar-menu title="Menu" >
            <left-aside-menu-content />
          </side-bar-menu>
          </div>
        </b-col>
        <b-col>    

    <slot>CONTENU PLACEHOLDER</slot>

        </b-col>
      </b-row>
      </b-container>
    </main>
    <footer class="page-footer">

      <div class="test-mode" v-if="isTestMode">TEST-MODE</div>

      <div>
        <div>&copy; : 2023 - Marc Levesque / FFVL</div>
          <div>WEB version {{ webVersion }}</div>
      </div>
    </footer>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SynchroHelper from "@/services/helpers/synchroHelper";

import SecuredArea from "@/components/Security/NewSecuredArea";
import NavBarMenu from '@/components/Utilities/NavBarMenu.vue';
//import LeftAsideMenu from '@/components/PageTemplates/LeftAsideMenu copy.vue';
import LeftAsideMenuContent from '@/components/PageTemplates/LeftAsideMenuContent.vue';
import SideBarMenu from '@/components/Panels/SideBarMenu.vue';

export default{
  components: { /*LeftAsideMenu,*/ LeftAsideMenuContent ,SideBarMenu, SecuredArea,  NavBarMenu,   },
  computed:{
    ...mapGetters('auth', ['isAuthenticated', 'isImpersonated', 'currentUserFullname', 'userHasOneOfRoles', 'currentUserFirstname' ]),
    ...mapGetters('config', ['isTestMode', 'isDevEnvironment', 'isProdEnvironment', 'isNativePlatform', 'environment', 'isDebugMode', 'isMobileAppSimulationMode' ]),
    needSynchro(){
      return SynchroHelper.IsThereRemainingLogsToSynchronize();
    },
    nbLogs(){
      return SynchroHelper.RemainingLogsCount;
    }
  },
  methods:{
        backToHome(){
            this.$emit('backToHome');
        },
        showAccount(){
            this.$emit('showAccount');
        },
        showLogin(){
            this.$emit('showLogin');
        },
        disconnect(){
            this.$emit('disconnect');
        },
        doSynchronizeNow(){
            this.$emit('doSynchronizeNow');
        }
    }
}
</script>

<style>

#app.web-app {
  font-family: Arial /*Avenir, Helvetica, Arial, sans-serif*/;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 10px;
}
#app.web-app.test-mode{
  background-color: #ebebeb ;
}
h1, .main-header{text-align: center;}
.tools-menu-header{text-align:left;}

main{
  min-height: 100%;
  padding-bottom:25px;
  padding-top: 78px;
}
#app.test-mode > main {
  padding-bottom: 60px;
}

header.page-header{
  position:fixed;
  width:100%;
  top:0;
  z-index:100;
}
#app.web-app .nav.bg-dark{
  background: linear-gradient(90deg, var(--ffvlOrangePicto) 0%, var(--ffvlOrangeLight) 100%);
}
header > div.impersonated-user,
footer > div.impersonated-user
{
  letter-spacing: normal !important;
}
.impersonated-user{
  background-color: var(--impersonatedUserBackground) !important;
  color:var(--impersonatedUserColor)!important;
}
.nav-dropdown.impersonated-user{
  color:var(--impersonatedUserColor) !important;
}

footer.page-footer > div{
background-color: var(--ffvlHeaderAndFooter);
position:fixed;
color:white;
width:100%;
text-align:center;
bottom:0;
height:18px;
font-size:12px;
padding:5px;
padding-top:2px;
padding-bottom:2px;
display:flex;
justify-content: space-between;
z-index:999;
}

footer.page-footer > div :first-child{
  margin-left:10px;
}
footer.page-footer > div:last-child{
  margin-right: 10px;
}
header  div.test-mode,
footer > div.test-mode
{
  background-color: black; color:white;
  min-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:10px;
  letter-spacing: 20px;
}
footer > div.test-mode{
  margin-bottom: 18px;
}
footer > div.impersonated-user{
  margin-bottom: 48px !important;
}


html{
  height: 100%;
  font-size:12pt;
}
body{
  margin: 0;
  min-height: 100%;
  position:relative;
  font-family: var(--ffvlFontFamily);
}
.nav-item{

  list-style: none;
}
h1, h2, h3, h4, h5, h6 {
  color: var(--ffvlBleuBaseline);
  font-weight:bold;
}
h1{
  font-size: 2em;
}
h2{
  font-size: 1.75em;
}
h3{
  font-size: 1.5em;
}
h4{
  font-size: 1.25em;
}
.btn{ margin: 2px;}

:root{
  --sortedHeader: #20C997;
  --sortedCell: #40e9b7;
  --resultTotalHeader : #aaaaaa;
  --resultTotal : #eeeeee;
  --resultRankHeader : #aaaaaa;
  --resultRank : #cccccc;
  --inputBackground: #aaaaee;
  --ffvlAlert: #cf0a10;
  --ffvlFontFamily: Arial;
  --ffvlLinkFontFamily: 'Arial Narrow';
  --ffvlLinkColor: #589ed0;
  --ffvlHeaderAndFooter: var(--ffvlOrangePicto);

  --ffvlPrimary: var(--ffvlBleuBaseline) !important;
  --ffvlSecondary: var(--ffvlBleuLogo) !important;
  --ffvlDanger: var(--ffvlAlert) !important;
  --ffvlInactif: darkgrey;
  --ffvlOrangeLight: #f1dcc6;
  --ffvlError: var(--ffvlAlert) !important;
  --ffvlErrorBackground: #ffdae0;
  --ffvlWarning: var(--ffvlOrangePicto);

  --impersonatedUserBackground: #cc00cc;
  --impersonatedUserColor: #ffffff;
}

input[type="number"] { text-align:  right; padding-right:2px; padding-left:2px;}
fieldset.form-group { padding-left: calc(0.475rem + 1px); padding-right: calc(0.475rem + 1px);}
fieldset.form-group > legend { color: var(--ffvlOrangePicto); text-align: left; padding-bottom: calc(0.175rem + 1px); font-size: 0.9rem;}
fieldset.form-group > div > legend span.label { color: var(--ffvlOrangePicto); text-align: left; padding-bottom: calc(0.175rem + 1px); font-size: 0.9rem;}
fieldset.fieldset {border:solid 1px var(--dark); margin: 10px; margin: calc(0.375rem + 1px); margin-bottom: calc(2rem + 1px) !important;  }
fieldset.fieldset > legend { font-size:1rem; text-align: left; width: auto; margin-left: 5px; padding-left: 10px; padding-right: 10px; color: var(--ffvlSecondary); font-weight: bold;  }
fieldset.form-group > div { display:flex; justify-content: flex-start; align-items: center;}
fieldset.form-group.readonly > input { color:darkgrey;}
.ffvlLogo { font-family: 'HandelGothicBT Regular', 'Arial'}
.ffvlText, .ffvlTitle { font-family: var(--ffvlFontFamily);}
.ffvlTitle { font-weight: bold; }
.ffvlLevalMentions { font-family: Helvetica; }
.ffvlTechnicalInfos { font-family: Helvetica; }
.ffvlLink { font-family: 'Arial Narrow', arial; color: var(--ffvlBleuLogo); }
.ffvlAlert { color: var(--ffvlAlert); }

div.info{ display: inline;}

.nav.bg-dark { background-color: var(--ffvlHeaderAndFooter) !important;   padding-left:5px; padding-right: 5px;
}
.nav-brand { font-family: 'HandelGothicBT Regular', 'Arial' !important;}
.nav-link { font-family: var(--ffvlLinkFontFamily) !important; color: var(--ffvlOrangeLight); }
.btn-primary { background-color: var(--ffvlPrimary) !important;}
.btn-outline-primary { border-color: var(--ffvlPrimary) !important; color: var(--ffvlPrimary) !important;}
.btn-secondary { background-color: var(--ffvlSecondary) !important;}
.btn-outline-primary.inactif { border-color: var(--ffvlInactif) !important; color: var(--ffvlInactif)!important; }

.nav-tabs > .nav-item{ border: solid 1px var(--ffvlBleuLogo);
  color: var(--ffvlBleuLogo) !important;
  border-radius: 5px 5px 0 0;
}
.nav-tabs > .nav-item > .nav-link{ color: var(--ffvlBleuLogo) !important; }
.nav-tabs > .nav-item > .nav-link.active{ color: var(--ffvlBleuBaseline); font-weight: bolder;}
.nav-pills > .nav-item > .nav-link { color: var(--ffvlOrangePicto)};
.nav-pills > .nav-item > .nav-link.active{ color: var(--ffvlOrangePicto); background-color: var(--ffvlOrangeLight) !important; }
a.btn.back {
  margin : 10px 20px 10px 20px !important;
  border: var(--ffvlOrangePicto) 1px solid;
  border-radius: 5px;
  background-color:darksalmon;
  color: white;
  text-decoration: none;
  padding: 3px;
}

helper-note {
  background-color: var(--ffvlOrangePicto);
  opacity:60%;
  border: solid 1px var(--ffvlOrangePicto);
  border-radius: 2px;
  font-size: 0.8rem;
  color: var(--ffvlDanger);
  font-style: italic;
  opacity:60%;
}

.synchro { color: green; }
.synchro.required { color: white; background-color:red;}

  div.header { font-size: 0.7rem; }
  fieldset.fieldset.danger{ border-color: var(--danger); }
  fieldset.fieldset.danger > legend { color: var(--danger); }
  fieldset.fieldset.danger > ul { color: var(--danger); }

  fieldset.fieldset.warning{ border-color: var(--warning); }
  fieldset.fieldset.warning > legend { color: var(--warning); }
  fieldset.fieldset.warning > ul { color: var(--warning); }
hr.env{
  margin: 0px !important;
  border-width: 5px;
  border-style: solid;
}

hr.dev-env{
  border:solid 5px yellow !important;
  margin: 0px !important;
  border-bottom: solid 1px black !important;
}
hr.debug-env{
  border: solid 5px red !important;
  margin: 0px !important;
  border-bottom: solid 1px black !important;;
}
hr.production{
  border-color: #a0abbd !important;
}
hr.tests{
  border-color: yellow !important;
}
hr.DEBUG{
  border-color: rgb(101, 251, 124) !important;
}
hr.env-top{
  border-bottom: 1px solid var(--ffvlHeaderAndFooter) !important;
}
hr.debug-top{
  border-bottom: 2px solid red !important;
}
hr.env-bottom{
  margin-bottom: 38px !important;
  border-top: 1px solid var(--ffvlHeaderAndFooter) !important;
}
hr.debug-bottom{
  border-top: 2px solid red !important;
}
y { background-image: "@/assets/bg.jpg";}
@media screen and (max-width: 767px) and (orientation: portrait) {

}
@media screen and (max-width: 500px){
  html{font-size: 8pt; color:green;}
}
@media screen and (max-width: 600px){
  html{font-size: 11pt;}
}
@media screen and (max-midth: 1024px){
  html{font-size: 14pt;}
}

</style>