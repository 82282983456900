<template>
    <b-form-group :class="{'unplugged': unplugged}"
    >
      <template #label v-if="!compactMode">
        <slot name="caption">
          <span :class="{ 'required' : required}">
            <slot name="caption-text">{{ captionLabel }}</slot>
          </span>
          <span v-if="hasSlot('info-helper-placeholder')">&nbsp;&nbsp;&nbsp;</span>
          <slot name="info-helper-placeholder"></slot>
        </slot>
        <div v-if="hasSlot('notice')" class="notice">
          <slot name="notice"></slot>
        </div>      
      </template>



      <b-input-group >
        <template #prepend v-if="compactMode || prepend || hasSlot('prepend')">
          <b-input-group-text v-if="compactMode">
            <slot name="caption">
              <span :class="{ 'required' : required}">
                <slot name="caption-text">{{ captionLabel }}</slot>
              </span>
              <span v-if="hasSlot('info-helper-placeholder')">&nbsp;&nbsp;&nbsp;</span>
              <slot name="info-helper-placeholder">
              </slot>
            </slot>
          </b-input-group-text>
          <slot name="prepend">
            <b-input-group-text v-if="prepend">{{ prepend }}
            </b-input-group-text>
          </slot>
        </template>
        <slot></slot>

        <b-form-invalid-feedback v-if="withValidation" :state="state"><slot name="invalid-feedback">{{ invalidFeedback }}</slot></b-form-invalid-feedback>
        <b-form-valid-feedback v-if="withValidation" :state="state"><slot name="valid-feedback">{{ validFeedback }}</slot></b-form-valid-feedback>
      </b-input-group>


      <!--<slot v-if="!compactMode"></slot>-->
    </b-form-group>
</template>

<script>

export default {
  props: {
    compactMode: { Type:Boolean, default:false },
    caption: { Type:String, default:''},
    label: { type:String, default: ''},
    required: Boolean,
    prepend: { type:String },
    append: { type:String },
    validFeedback: {type:String},
    invalidFeedback: {type:String},
    state: {type:Boolean, default:true},
    description:{type:String, default:null},
    withValidation:{type:Boolean, default:false},
    unplugged:{type:Boolean, default:false}
  },
  computed:{
    captionLabel(){
      if(this.caption != null && this.caption != '')
        return this.caption;
      return this.label;
    }
  },
  methods: {
    hasSlot(slotName = 'default') {
      return !!this.$slots[slotName];
    }
  }
}
</script>


<style scoped>
  span.required::after { content:'* '; color:var(--ffvlAlert);}
</style>
<style>
  .notice{ color: var(--ffvlSecondary) !important; font-size: 0.98em; font-style: italic;}
  .unplugged input {
    background-color: rgb(223, 204, 204) !important;
    color: darkred !important;
    pointer-events: none !important;
  }
  .unplugged input::before, .unplugged input::after{
    content: "&#2692;"
  }
  .unplugged legend, .unplugged legend span {
    color: #efc89f !important;
  }

</style>
