import { RST_LOG } from "@/LocalDB";
import { store } from "@/services/store";
import axios from "axios";
import { v4 as uuid} from 'uuid';
import { RST_COMPETITION, RST_FIELD, RST_ROUND, RST_LEVEL, RST_CATEGORY, RST_ROUND_COMPULSORY, RST_EVENT, RST_COMPETITION_STAFF, RST_COMPETITOR, RST_COMPETITOR_COMPOSITION, RST_JUDGE_EVENT_COMPULSORY_NOTE, RST_JUDGE_EVENT_NOTES, RST_COMPETITOR_COMPULSORY_NOTE, RST_COMPETITOR_NOTES, /*RST_BALLET_NOTE, RST_COMPULSORY_NOTE, RST_ROUTINE_NOTE*/ } from "@/LocalDB";

const SynchroHelper = {

  VERBOSE: false,

  /**
   * Enregistre une opération dans les logs de mise à jour du serveur
   * @param {String} operation - L'opération (INSERT, UPDATE, DELETE)
   * @param {String} oject_type - Type d'objet concerné (nom de la table)
   * @param {*} object_id - Id du record concerné (en cas d'UPDATE ou de DELETE; Null si INSERT)
   * @param {Json} value - Objet JSON représentant les modifications à apporter (en cas d'INSERT ou de UPDATE; Null si DELETE) 
   * @param {String} action - Description (clé) indiquant quelle action est à mener très exactement (généralement en cas de UPDATE pour préciser la mise à jour à accomplir) 
   * @returns { Number } - id de l'opération loguée
   */
  LogOperation(operation, oject_type, object_id, value, action = null){
    var username = store.getters["auth/connectedUser"].username;
    var inBehalfUsername = store.getters["auth/currentUser"].username;
    var lastLog = RST_LOG.query().orderBy('id', 'desc').first();
    var logId = (lastLog == null) ? 1 : lastLog.id + 1;
    RST_LOG.insert({ data : { id: logId, operation: operation, timestamp: new Date().toISOString().replace(/T/g,' ').replace(/Z/g,''), user: username, inBehalfOf: inBehalfUsername, object_type: oject_type, object_id: object_id, value: value, action: action, synchronizationPoint: null }});
    return logId;
  },

  TryToSynchronize()
  {
    var synchronizationPoint = uuid();
    RST_LOG.update(
      {
        where: record => record.synchronizationPoint == null,
        data: { synchronizationPoint: synchronizationPoint}
      });
    var baseUrl = store.getters["config/serverBaseUrl"];
    var url = baseUrl + '/api/setResults.php';
    var setOfData = RST_LOG.query().where("synchronizationPoint", synchronizationPoint).get();
    if(this.VERBOSE) console.log('TryToSynchronize -> logEntry', setOfData);
    axios
      .post(url, { dataChangeLog: setOfData })
      .then(response => {
        var data = response.data;
        if(this.VERBOSE) console.log('TryToSynchronize - response.ata', data);
        if(!data.success || data.items.length == 0)
        {
          RST_LOG.update(
            {
              where: record => record.synchronizationPoint == synchronizationPoint,
              data: { synchronizationPoint: null}
            });
        }
        data.items.forEach(item => {
          if(item.isSuccessful)
          {
            if(item.ALTER_ID){
              console.log('ALTER ID FOR LOG', item);
              RST_LOG.update({ where: (logitem) => { return logitem.object_type == item.ALTER_ID.tablename && logitem.object_id == item.ALTER_ID.source_id}, data: { object_id: item.ALTER_ID.target_id}});
              store.dispatch('entities/update', { entity: item.ALTER_ID.tablename, where: item.ALTER_ID.source_id, data: { id: item.ALTER_ID.target_id, isSync: true }});
            }
            else if(item.SYNC)
            {
              store.dispatch('entities/update', { entity: item.SYNC.tablename, where: item.SYNC.source_id, data: { isSync: true }});
            }
            RST_LOG.delete(item.id);
          } else {
            RST_LOG.update(
              {
                where: item.id,
                data: { synchronizationPoint: null, lastError: item.error }
              });
          }
        })
      })
      .catch(error => {
        console.log('TryToSynchronize ERROR =>', error);
        RST_LOG.update(
          {
            where: record => record.synchronizationPoint == synchronizationPoint,
            data: { synchronizationPoint: null}
          });
      })
  },

  async TryToSynchronizeAsync(ids)
  {
    if(this.VERBOSE) console.log('TryToSynchronizeAsync -> ids', ids);
    var synchronizationPoint = uuid();
    RST_LOG.update(
      {
        where: record => record.synchronizationPoint == null,
        data: { synchronizationPoint: synchronizationPoint}
      });
    var baseUrl = store.getters["config/serverBaseUrl"];
    var url = baseUrl + '/api/setResults.php';
    var setOfData = RST_LOG.query().where("synchronizationPoint", synchronizationPoint).get();
    if(this.VERBOSE) console.log('TryToSynchronizeAsync -> logEntry', setOfData);
    try{
      var response = await axios.post(url, { dataChangeLog: setOfData });
      var data = response.data;
      if(this.VERBOSE) console.log('TryToSynchronizeAsync - response.data', data);
      if(!data.success || data.items.length == 0)
      {
        RST_LOG.update(
          {
            where: record => record.synchronizationPoint == synchronizationPoint,
            data: { synchronizationPoint: null}
          });
      }
      data.items.forEach(item => {
        if(item.isSuccessful)
        {
          if(item.ALTER_ID){
            console.log('ALTER ID FOR LOG', item);
            RST_LOG.update({ where: (logitem) => { return logitem.object_type == item.ALTER_ID.tablename && logitem.object_id == item.ALTER_ID.source_id}, data: { object_id: item.ALTER_ID.target_id}});
            store.dispatch('entities/update', { entity: item.ALTER_ID.tablename, where: item.ALTER_ID.source_id, data: { id: item.ALTER_ID.target_id}});
          }
          RST_LOG.delete(item.id);
          ids = ids.filter(id => id != item.id);
          if(this.VERBOSE) console.log('TryToSynchronizeAsync -> successfulItem', item.id);
        } else {
          RST_LOG.update(
            {
              where: item.id,
              data: { synchronizationPoint: null, lastError: item.error }
            });
        }
      });
      if(this.VERBOSE) console.log('TryToSynchronizeAsync -> ids (at end)', ids);
      return ids == null || ids.length == 0;
    }
    catch(error) {
      console.log('TryToSynchronizeAsync ERROR =>', error);
      RST_LOG.update(
        {
          where: record => record.synchronizationPoint == synchronizationPoint,
          data: { synchronizationPoint: null}
        });
      return false;
    }
  },

  IsThereRemainingLogsToSynchronize(){
    return RST_LOG.query().where('synchronizationPoint', null).exists();
  },

  get SomeRemainingNonSynchronizedLogsExist(){
    return this.IsThereRemainingLogsToSynchronize();
  },
  get RemainingLogsCount(){
    return RST_LOG.query().where('synchronizationPoint', null).count();
  },

  refreshLinkedCompetition(competition_id, callbackWhenCompleted){
    // reste-t-il des données à transmettre ?
    var baseUrl = store.getters["config/serverBaseUrl"];
    var url = baseUrl + '/api/getWholeCompetitionData.php';

    axios
      .post(url, { id: competition_id })
      .then(response => {
        var data = response.data;
        if(data.success)
        {
          console.error('NEED TO CHECK THIS METHOD');
          data.competitions.forEach(competition => {
            // Traitement de mise à jour de la compéition.
            RST_COMPETITION.insertOrUpdate({ data: competition });

            // Mise à jour des catégories, suppression des celles qui n'ont plus lieux d'être.
            RST_FIELD.delete(where => { return where.competition_id == competition.id});
            data.fields.filter(r => r.competition_id == competition.id)
                        .forEach(r => RST_FIELD.insertOrUpdate({ data: r}));

            // Mise à jour des manches, suppression des celles qui n'ont plus lieux d'être.
            RST_ROUND.delete(where => { return where.competition_id == competition.id});
            data.rounds.filter(r => r.competition_id == competition.id)
                        .forEach(r => RST_ROUND.insertOrUpdate({ data: r}));

            // Mise à jour des niveaux, suppression des reux qui n'ont plus lieux d'être.
            RST_LEVEL.delete(where => { return where.competition_id == competition.id});
            data.levels.filter(r => r.competition_id == competition.id)
                        .forEach(r => RST_LEVEL.insertOrUpdate({ data: r}));

            // Mise à jour des catégories, suppression des celles qui n'ont plus lieux d'être.
            RST_CATEGORY.delete(where => { return where.competition_id == competition.id});
            data.categories.filter(r => r.competition_id == competition.id)
                        .forEach(r => RST_CATEGORY.insertOrUpdate({ data: r}));

            // Mise à jour des figures, suppression des celles qui n'ont plus lieux d'être.
            RST_ROUND_COMPULSORY.delete(where => { return where.competition_id == competition.id});
            data.compulsories.filter(r => r.competition_id == competition.id)
                        .forEach(r => RST_ROUND_COMPULSORY.insertOrUpdate({ data: r}));

            // Mise à jour des épreuves, suppression des celles qui n'ont plus lieux d'être.
            RST_EVENT.delete(where => { return where.competition_id == competition.id});
            data.events.filter(r => r.competition_id == competition.id)
                        .forEach(r => RST_EVENT.insertOrUpdate({ data: r}));

            // Mise à jour des l'utilisation des membres du staff, suppression des celles qui n'ont plus lieux d'être.
            RST_COMPETITION_STAFF.delete(where => { return where.competition_id == competition.id});
            data.staffMembers.filter(r => r.competition_id == competition.id)
                        .forEach(r => RST_COMPETITION_STAFF.insertOrUpdate({ data: r}));

            // Mise à jour des compétiteurs inscrits, suppression des celles qui n'ont plus lieux d'être.
            RST_COMPETITOR.delete(where => { return where.competition_id == competition.id});
            data.competitors.filter(r => r.competition_id == competition.id)
                        .forEach(r => RST_COMPETITOR.insertOrUpdate({ data: r}));

            // Mise à jour des co-équipier des paires et teams, suppression des celles qui n'ont plus lieux d'être.
            RST_COMPETITOR_COMPOSITION.delete(where => { return where.competition_id == competition.id});
            data.teamsMembers.filter(r => r.competition_id == competition.id)
                        .forEach(r => RST_COMPETITOR_COMPOSITION.insertOrUpdate({ data: r}));

            // Mise à jour des notes des juges au niveau des figures de précision, suppression des celles qui n'ont plus lieux d'être.
            RST_JUDGE_EVENT_COMPULSORY_NOTE.delete(where => { return where.competition_id == competition.id});
            data.judgesCompulsoriesNotes.filter(r => r.competition_id == competition.id)
                        .forEach(r => RST_JUDGE_EVENT_COMPULSORY_NOTE.insertOrUpdate({ data: r}));

            // Mise à jour des notes totales par figure, suppression des celles qui n'ont plus lieux d'être.
            RST_COMPETITOR_COMPULSORY_NOTE.delete(where => { return where.competition_id == competition.id});
            data.compulsoriesNotesSummaries.filter(r => r.competition_id == competition.id)
                        .forEach(r => RST_COMPETITOR_COMPULSORY_NOTE.insertOrUpdate({ data: r}));

            // Mise à jour des notes et totaux par juge pour les épreuves autre que les figures de précision, suppression des celles qui n'ont plus lieux d'être.
            RST_JUDGE_EVENT_NOTES.delete(where => { return where.competition_id == competition.id});
            data.judgesNotes.filter(r => r.competition_id == competition.id)
                        .forEach(r => RST_JUDGE_EVENT_NOTES.insertOrUpdate({ data: r}));

            // Mise à jour des note et totaux globales, suppression des celles qui n'ont plus lieux d'être.
            RST_COMPETITOR_NOTES.delete(where => { return where.competition_id == competition.id});
            data.notesSummaries.filter(r => r.competition_id == competition.id)
                        .forEach(r => RST_COMPETITOR_NOTES.insertOrUpdate({ data: r}));

            callbackWhenCompleted();
          });
        }
      })
      .catch(error => {
        console.log('refreshLinkedCompetition ERROR =>', error);
        callbackWhenCompleted();
      });
  }
}

export default SynchroHelper;
