import { store } from "@/services/store";
import axios from "axios";

import {
  RST_COMPETITION,
  RST_ROUND,
  RST_ROUND_COMPETITOR,
  RST_LEVEL,
  RST_CATEGORY,
  RST_REF_CATEGORY,
  RST_REF_EVENT,
  RST_EVENT,
  RST_EVENT_STAFF,
  RST_COMPETITION_STAFF,
  RST_EVENT_COMPULSORY,
  RST_COMPETITOR,
  RST_COMPETITOR_COMPOSITION,
  SEASON_COMPETITION_TYPE_CATEGORY_LEVEL,
  RST_FIELD,
  RST_EVENT_COMPETITOR,
  LICENSEE,
  RST_COMPETITION_ADMIN,
  RST_BALLET_NOTE,
  RST_COMPULSORY_NOTE,
  RST_ROUTINE_NOTE,
  RST_DELEGATE,
  REF_EVENT,
  STAFF_CAPABILITY
} from "@/LocalDB";
import SynchroHelper from "@/services/helpers/synchroHelper";
import { v4 as uuid} from 'uuid';
import { COMPETITOR, PEOPLE } from "@/LocalDB";
import { RolesEnum } from "../../Constants";

const aleaCharStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZABCDEFGHIJKLMNOPQRSTUVWXYZABCDEFGHIJKLMNOPQRSTUVWXYZABCDEFGHIJKLMNOPQRSTUVWXYZ';

const CompetitionsConfigurationHelper = {
    VERBOSE: false,

    getAleaChar(){
      var n = Math.floor(Math.random() * 10) * Math.sign(Math.random() - 0.5);
      return aleaCharStr.at(n);
    },
    getNewCompetitionId(location, startDate ){
      if(this.VERBOSE) console.log('getNewCompetitionId', location, startDate);
      location = (location == null || location == undefined) ? '' : location.trim();
      var d = (startDate == null || startDate == undefined) ? new Date() : new Date(startDate);
      var y = ("" + d.getFullYear()).substring(2,4);
      var m = ("" + (d.getMonth()+1)).padStart(2, "0");
      if(this.VERBOSE) console.log(d, y, m);
      switch(location.length){
        case 0:
          return (this.getAleaChar() + this.getAleaChar() + this.getAleaChar() + d.substring(d.length - 2, d.length-1) + d.substring(3,5)).toUpperCase();
        case 1:
          return (this.getAleaChar() + location + this.getAleaChar() + d.substring(d.length - 2, d.length-1) + d.substring(3,5)).toUpperCase();
        case 2:
          return (location.substring(0,0) + this.getAleaChar() + location.substring(1,1) + d.substring(d.length - 2, d.length-1) + d.substring(3,5)).toUpperCase();
      }
      return (location.substring(0,2) + location.substring(location.length-1, location.length) + y + m + this.getAleaChar()).toUpperCase();
    },
    async getUserRunningCompetitionsAsync(forTestMode){
      if(forTestMode == null || forTestMode == undefined)
      {
        forTestMode = store.getters["config/isTestMode"];
      }
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/competitions/getList.php';

      try{
        const response = await axios.post(url);
        if(this.VERBOSE) console.log('getUserRunningCompetitionsAsync =>', response);
        store.commit("synchro/setServerNotResponding", false);
        store.commit('synchro/setNetAvailability', true);

        if(response.data && response.data.competitions){
          var alreadySync = RST_COMPETITION.query().where("isSync", true).get();
          alreadySync.forEach(item => { RST_COMPETITION.delete(item.id)});
          RST_COMPETITION.insertOrUpdate({ data: response.data.competitions });
        }
        if(response.data && response.data.admins){
          alreadySync = RST_COMPETITION_ADMIN.query().where("isSync", true).get();
          alreadySync.forEach(item => RST_COMPETITION_ADMIN.delete(item.id));
          RST_COMPETITION_ADMIN.insertOrUpdate({ data: response.data.admins});
        }
        if(response.data && response.data.staffMembers){
          alreadySync = RST_COMPETITION_STAFF.query().where("isSync", true).get();
          alreadySync.forEach(item => RST_COMPETITION_STAFF.delete(item.id));
          RST_COMPETITION_STAFF.insertOrUpdate({ data: response.data.staffMembers});
        }
      }catch(error){
        if(this.VERBOSE) console.error('getUserRunningCompetitionAsync', { status: error.response.status, error: error});
        if(error.response && error.response.status == 500){
          store.commit("synchro/setServerNotResponding", true);
          store.commit('synchro/setNetAvailability', false);
        } 
      }
      var currentUserVisa = store.getters["auth/currentUser"].userName;
      if(this.VERBOSE) console.log('currentUserVisa', currentUserVisa);
      var runningCompetitions = RST_COMPETITION.query()
        .where("isForSimulationPurposeOnly", forTestMode)
        .where('isOver', false)
        .where(c => c.owner == currentUserVisa || c.ADMINS.findIndex(a => a.admin == currentUserVisa) >= 0 || c.STAFF_MEMBERS.findIndex(sm => sm.PEOPLE && sm.PEOPLE.VISA && sm.PEOPLE.VISA.visa == currentUserVisa) >= 0)
        .orderBy('predicted_start_date', 'desc')
        .get();

      return runningCompetitions;
    },
    async hasUserRunningCompetitionsAsync(forTestMode){
      return (await this.getUserRunningCompetitionsAsync(forTestMode).length) > 0
    },
    async getUserArchivedCompetitionsAsync(forTestMode){
      if(this.VERBOSE) console.log('getUserArchivedCompetitionsAsync', forTestMode);
      if(forTestMode == null || forTestMode == undefined)
      {
        forTestMode = store.getters["config/isTestMode"];
      }
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/competitions/getArchivedList.php';
      var currentUserVisa = store.getters["auth/currentUser"].userName;
      const response = await axios.post(url, {visa: currentUserVisa, testMode: (forTestMode ? 1 : 0)});
      if(this.VERBOSE) console.log('getUserArchivedCompetitionsAsync =>', response);
      if(response.data && response.data.competitions)
        return response.data.competitions;
      return [];
    },
    async getStaffWithCapabilitiesAsync(competition_id){
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/competitions/getStaffMembers.php';
      var sync = true;
      try{
        const response = await axios.post(url, { competition_id: competition_id});
        //if(this.VERBOSE) console.log('getStaffWithCapabilitiesAsync =>', competition_id, response);
        if(response.data && response.data.staffMembers)
        {
          RST_COMPETITION_STAFF.insertOrUpdate({ data: response.data.staffMembers});
          STAFF_CAPABILITY.insertOrUpdate({ data: response.data.staffCapabilities});
          PEOPLE.insertOrUpdate({ data: response.data.staffPeople });
          LICENSEE.insertOrUpdate({ data: response.data.staffLicensees });
          sync = true;
        }
      }catch(error){
        sync = false;
      }

        return { isSync: sync, data: RST_COMPETITION_STAFF.query()
          .where('competition_id', competition_id)
          .get()};
      //}
      //return [];
    },
    async get(competition_id){
      return RST_COMPETITION.query().where(c => c.id == competition_id).first();
    },
    missingRequirementsOnCreation(container)
    {
        var ret = [];
        if(container == null)
          return ret;
        var competition = container.competition;

        if(competition.year == null || competition.year == '' || competition.year == 0)
          ret.push({model: 'year', text:'L\'année e la compétition est manquante.' });
        if(competition.name == null || competition.name == '')
          ret.push({model: 'name', text: 'Le nom de la compétition est manquant.'});
        if(competition.location == null || competition.location == '')
          ret.push({ model:'location', text: 'Le lieu de la compétition est manquant.'});
        if(competition.competition_type == null || competition.competition_type == '')
          ret.push('Le type de compétition est manquant.');
        if(competition.scope == null || competition.scope == '')
          ret.push({model: 'scope', text: "L'échelon est manquant."});
        if((container.levels && container.levels.length == 0) || (container.LEVELS && container.LEVELS.length == 0))
          ret.push({ model: 'levels', text: "Aucun niveau n'est défini pour la compétition."});
        if(competition.regulation == null || competition.regulation == '')
          ret.push({ model: 'regulation', text: "Le règlement applicable pour cette compétition n'est pas défini."});
        if(competition.resultCalculationMode == null || competition.resultCalculationMode == '')
          ret.push({model: 'resultCalculationMode', text: "Le mode de calcul du résultat final n'est pas défini pour cette compétition."});

        if(competition.predicted_start_date == null || competition.predictedStartDate == '')
          ret.push({model: 'predictedDate', text: "La date prévue de la compétition n'est pas indiquée."})
        return ret;
    },
    missingRequirementsBeforeRun(competitionId){
        var competition = RST_COMPETITION.query().where('id', competitionId).first();
        return competition.ConfigurationErrors;
    },
    warningsBeforeRun(competitionId){
      if(this.VERBOSE) console.log("warningsBEforeRun", competitionId);
      var ret = [];
      return ret;
    },
    buildCompetitionContainer(competition_type, year, scope, name, location, isOpen, isForSimulationPurposeOnly, regulation, resultCalculationMode, selectedLevels, predictedStartDate, predictedEndDate)
    {
      var container = {};

      if(predictedEndDate == null || predictedEndDate == '')
        predictedEndDate = predictedStartDate;

      var newid = this.getNewCompetitionId(location, predictedStartDate);
      if(this.VERBOSE) console.log('buildCompetitionContainer newid', newid);
      container.competition = {
        id: newid,
        name: name,
        year: year,
        competition_type: competition_type,
        scope: scope,
        isOpen: isOpen,
        isStarted: false,
        isCompleted: false,
        isForSimulationPurposeOnly: isForSimulationPurposeOnly,
        location: location,
        regulation: regulation,
        resultCalculationMode: resultCalculationMode,
        predicted_start_date: predictedStartDate,
        predicted_end_date: predictedEndDate,
      };

      var newFieldId = uuid();
      var defaultField = {
        id: newFieldId,
        competition_id: newid,
        field_number: 1,
        field_name: "Terrain principal",
        size_A: 0, size_B: 0,
        stage_in: '', stage_out: '',
        isMain: true,
        isSonorized: false,
        isHot: false,
      };
      container.fields = [];
      container.fields.push(defaultField);

      container.levels = [];
      selectedLevels.forEach(lvl => {
        var newLevel = {
          id: uuid(),
          competition_id: newid,
          level: lvl.code
        };
        container.levels.push(newLevel);
      });
      return container;
    },
    async createCompetitionFromContainerAsync(competitionContainer)
    {
      var operationsIds = [];

      if(!competitionContainer.competition.owner || competitionContainer.competition.owner == null || competitionContainer.competition.owner == '' ) {
        var currentUserVisa = store.getters["auth/currentUser"].userName;
        competitionContainer.competition.owner = currentUserVisa;
      }

      if(competitionContainer.competition.predicted_end_date == null || competitionContainer.competition.predicted_end_date == '')
        competitionContainer.competition.predicted_end_date = competitionContainer.competition.predicted_start_date; 

      RST_COMPETITION.insertOrUpdate({ data: competitionContainer.competition });
      operationsIds.push(SynchroHelper.LogOperation("INSERT", "RST_COMPETITION", null, competitionContainer.competition));

      competitionContainer.fields.forEach(fld => {
        RST_FIELD.insertOrUpdate({ data: fld });
        operationsIds.push(SynchroHelper.LogOperation("INSERT", "RST_FIELD", null, fld ));
      })

      competitionContainer.levels.forEach(lvl => {
        RST_LEVEL.insertOrUpdate({ data: lvl });
        operationsIds.push(SynchroHelper.LogOperation("INSERT", "RST_LEVEL", null, lvl));
      });

      var success = await SynchroHelper.TryToSynchronizeAsync(operationsIds);

      return { success: success, competition: RST_COMPETITION.query().where("id", competitionContainer.competition.id).first()};
    },
    createCompetition(competition_type, year, scope, name, location, isOpen, isForSimulationPurposeOnly, nbRounds, roundAverageComputationRule, nbRoundToSkipOrKeep, selectedLevels ){
      var newid = this.getNewCompetitionId(location, null);
      if(this.VERBOSE) console.log('createCompetition newid', newid);
      var newCompetition = {
        id: newid,
        name: name,
        year: year,
        competition_type: competition_type,
        scope: scope,
        isOpen: isOpen,
        isStarted: false,
        isCompleted: false,
        isForSimulationPurposeOnly: isForSimulationPurposeOnly,
        location: location,
        roundAverageComputationRule: roundAverageComputationRule,
        nbRoundToSkipOrKeep: nbRoundToSkipOrKeep,
        isSync: false,
      };
      RST_COMPETITION.insertOrUpdate({ data: newCompetition });
      SynchroHelper.LogOperation("INSERT", "RST_COMPETITION", null, newCompetition);

      var newFieldId = uuid();
      var defaultField = {
        id: newFieldId,
        competition_id: newid,
        field_number: 1,
        field_name: "Terrain principal",
        size_A: 0, size_B: 0,
        stage_in: '', stage_out: '',
        isMain: true,
        isSonorized: true,
        isHot: false,
      };
      RST_FIELD.insertOrUpdate({ data: defaultField });
      SynchroHelper.LogOperation("INSERT", "RST_FIELD", null, defaultField );

      selectedLevels.forEach(lvl => {
        var newLevel = {
          id: uuid(),
          competition_id: newid,
          level: lvl.code
        };
        RST_LEVEL.insertOrUpdate({ data: newLevel });
        SynchroHelper.LogOperation("INSERT", "RST_LEVEL", null, newLevel);
      });

      for(var i=0; i < nbRounds; i++){
        var newRound = {
          id: uuid(),
          competition_id: newid,
          round_number: i + 1,
        };
        RST_ROUND.insertOrUpdate({ data: newRound });
        SynchroHelper.LogOperation("INSERT", "RST_ROUND", null, newRound);

        selectedLevels.forEach(level =>{
          var newLevel = {
            id: uuid(),
            competition_id: newid,
            round_number: i + 1,
            level: level.code
          }
          RST_LEVEL.insertOrUpdate({ data: newLevel });
          SynchroHelper.LogOperation("INSERT", "RST_LEVEL", null, newLevel);

          SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.query()
            .where("competition_type", competition_type)
            .where("level", level.code)
            .where("year", year)
            .get()
            .forEach(item => {
              var newCategory = {
                id: uuid(),
                competition_id: newid,
                round_number: i + 1,
                level: level.code,
                category: item.category,
              };
              RST_CATEGORY.insertOrUpdate({ data: newCategory });
              SynchroHelper.LogOperation("INSERT", "RST_CATEGORY", null, newCategory);
            });
        });
      }
      SynchroHelper.TryToSynchronize();
      return RST_COMPETITION.query().where("id", newid).first();
    },
    async changeRegistrationMode(competitionId, registrationMode){
      var found = RST_COMPETITION.query().where("competition_id", competitionId).first();
      if(found != null){
        var data = { registrationMode:  registrationMode};
        RST_COMPETITION.update({ where: found.id, data: data });
        var operationId = SynchroHelper.LogOperation("UPDATE", "RST_COMPETITION", found.id, data, "CHANGE_REGISTRATION_MODE");
        return await SynchroHelper.TryToSynchronizeAsync([operationId]);
      }
    },
    async changeCompetitionChiefJudgeAsync(competitionId, staff_member_id)
    {
      var found = RST_COMPETITION.query().where("competition_id", competitionId).first();
      if(found != null){
        var data = { chiefJudge_staff_id: staff_member_id };
        RST_COMPETITION.update({ where: found.id, data: data });
        var operationId = SynchroHelper.LogOperation("UPDATE", "RST_COMPETITION", found.id, data, "CHANGE_CHIEF_JUDGE");
        return await SynchroHelper.TryToSynchronizeAsync([operationId]);
      }
    },
    addField(competition_id)
    {
      var lastFieldNumber = RST_FIELD.query()
                              .where("competition_id", competition_id)
                              .orderBy("field_number", "desc")
                              .first();
      var newFieldNumber = (lastFieldNumber == null) ? 1 : lastFieldNumber.field_number + 1;
      var data = {
        id: uuid(),
        competition_id: competition_id,
        field_number: newFieldNumber,
        field_name: 'Terrain n° ' + newFieldNumber,
        size_A: 0,
        size_B: 0,
        isSonorized: false,
        stage_in: '',
        stage_out: '',
        isMain: (lastFieldNumber == null),
        ishot: false
      };
      RST_FIELD.insertOrUpdate({ data: data });
      SynchroHelper.LogOperation("INSERT", "RST_FIELD", null, data );
      SynchroHelper.TryToSynchronize();
    },
    removeField(competition_id, field_number){
      var found = RST_FIELD.query().where("competition_id", competition_id).where("field_number", field_number).first();
      if(found != null)
      {
        RST_FIELD.delete(found.id);
        SynchroHelper.LogOperation("DELETE", "RST_FIELD", found.id, null );
        var i = 2;
        RST_FIELD.query().where("competition_id", competition_id).where("isMain", false).orderBy("field_number").get().forEach(item => {
          if(item.field_number != i)
          {
            var renumData = { field_number: i};
            RST_FIELD.update({ where: item.id, data: renumData});
            SynchroHelper.LogOperation("UPDATE", "RST_FIELD", item.id, renumData, "CHANGE_NUMBER");
          }
          i++;
        })
        SynchroHelper.TryToSynchronize();
      }
    },

    removeCompetitionFromLocalDb(competitionId)
    {
      RST_BALLET_NOTE.delete((item) => {return item.competition_id == competitionId});
      RST_COMPETITION.delete((item) => { return item.competition_id == competitionId });
      RST_COMPETITION_ADMIN.delete((item) => { return item.competition_id == competitionId });
      RST_COMPETITION_STAFF.delete(item => { return item.competition_id == competitionId });
      RST_COMPETITOR.delete((item) => { return item.competition_id == competitionId });
      RST_COMPETITOR_COMPOSITION.delete((item) => { return item.competition_id == competitionId });
      RST_COMPULSORY_NOTE.delete((item) => { return item.competition_id == competitionId });
      RST_DELEGATE.delete((item) => { return item.competition_id == competitionId });
      RST_EVENT.delete((item) => { return item.competition_id == competitionId });
      RST_EVENT_COMPETITOR.delete((item) => { return item.competition_id == competitionId });
      RST_EVENT_COMPULSORY.delete((item) => { return item.competition_id == competitionId });
      RST_EVENT_STAFF.delete((item) => { return item.competition_id == competitionId });
      RST_FIELD.delete((item) => { return item.competitionId == competitionId});
      RST_LEVEL.delete((item) => { return item.competition_id == competitionId });
      RST_REF_CATEGORY.delete((item) => { return item.competitionId == competitionId} );
      RST_REF_EVENT.delete((item) => { return item.competitionId == competitionId} );
      RST_ROUND.delete((item) => { return item.competition_id == competitionId });
      RST_ROUND_COMPETITOR.delete((item) => { return item.competition_id == competitionId });
      RST_ROUTINE_NOTE.delete((item) => { return item.competition_id == competitionId });
    },
    async deleteCompetitionAsync(competitionId){
      this.removeCompetitionFromLocalDb(competitionId);
      var operationId = SynchroHelper.LogOperation("DELETE", "RST_COMPETITION", competitionId, null);
      if(this.VERBOSE) console.log('Suppression locale de compétition', operationId);
      //return await SynchroHelper.TryToSynchronizeAsync([operationId]);
      return false;
    },

    async renameCompetitionAsync(competitionId, newValue)
    {
      var comp = RST_COMPETITION.query().where("id", competitionId).first();
      if(comp == null)
        return false;
      var change = { name : newValue };
      RST_COMPETITION.update({ where: competitionId, data: change });
      var operationId = SynchroHelper.LogOperation("UPDATE", "RST_COMPETITION", competitionId, change, "RENAME");
      return await SynchroHelper.TryToSynchronizeAsync([operationId]);
    },
    async changeCompetitionLocationAsync(competitionId, newValue)
    {
      var comp = RST_COMPETITION.query().where("id", competitionId).first();
      if(comp == null)
        return false;
      var change = { location : newValue };
      RST_COMPETITION.update({ where: competitionId, data: change });
      var operationId = SynchroHelper.LogOperation("UPDATE", "RST_COMPETITION", competitionId, change, "CHANGE_LOCATION");
      return await SynchroHelper.TryToSynchronizeAsync([operationId]);
    },
    changeCompetitionLockState(competition_id, newValue)
    {
      var comp = RST_COMPETITION.query().where("id", competition_id).first();
      if(comp == null)
        return false;
      var change = { isLockedForChanges: newValue};
      RST_COMPETITION.update({ where: competition_id, data: change});
      SynchroHelper.LogOperation("UPDATE", "RST_COMPETITION", competition_id, change, "CHANGE_LOCK_STATE");
    },
    async changeCompetitionLockStateAsync(competition_id, newValue){
      var comp = RST_COMPETITION.query().where("id", competition_id).first();
      if(comp == null)
        return false;
      var change = { isLockedForChanges: newValue};
      RST_COMPETITION.update({ where: competition_id, data: change});
      var operationId = SynchroHelper.LogOperation("UPDATE", "RST_COMPETITION", competition_id, change, "CHANGE_LOCK_STATE");
      return await SynchroHelper.TryToSynchronizeAsync([operationId]);
    },
    async changeIsOpenFlagAsync(competitionId, newValue)
    {
      var comp = RST_COMPETITION.query().where("id", competitionId).first();
      if(comp == null)
        return false;
      var change = { isOpen : newValue };
      RST_COMPETITION.update({ where: competitionId, data: change });
      var operationId = SynchroHelper.LogOperation("UPDATE", "RST_COMPETITION", competitionId, change, "CHANGE_ISOPEN_FLAG");
      return await SynchroHelper.TryToSynchronizeAsync([operationId]);
    },
    async changeIsOverFlagAsync(competitionId, newValue)
    {
      var comp = RST_COMPETITION.query().where("id", competitionId).first();
      if(comp == null)
        return false;
      var change = { isOver : newValue };
      RST_COMPETITION.update({ where: competitionId, data: change });
      var operationId = SynchroHelper.LogOperation("UPDATE", "RST_COMPETITION", competitionId, change, "CHANGE_ISOVER_STATE");
      return await SynchroHelper.TryToSynchronizeAsync([operationId]);
    },
    async changeResultCalculationModeAsync(competitionId, newValue){
      var comp = RST_COMPETITION.query().where("id", competitionId).first();
      if(comp == null){
        if(this.VERBOSE) console.log('comp non trouvée', competitionId);
        return false;
      }
      var change = { resultCalculationMode : newValue };
      if(this.VERBOSE) console.log('changeResultCalculationModeAsync', change);
      RST_COMPETITION.update({ where: competitionId, data: change });
      var operationId = SynchroHelper.LogOperation("UPDATE", "RST_COMPETITION", competitionId, change, "CHANGE_RESULT_CALCULATION_MODE");
      return await SynchroHelper.TryToSynchronizeAsync([operationId]);
    },
    changeRoundAverageComputationRule(competitionId, newValue){
      var comp = RST_COMPETITION.query().where("id", competitionId).first();
      if(comp == null)
        return false;
      var change = { roundAverageComputationRule : newValue };
      RST_COMPETITION.update({ where: competitionId, data: change });
      SynchroHelper.LogOperation("UPDATE", "RST_COMPETITION", competitionId, change, "CHANGE_ROUNDS_COMPUTATION_RULE");
      SynchroHelper.TryToSynchronize();
    },
    changeNbRoundToSkipOrKeep(competitionId, newValue){
      var comp = RST_COMPETITION.query().where("id", competitionId).first();
      if(comp == null)
        return false;
      var change = { nbRoundToSkipOrKeep : newValue };
      RST_COMPETITION.update({ where: competitionId, data: change });
      SynchroHelper.LogOperation("UPDATE", "RST_COMPETITION", competitionId, change, "CHANGE_NB_ROUNDS_TO_SKIP_OR_KEEP");
      SynchroHelper.TryToSynchronize();
    },
    toggleStaffMemberPresence(competitionId, refStaffMemberId){
      var found = RST_COMPETITION_STAFF.query().where("competition_id", competitionId).where("staff_id", refStaffMemberId).first();
      if(found != null){
          RST_COMPETITION_STAFF.delete(found.id);
          SynchroHelper.LogOperation("DELETE", "RST_COMPETITION_STAFF", found.id, null);
      } else {
          var newRecord = {
            id: uuid(),
            competition_id: competitionId,
            staff_id: refStaffMemberId,
          };
          RST_COMPETITION_STAFF.insertOrUpdate({ data: newRecord})
          SynchroHelper.LogOperation("INSERT", "RST_COMPETITION_STAFF", null, newRecord, "ASSOC_STAFF_TO_COMPETITION");
      }
      SynchroHelper.TryToSynchronize();
    },
    async toggleStaffMemberPresenceAsync(competitionId, refStaffMemberId){
      var found = RST_COMPETITION_STAFF.query().where("competition_id", competitionId).where("staff_id", refStaffMemberId).first();
      var operationId = null;
      if(found != null){
          RST_COMPETITION_STAFF.delete(found.id);
          operationId = SynchroHelper.LogOperation("DELETE", "RST_COMPETITION_STAFF", found.id, null);
      } else {
          var newRecord = {
            id: uuid(),
            competition_id: competitionId,
            staff_id: refStaffMemberId,
          };
          RST_COMPETITION_STAFF.insertOrUpdate({ data: newRecord})
          operationId = SynchroHelper.LogOperation("INSERT", "RST_COMPETITION_STAFF", null, newRecord, "ASSOC_STAFF_TO_COMPETITION");
      }
      await SynchroHelper.TryToSynchronizeAsync([operationId]);
    },
    async toggleStaffMemberMainChiefJudgeStateAsync(competitionId, refStaffMemberId){
      var found = RST_COMPETITION_STAFF.query().where("competition_id", competitionId).where("staff_id", refStaffMemberId).first();
      var change = {
        isChiefJudge : !found.isChiefJudge
      };
      RST_COMPETITION_STAFF.update({ where: found.id, data: change});
      var operationId = SynchroHelper.LogOperation("UPDATE", "RST_COMPETITION_STAFF", found.id, change, "CHANGE_CHIEF_JUDGE_STATE");
      return await SynchroHelper.TryToSynchronizeAsync([operationId]);
    },
    getNewPeopleId(firstname, lastname ){
      if(this.VERBOSE) console.log('getNewPeopleId', firstname, lastname);
      firstname = (firstname == null || firstname == undefined) ? this.getAleaChar() + this.getAleaChar() : firstname.trim();
      lastname = (lastname == null || lastname == undefined) ? this.getAleaChar() + this.getAleaChar() : lastname.trim();
      if(this.VERBOSE) console.log(firstname, lastname);
      var d = new Date();
      if(this.VERBOSE) console.log('d', d);
      var y = ("" + d.getFullYear()).substring(2,4);
      var m = ("" + (d.getMonth()+1)).padStart(2, "0");
      if(this.VERBOSE) console.log(d, y, m);
      var ret = (firstname.substring(0,1) + firstname.substring(firstname.length-1, firstname.length)
              + lastname.substring(0,1) + lastname.substring(lastname.length - 1, lastname.length)
              + y + m + this.getAleaChar()).toUpperCase();
      if(this.VERBOSE) console.log(ret);
      return ret;
    },
    create_new_individual_competitor_from_scratch(competition_type, category_code, firstname, lastname, federal_number, sexe = '', birthdate = null, email = null )
    {
      var change = {
        id: this.getNewPeopleId(firstname, lastname),
        firstname: firstname,
        lastname: lastname,
        federalNumber: federal_number,
        sexe: sexe,
        birthdate: birthdate,
        email: email,
        isStaff: false
      };
      PEOPLE.insert({ data: change});
      SynchroHelper.LogOperation("INSERT", "PEOPLE", null, change);

      return this.create_new_individual_competitor_from_people(competition_type, category_code, change.id);
    },
    async create_new_individual_competitor_from_scratchAsync(competition_type, category_code, firstname, lastname, federal_number, sexe = '', birthdate = null, email = null )
    {
      var operationIds = [];
      var change = {
        id: this.getNewPeopleId(firstname, lastname),
        firstname: firstname,
        lastname: lastname,
        federalNumber: federal_number,
        sexe: sexe,
        birthdate: birthdate,
        email: email,
        isStaff: false
      };
      PEOPLE.insert({ data: change});
      operationIds.push(SynchroHelper.LogOperation("INSERT", "PEOPLE", null, change));

      var people = PEOPLE.query().where("id", change.id).first();

      var compChange = {
        id: (change.id + this.getAleaChar()).toUpperCase(),
        competition_type: competition_type,
        category: category_code,
        people_id: change.id,
        name: people.name,
        shortname: people.shortname,
      };
      COMPETITOR.insert({ data: compChange});
      operationIds.push(SynchroHelper.LogOperation("INSERT", "COMPETITOR", null, compChange));

      var callResult = await SynchroHelper.TryToSynchronizeAsync(operationIds);
      return {
        success: callResult,
        people: people,
        competitor: COMPETITOR.query().where("id", compChange.id).first(),
      };
    },
    isValidLicenseeNumber(number){
      return (number && number!=null && number.length == 8 && number.matches(/^\d{7}[a-zA-Z]$/));
    },
    extend_competitor_categories(competitor_id, competition_type, category_code)
    {
      var competitor = COMPETITOR.query().where("id", competitor_id).first();
      var change = {
        id: uuid(),
        competition_type: competition_type,
        category: category_code,
        people_id: competitor.people_id,
        name: competitor.name,
        shortname: competitor.shortname
      };
      COMPETITOR.insert({ data: change});
      SynchroHelper.LogOperation("INSERT", "COMPETITOR", null, change);
      SynchroHelper.TryToSynchronize();
      return COMPETITOR.query().where("id", change.id).first();
    },
    create_new_individual_competitor_from_people(competition_type, category_code, people_id){
      var people = PEOPLE.query().where("id", people_id).first();

      var change = {
        id: (people_id + this.getAleaChar()).toUpperCase(),
        competition_type: competition_type,
        category: category_code,
        people_id: people_id,
        name: people.name,
        shortname: people.shortname,
      };
      COMPETITOR.insert({ data: change});
      SynchroHelper.LogOperation("INSERT", "COMPETITOR", null, change);
      SynchroHelper.TryToSynchronize();
      return COMPETITOR.query().where("id", change.id).first();
    },
    async updateIndividualCompetitorDataAsync(competitor_id, firstname, lastname, federal_number)
    {
      if(this.VERBOSE) console.log('updateIndividualCompetitorDataAsync', competitor_id, firstname, lastname, federal_number)
      var competitor = COMPETITOR.query().where("id", competitor_id).first();
      var peopleChange = {
        firstname: firstname,
        lastname: lastname,
        federalNumber: federal_number,
      };
      var operationIds = [];
      PEOPLE.update({ where: competitor.people_id, data: peopleChange});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "PEOPLE", competitor.people_id, peopleChange, 'CHANGE_COMPETITOR_DATA'));

      var allCompetitorsForThisPeople = COMPETITOR.query().where("people_id", competitor.people_id).get();
      allCompetitorsForThisPeople.forEach(aCompetitor => {
        var competitorChange = {
          name: (firstname + ' ' + lastname).trim(),
          shortname: (firstname + ' ' + lastname.slice(0,1) + '.').trim(),
        }
        COMPETITOR.update({ where: aCompetitor.id, data: competitorChange });
        operationIds.push(SynchroHelper.LogOperation("UPDATE", "COMPETITOR", aCompetitor.id, competitorChange, 'CHANGE_NAMES'));
      });
      return await SynchroHelper.TryToSynchronizeAsync(operationIds);
    },
    getNewTeamCompetitorId(name){
      if(!name || name == null || name == undefined || name.trim() == '')
        name = 'PairOrTeam';
      name = name.trim().replace(" ", "");
      var d = new Date();
      var y = ("" + d.getFullYear()).substring(2,4);
      var m = ("" + (d.getMonth()+1)).padStart(2, "0");
      if(this.VERBOSE) console.log(d, y, m);
      switch(name.length){
        case 1: return `${this.getAleaChar()}${name}${name}${name}${this.getAleaChar()}${y}${m}${this.getAleaChar()}`.toUpperCase();
        case 2: return `${this.getAleaChar()}${name}${this.getAleaChar()}${this.getAleaChar()}${y}${m}${this.getAleaChar()}`.toUpperCase();
        case 3: return `${this.getAleaChar()}${name}${this.getAleaChar()}${this.getAleaChar()}${y}${m}${this.getAleaChar()}`.toUpperCase();
        case 4: return `${this.getAleaChar()}${name}${y}${m}${this.getAleaChar()}`.toUpperCase();
      }
      return `${name.substring(0,1)}${name.substring(name.length - 3, name.length)}${y}${m}${this.getAleaChar()}`.toUpperCase();
    },
    async changeCompetitorIsOpenStatusAsync(competition_id, competitor_id, newIsOpenStatus){
      var competitor = RST_COMPETITOR.query().where("competition_id", competition_id).where("competitor_id", competitor_id).first();
      var change = {
        isOpen: newIsOpenStatus
      };
      RST_COMPETITOR.update({where: competitor.id, data: change});
      var operationId = SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR", competitor.id, change, 'CHANGE_ISOPEN_STATUS');
      return await SynchroHelper.TryToSynchronizeAsync([operationId]);
    },
    create_new_non_individual_competitor(competition_type, category_code, competitor_name, competitor_shortname){
      if(competitor_shortname == null || competitor_shortname.trim() == '')
        competitor_shortname = competitor_name;
      if(competitor_name == null || competitor_name.trim() == '')
        competitor_name = competitor_shortname;

      var change = {
        id: this.getNewTeamCompetitorId(competitor_name),
        competition_type: competition_type,
        category: category_code,
        name: competitor_name,
        shortname: competitor_shortname,
      };
      COMPETITOR.insert({ data: change});
      SynchroHelper.LogOperation("INSERT", "COMPETITOR", null, change);
      SynchroHelper.TryToSynchronize();
      return COMPETITOR.query().where("id", change.id).first();
    },
    async updateNonIndividualCompetitorDataAsync(competitor_id, name, shortname)
    {
      var change = {
        name: name,
        shortname: shortname,
      };
      var operationIds = [];
      COMPETITOR.update({ where: competitor_id, data: change});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "COMPETITOR", competitor_id, change, 'CHANGE_NAMES'));

      return await SynchroHelper.TryToSynchronizeAsync(operationIds);
    },
    attach_competitor(competitionId, refCompetitorId, level_code, category_code, subscription_order, isOpenStatus)
    {
      var newRecord = {
        id: uuid(),
        competition_id: competitionId,
        competitor_id: refCompetitorId,
        level: level_code,
        category: category_code,
        subscription_order: subscription_order,
        isOpen: isOpenStatus
      };
      RST_COMPETITOR.insertOrUpdate({ data: newRecord });
      SynchroHelper.LogOperation("INSERT", "RST_COMPETITOR", null, newRecord);
      this.manageCompetitorsRandomOrder(competitionId, level_code, category_code);
      SynchroHelper.TryToSynchronize();
    },
    async attach_competitor_Async(competitionId, refCompetitorId, level_code, category_code, subscription_order, isOpenStatus)
    {
      var operationIds = [];
      var newRecord = {
        id: uuid(),
        competition_id: competitionId,
        competitor_id: refCompetitorId,
        level: level_code,
        category: category_code,
        subscription_order: subscription_order,
        isOpen: isOpenStatus,
      };

      RST_COMPETITOR.insertOrUpdate({ data: newRecord });

      var refCateg = RST_REF_CATEGORY.query().where('competition_id', competitionId).where("level", level_code).where("category", category_code).first();
      if(refCateg == null)
      {
        refCateg = { id: uuid(), competition_id: competitionId, level: level_code, category: category_code,
          subscribers_count: RST_COMPETITOR.query().where('competition_id', competitionId).where('level', level_code).where('category', category_code).get().length,
          effective_competitors_count: RST_COMPETITOR.query().where('competition_id', competitionId).where('level', level_code).where('category', category_code).where(item => !(item.isWithdrawn || item.isAbsent || item.isForfeited)).get().length,
          withdraw_count: RST_COMPETITOR.query().where('competition_id', competitionId).where('level', level_code).where('category', category_code).where(item => item.isWithdrawn).get().length,
          forfeit_count: RST_COMPETITOR.query().where('competition_id', competitionId).where('level', level_code).where('category', category_code).where(item => item.isForfeited).get().length,
          absent_count: RST_COMPETITOR.query().where('competition_id', competitionId).where('level', level_code).where('category', category_code).where(item => item.isAbsent).get().length,
        };
        RST_REF_CATEGORY.insertOrUpdate({ data: refCateg });
        operationIds.push(SynchroHelper.LogOperation("INSERT", "RST_REF_CATEGORY", null, refCateg));
      } else {
        var updRefCateg = {
          id: refCateg.id,
          subscribers_count: RST_COMPETITOR.query().where('competition_id', competitionId).where('level', level_code).where('category', category_code).get().length,
          effective_competitors_count: RST_COMPETITOR.query().where('competition_id', competitionId).where('level', level_code).where('category', category_code).where(item => !(item.isWithdrawn || item.isAbsent || item.isForfeited)).get().length,
          withdraw_count: RST_COMPETITOR.query().where('competition_id', competitionId).where('level', level_code).where('category', category_code).where(item => item.isWithdrawn).get().length,
          forfeit_count: RST_COMPETITOR.query().where('competition_id', competitionId).where('level', level_code).where('category', category_code).where(item => item.isForfeited).get().length,
          absent_count: RST_COMPETITOR.query().where('competition_id', competitionId).where('level', level_code).where('category', category_code).where(item => item.isAbsent).get().length,
        };
        RST_REF_CATEGORY.insertOrUpdate({ data: updRefCateg }); // pas de synchro dans ce cas là car les propriétés sont également gérées côté serveur
      }

      operationIds.push(SynchroHelper.LogOperation("INSERT", "RST_COMPETITOR", null, newRecord));
      if(await SynchroHelper.TryToSynchronizeAsync(operationIds))
        return await this.manageCompetitorsRandomOrderAsync(competitionId, level_code, category_code);
      return false;
    },
    async attach_staff_async(competition_id, staff_id){
      var found = RST_COMPETITION_STAFF.query().where("competition_id", competition_id).where("staff_id", staff_id).first();
      if(found == null){
          var newRecord = {
            id: uuid(),
            competition_id: competition_id,
            staff_id: staff_id,
          };
          RST_COMPETITION_STAFF.insertOrUpdate({ data: newRecord})
          var operationsIds =[SynchroHelper.LogOperation("INSERT", "RST_COMPETITION_STAFF", null, newRecord, "ASSOC_STAFF_TO_COMPETITION")];
          return await SynchroHelper.TryToSynchronizeAsync(operationsIds);
      }
      return false;
    },
    async dettach_staff_Async(competition_id, staff_id){
      var found = RST_COMPETITION_STAFF.query().where("competition_id", competition_id).where("staff_id", staff_id).first();
      if(found != null){
        RST_COMPETITION_STAFF.delete(found.id);
        var operationIds = [SynchroHelper.LogOperation("DELETE", "RST_COMPETITION_STAFF", found.id, null)];
          return await SynchroHelper.TryToSynchronizeAsync(operationIds);
      }
      return false;
    },
    dettach_competitor(competitionId, refCompetitorId, level_code, category_code)
    {
      var found = RST_COMPETITOR.query().where("competition_id", competitionId).where("level", level_code).where("category", category_code).where("competitor_id", refCompetitorId).first();
      if(found != null)
      {
        if(this.VERBOSE) console.log('dettach_competitor found', found)
        RST_COMPETITOR.delete(found.id);
        SynchroHelper.LogOperation("DELETE", "RST_COMPETITOR", found.id, null);
        this.manageCompetitorsRandomOrder(competitionId, level_code, category_code);
        SynchroHelper.TryToSynchronize();
      }
    },
    async dettach_competitor_Async(competitionId, refCompetitorId, level_code, category_code)
    {
      var found = RST_COMPETITOR.query().where("competition_id", competitionId).where("level", level_code).where("category", category_code).where("competitor_id", refCompetitorId).first();
      if(found != null)
      {
        RST_COMPETITOR.delete(found.id);
        var operationId = SynchroHelper.LogOperation("DELETE", "RST_COMPETITOR", found.id, null);

        var refCateg = RST_REF_CATEGORY.query().where('competition_id', competitionId).where("level", level_code).where("category", category_code).first();
        if(refCateg != null)
        {
          var updRefCateg = {
            id: refCateg.id,
            subscribers_count: RST_COMPETITOR.query().where('competition_id', competitionId).where('level', level_code).where('category', category_code).get().length,
            effective_competitors_count: RST_COMPETITOR.query().where('competition_id', competitionId).where('level', level_code).where('category', category_code).where(item => !(item.isWithdrawn || item.isAbsent || item.isForfeited)).get().length,
            withdraw_count: RST_COMPETITOR.query().where('competition_id', competitionId).where('level', level_code).where('category', category_code).where(item => item.isWithdrawn).get().length,
            forfeit_count: RST_COMPETITOR.query().where('competition_id', competitionId).where('level', level_code).where('category', category_code).where(item => item.isForfeited).get().length,
            absent_count: RST_COMPETITOR.query().where('competition_id', competitionId).where('level', level_code).where('category', category_code).where(item => item.isAbsent).get().length,
          };
          RST_REF_CATEGORY.insertOrUpdate({ data: updRefCateg }); // pas de synchro dans ce cas là car les propriétés sont également gérées côté serveur
        }

        if(await SynchroHelper.TryToSynchronizeAsync([operationId])){
          return await this.manageCompetitorsRandomOrderAsync(competitionId, level_code, category_code);
        }
      }
      return true;
    },
    manageCompetitorsRandomOrder(competition_id, level_code, category_code)
    {
      // Calcul de l'aléa pour les compéteurs de la catégorie.
      var allCompetitors = RST_COMPETITOR.query()
        .where("competition_id", competition_id)
        .where("level", level_code)
        .where("category", category_code)
        .get();
      if(allCompetitors.length > 1)
      {
        allCompetitors.sort(() => Math.random() - 0.5);
      }
      for(var i = 0; i < allCompetitors.length; i ++)
      {
        var change = { random_order : i + 1};
        RST_COMPETITOR.update({ where: allCompetitors[i].id, data: change});
        SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR", allCompetitors[i].id, change, "CHANGE_RANDOM_ORDER");
      }
      SynchroHelper.TryToSynchronize();
    },
    async manageCompetitorsRandomOrderAsync(competition_id, level_code, category_code)
    {
      // Calcul de l'aléa pour les compéteurs de la catégorie.
      var allCompetitors = RST_COMPETITOR.query()
        .where("competition_id", competition_id)
        .where("level", level_code)
        .where("category", category_code)
        .get();
      if(allCompetitors.length > 1)
      {
        allCompetitors.sort(() => Math.random() - 0.5);
      }
      var ids = [];
      for(var i = 0; i < allCompetitors.length; i ++)
      {
        var change = { random_order : i + 1};
        RST_COMPETITOR.update({ where: allCompetitors[i].id, data: change});
        ids.push(SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR", allCompetitors[i].id, change, "CHANGE_RANDOM_ORDER"));
      }
      return await SynchroHelper.TryToSynchronizeAsync(ids);
    },
    changeCompetitor_withdraw(competitionId, refCompetitorId, value)
    {
      var competitor = RST_COMPETITOR.query()
        .where("competition_id", competitionId)
        .where("competitor_id", refCompetitorId)
        .where("round_number", null)
        .first();
      if(competitor == null)
        return false;
      var data = { isWithdrawn : value };
      RST_COMPETITOR.update({ where: competitor.id, data: data });
      SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR", competitor.id, data, 'CHANGE_WITHDRAW_STATE');
      SynchroHelper.TryToSynchronize();
    },
    async changeCompetitor_withdraw_Async(competitionId, refCompetitorId, value)
    {
      var competitor = RST_COMPETITOR.query()
        .where("competition_id", competitionId)
        .where("competitor_id", refCompetitorId)
        .where("round_number", null)
        .first();
      if(competitor == null)
        return false;
      if(competitor.isWithdrawn == value) return true;
      var data = { isWithdrawn : value, isSync : false };
      RST_COMPETITOR.update({ where: competitor.id, data: data });
      var operationId = SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR", competitor.id, data, 'CHANGE_WITHDRAW_STATE');
      var refCateg = RST_REF_CATEGORY.query().where('competition_id', competitionId).where("level", competitor.level).where("category", competitor.category).first();
      if(refCateg != null)
      {
        var updRefCateg = {
          id: refCateg.id,
          withdraw_count: refCateg.withdraw_count + (value ? 1 : -1),
        };
        RST_REF_CATEGORY.insertOrUpdate({ data: updRefCateg }); // pas de synchro dans ce cas là car les propriétés sont également gérées côté serveur
      }

      return await SynchroHelper.TryToSynchronizeAsync([operationId]);
    },
    changeCompetitor_forfeit(competitionId, refCompetitorId, value)
    {
      var competitor = RST_COMPETITOR.query()
        .where("competition_id", competitionId)
        .where("competitor_id", refCompetitorId)
        .where("round_number", null)
        .first();
      if(competitor == null)
        return false;
      var data = { isForfeited : value };
      RST_COMPETITOR.update({ where: competitor.id, data: data });
      SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR", competitor.id, data, 'CHANGE_FORFEIT_STATE');
      SynchroHelper.TryToSynchronize();
    },
    async changeCompetitor_forfeit_Async(competitionId, refCompetitorId, value)
    {
      var competitor = RST_COMPETITOR.query()
        .where("competition_id", competitionId)
        .where("competitor_id", refCompetitorId)
        .where("round_number", null)
        .first();
      if(competitor == null)
        return false;
      if(competitor.isForfeited == value)
        return true;
      var data = { isForfeited : value, isSync: false };
      RST_COMPETITOR.update({ where: competitor.id, data: data });
      var operationId = SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR", competitor.id, data, 'CHANGE_FORFEIT_STATE');
      var refCateg = RST_REF_CATEGORY.query().where('competition_id', competitionId).where("level", competitor.level).where("category", competitor.category).first();
      if(refCateg != null)
      {
        var updRefCateg = {
          id: refCateg.id,
          forfeit_count: refCateg.forfeit_count + (value ? 1 : -1),
        };
        RST_REF_CATEGORY.insertOrUpdate({ data: updRefCateg }); // pas de synchro dans ce cas là car les propriétés sont également gérées côté serveur
      }
    return await SynchroHelper.TryToSynchronizeAsync([operationId]);
    },
    changeCompetitor_presence(competitionId, refCompetitorId, value)
    {
      var competitor = RST_COMPETITOR.query()
        .where("competition_id", competitionId)
        .where("competitor_id", refCompetitorId)
        .where("round_number", null)
        .first();
      if(competitor == null)
        return false;
      var data = { isAbsent : value };
      RST_COMPETITOR.update({ where: competitor.id, data: data });
      SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR", competitor.id, data, 'CHANGE_PRESENCE_STATE');
      SynchroHelper.TryToSynchronize();
    },
    async changeCompetitor_presence_Async(competitionId, refCompetitorId, value)
    {
      var competitor = RST_COMPETITOR.query()
        .where("competition_id", competitionId)
        .where("competitor_id", refCompetitorId)
        .where("round_number", null)
        .first();
      if(competitor == null)
        return false;
      if(competitor.isAbsent == value)
        return true;
      var data = { isAbsent : value, isSync : false };
      RST_COMPETITOR.update({ where: competitor.id, data: data });
      var refCateg = RST_REF_CATEGORY.query().where('competition_id', competitionId).where("level", competitor.level).where("category", competitor.category).first();
      if(refCateg != null)
      {
        var updRefCateg = {
          id: refCateg.id,
          absent_count: refCateg.absent_count + (value ? 1 : -1),
        };
        RST_REF_CATEGORY.insertOrUpdate({ data: updRefCateg }); // pas de synchro dans ce cas là car les propriétés sont également gérées côté serveur
      }
      var operationId = SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR", competitor.id, data, 'CHANGE_PRESENCE_STATE');
      return await SynchroHelper.TryToSynchronizeAsync([operationId]);
    },
    async change_competitor_presenceStatusAsync(competitionId, refCompetitorId, newStatus)
    {
      var competitor = RST_COMPETITOR.query()
        .where("competition_id", competitionId)
        .where("competitor_id", refCompetitorId)
        .where("round_number", null)
        .first();
      if(competitor == null)
        return false;
      var data = { ...newStatus, isSync : false };
      RST_COMPETITOR.update({ where: competitor.id, data: data });
      var allCompetitors = RST_COMPETITOR.query().where('competition_id', competitionId).where("level", competitor.level).where("category", competitor.category).get();
      var refCateg = RST_REF_CATEGORY.query().where('competition_id', competitionId).where("level", competitor.level).where("category", competitor.category).first();
      if(refCateg != null)
      {
        var updRefCateg = {
          id: refCateg.id,
          absent_count: allCompetitors.filter(c => c.isAbsent).length, //refCateg.absent_count + (value ? 1 : -1),
          forfeit_count: allCompetitors.filter(c => c.isForfeited).length,
          withdraw_count: allCompetitors.filter(c => c.isWithdrawn).length,
        };
        RST_REF_CATEGORY.insertOrUpdate({ data: updRefCateg }); // pas de synchro dans ce cas là car les propriétés sont également gérées côté serveur
      }
      var operationId = SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR", competitor.id, data, 'CHANGE_PRESENCE_STATUS');
      return await SynchroHelper.TryToSynchronizeAsync([operationId]);

    },
    setFieldName(competition_id, field_number, newValue){
      var field = RST_FIELD.query().where("competition_id", competition_id).where("field_number", field_number).first();
      var data = { field_name: newValue};
      RST_FIELD.update({ where: field.id, data: data });
      SynchroHelper.LogOperation("UPDATE", "RST_FIELD", field.id, data, "CHANGE_NAME");
      SynchroHelper.TryToSynchronize();
    },
    setFieldSize(competition_id, field_number, size_A_NewValue, size_B_NewValue)
    {
      var field = RST_FIELD.query().where("competition_id", competition_id).where("field_number", field_number).first();
      var data = { size_A: size_A_NewValue, size_B: size_B_NewValue};
      RST_FIELD.update({ where: field.id, data: data });
      SynchroHelper.LogOperation("UPDATE", "RST_FIELD", field.id, data, "CHANGE_SIZE");
      SynchroHelper.TryToSynchronize();
    },
    setFieldIsSonorized(competition_id, field_number, newValue){
      var field = RST_FIELD.query().where("competition_id", competition_id).where("field_number", field_number).first();
      var data = { isSonorized: newValue};
      RST_FIELD.update({ where: field.id, data: data });
      SynchroHelper.LogOperation("UPDATE", "RST_FIELD", field.id, data, "CHANGE_IS_SONORIZED");
      SynchroHelper.TryToSynchronize();
    },
    setFieldStageIn(competition_id, field_number, newValue){
      var field = RST_FIELD.query().where("competition_id", competition_id).where("field_number", field_number).first();
      var data = { stage_in: newValue};
      RST_FIELD.update({ where: field.id, data: data });
      SynchroHelper.LogOperation("UPDATE", "RST_FIELD", field.id, data, "CHANGE_STAGE_IN");
      SynchroHelper.TryToSynchronize();
    },
    async setFieldStageInAsync(competition_id, field_number, newValue){
      var field = RST_FIELD.query().where("competition_id", competition_id).where("field_number", field_number).first();
      var data = { stage_in: newValue};
      RST_FIELD.update({ where: field.id, data: data });
      var operationId = SynchroHelper.LogOperation("UPDATE", "RST_FIELD", field.id, data, "CHANGE_STAGE_IN");
      return await SynchroHelper.TryToSynchronizeAsync([operationId]);
    },
    setFieldStageOut(competition_id, field_number, newValue){
      var field = RST_FIELD.query().where("competition_id", competition_id).where("field_number", field_number).first();
      var data = { stage_out: newValue};
      RST_FIELD.update({ where: field.id, data: data });
      SynchroHelper.LogOperation("UPDATE", "RST_FIELD", field.id, data, "CHANGE_STAGE_OUT");
      SynchroHelper.TryToSynchronize();
    },
    async setFieldStageOutAsync(competition_id, field_number, newValue){
      var field = RST_FIELD.query().where("competition_id", competition_id).where("field_number", field_number).first();
      var data = { stage_out: newValue};
      RST_FIELD.update({ where: field.id, data: data });
      var operationId = SynchroHelper.LogOperation("UPDATE", "RST_FIELD", field.id, data, "CHANGE_STAGE_OUT");
      return await SynchroHelper.TryToSynchronizeAsync([operationId]);
    },
    setFieldHotState(competition_id, field_number, newValue){
      var field = RST_FIELD.query().where("competition_id", competition_id).where("field_number", field_number).first();
      var data = { isHot: newValue};
      RST_FIELD.update({ where: field.id, data: data });
      SynchroHelper.LogOperation("UPDATE", "RST_FIELD", field.id, data, "CHANGE_HOT_STATE");
      SynchroHelper.TryToSynchronize();
    },
    async setFieldHotStateAsync(competition_id, field_number, newValue){
      var field = RST_FIELD.query().where("competition_id", competition_id).where("field_number", field_number).first();
      var data = { isHot: newValue};
      RST_FIELD.update({ where: field.id, data: data });
      var operationId = SynchroHelper.LogOperation("UPDATE", "RST_FIELD", field.id, data, "CHANGE_HOT_STATE");
      return await SynchroHelper.TryToSynchronizeAsync([operationId]);
    },
    async changeLicenceInfoAsync(federal_number, year, isValid, isCompetitor, isMedicalCertificateValid)
    {
      var operationId = null;
      var licence = LICENSEE.query().where("federal_number", federal_number).where("year", year).first();
      var data = {
        isValid: isValid,
        isCompetitor: isCompetitor,
        isMedicalCertificateValid: isMedicalCertificateValid        
      }
      if(licence == null){
        data = {...data, 
          id: uuid(),
          federal_number: federal_number,
          year: year,
        };
        LICENSEE.insertOrUpdate({ data: data });
        operationId = SynchroHelper.LogOperation("INSERT", "LICENSEE", null, data);
      }else{
        LICENSEE.update({ where: licence.id, data });
        operationId = SynchroHelper.LogOperation("UPDATE", "LICENSEE", licence.id, data, "CHANGE_HOT_STATE");
      }
      return await SynchroHelper.TryToSynchronizeAsync([operationId]);
    },

    async replace_membersAsync(competition_id, competitor_id, round_number, members){
      var existings = RST_COMPETITOR_COMPOSITION.query().where("competition_id", competition_id).where("competitor_id", competitor_id).where(cc => cc.round_number == round_number).get();
      members = members.map((m, i) => { 
                    var existing = existings.find(e => e.people_id === m.people_id);
                     return {...m, existing: existing, memberOrder: i+1};
                  });
      var toIns = members.filter(m => !m.existing ).map(m => { return {...m, id: uuid()}; });
      var foundMembers = members.filter(m => m.existing).map(m=> m.existing.id);
      var toDel = existings.filter(e => foundMembers.findIndex(m => m == e.id) < 0);
      var toUpd = members.filter(m => m.existing && m.memberOrder != m.existing.memberOrder);
      if(this.VERBOSE) console.log('replace_memberAsync', { competition_id: competition_id, competitor_id: competitor_id, round_number: round_number, members: members, existings: existings, toIns: toIns, foundMembers: foundMembers, toDel: toDel, toUpd: toUpd });
      var operationIds = [];
      toDel.forEach(del => {
        RST_COMPETITOR_COMPOSITION.delete(del.id);
          operationIds.push(SynchroHelper.LogOperation("DELETE", "RST_COMPETITOR_COMPOSITION", del.id));
          if(this.VERBOSE) console.log("del member with id", del.id);
      });
      toIns.forEach(ins => {
        var data = { 
          id: ins.id, 
          competition_id: competition_id, 
          competitor_id: competitor_id, 
          round_number: round_number, 
          people_id: ins.people_id, 
          memberOrder: ins.memberOrder
        };
        RST_COMPETITOR_COMPOSITION.insert({ data: data });
        operationIds.push(SynchroHelper.LogOperation("INSERT", "RST_COMPETITOR_COMPOSITION", null, data));
      });
      toUpd.forEach(upd => {
        var data = { memberOrder: upd.memberOrder };
        RST_COMPETITOR_COMPOSITION.update({ where: upd.id, data: data});
        operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_COMPETITOR_COMPOSITION", upd.id, data, "CHANGE_ORDER"));
      })
      return await SynchroHelper.TryToSynchronizeAsync(operationIds);
    },
    async changeLockForRegistrationAsync(competition_id, lockValue){
      var data = {
        isLockedForRegistration: lockValue
      };
      RST_COMPETITION.update({where: competition_id, data: data});
      var operationId = SynchroHelper.LogOperation("UPDATE", "RST_COMPETITION", competition_id, data, "CHANGE_LOCK_FOR_REGISTRATION");
      return await SynchroHelper.TryToSynchronizeAsync([operationId]);
    },
    async changeLockForSelectionAsync(competition_id, lockValue){
      var data = {
        isLockedForSelection: lockValue
      };
      RST_COMPETITION.update({where: competition_id, data:data});
      var operationId = SynchroHelper.LogOperation("UPDATE", "RST_COMPETITION", competition_id, data, "CHANGE_LOCK_FOR_SELECTION");
      return await SynchroHelper.TryToSynchronizeAsync([operationId]);
    },
    doesCompetitionHaveRoundWithBriefingDone(competition_id)
    {
      return RST_ROUND.query().where("competition_id", competition_id).where("")
    },
    doesLevelHaveCompetitors(competition_id, level_code)
    {
      return RST_COMPETITOR.query().where("competition_id", competition_id).where("level", level_code).exists();
    },
    TOCHECK_doesLevelHaveStaff(competition_id, level_code)
    {
      if(this.VERBOSE) console.warn('A REVOIR CETTE METHODE !')
      return RST_COMPETITION_STAFF.query().where("competition_id", competition_id).where("level", level_code).exists();
    },
    createLevel(competition_id, level_code){
      // Pour traiter cette demande on aura besoin de données situées au niveau de l'entité COMPETITION.
      // C'est également l'occasion de vérifier que la compétition est bien définie.
      var competition = RST_COMPETITION.query().where("id", competition_id ).first();
      if(competition == null)
        return null;
      // Ainsi que de la liste des categories possibles pour le niveau à partir de l'entité SEASON_COMPETITION_TYPE_CATEGORY_LEVEL
      // Tout d'abord, on doit inscrire (pour mémoire) l'association du niveau pour la compétition, que celle-ci possède ou non
      // des manches.
      var ajout = {
        id: uuid(),
        competition_id: competition_id,
        round_number: null,
        level: level_code,
      };
      RST_LEVEL.insertOrUpdate({ data : ajout });
      SynchroHelper.LogOperation("INSERT", "RST_LEVEL", null, ajout);
      // Ensuite, pour créer "efficacement" le niveau, il faut le faire pour l'ensemble des manches définies pour la compétition.
        // Et pour chacun de ces manches on associe dirrectement toutes les catégories possibles.

      SynchroHelper.TryToSynchronize();
      if(this.VERBOSE) console.log('CreateLevel à revoir !')
      return true;
    },

    // ***************************************************************************************
    // Supprime un niveau (et ces données connexes) d'une compétition, toute manche confondue.
    // ---------------------------------------------------------------------------------------
    TOCHECK_deleteLevel(competition_id, level_code){
      // Avant de supprimmer un niveau, il faut supprimer toutes les données qui s'y rattachent
      // On commence par les compétiteurs
      RST_COMPETITOR.query().where("competition_id", competition_id).where("level", level_code).get().forEach(competitor => {
        // et leur composition pour paire et les team
        RST_COMPETITOR_COMPOSITION.query().where("competition_id", competition_id).where("competitor_id", competitor.id).get().forEach(composition => {
          RST_COMPETITOR_COMPOSITION.delete(composition.id);
          SynchroHelper.LogOperation("DELETE", "RST_COMPETITOR_COMPOSITION", composition.id, null);
        });
        RST_COMPETITOR.delete(competitor.id);
        SynchroHelper.LogOperation("DELETE", "RST_COMPETITOR", competitor.id, null);
      });
      // On continue avec les membres de staff assignés au niveau dans le cadre d'épreuves
      RST_EVENT_STAFF.query().where("competition_id", competition_id).where("level", level_code).get().forEach(staff => {
        RST_EVENT_STAFF.delete(staff.id);
        SynchroHelper.LogOperation("DELETE", "RST_COMPETITION_STAFF", staff.id, null);
      });
      // Puis on s'occupe des épreuves définies pour ce niveau
      RST_REF_EVENT.query().where("competition_id", competition_id).where("level", level_code).get().forEach(eventType => {
        RST_REF_EVENT.delete(eventType.id);
        SynchroHelper.LogOperation("DELETE", "RST_REF_EVENT", eventType.id, null);
      })
      RST_EVENT.query().where("competition_id", competition_id).where("level", level_code).get().forEach(event => {
        RST_EVENT.delete(event.id);
        SynchroHelper.LogOperation("DELETE", "RST_EVENT", event.id, null);
      });
      // Viennent ensuite les catégories
      RST_CATEGORY.query().where("competition_id", competition_id).where("level", level_code).get().forEach(category => {
        RST_CATEGORY.delete(category.id);
        SynchroHelper.LogOperation("DELETE", "RST_CATEGORY", category.id, null);
      });
      // Pour finir par le niveau proprement dit
      RST_LEVEL.query().where("competition_id", competition_id).where("level", level_code).get().forEach(level => {
        RST_LEVEL.delete(level.id);
        SynchroHelper.LogOperation("DELETE", "RST_LEVEL", level.id, null);
      });
      SynchroHelper.TryToSynchronize();
      if(this.VERBOSE) console.log('deleteLevel à revoir');
      return true;
    },

    async launchCompetitionAsync(competition_id)
    {
      if(this.VERBOSE) ("Starting launchCompetitionAsync");
      return Promise.all([
        this.refreshGeneralData(competition_id),
        this.refreshCompetitors(competition_id),
        this.refreshFields(competition_id),
        this.refreshStaffMembers(competition_id),
        this.refreshEvents(competition_id),
        this.refreshNotationData(competition_id),
      ]).then(() => {
        if(this.VERBOSE) console.log('READY TO LAUCH COMPETITION !');
        return true;
      }).catch((error) => {
        if(this.VERBOSE) console.error('launchCompetitionAysnc => error', error);
        throw(error);
      });
    },

    /// [OBSOLETE => RoundsConfigurationHelper.createRefEventAsync]
    async createEventAsync(competition_id, event_code, level_code, displayOrder = 1){
      var operationsIds = [];
      // Pour traiter cette demande on aura besoin de données situées au niveau de l'entité COMPETITION.
      // C'est également l'occasion de vérifier que la compétition est bien définie.
      var competition = RST_COMPETITION.query().where("id", competition_id ).first();
      if(competition == null)
        throw("La compétition n'existe pas!");
      // De même pour le niveau (Level)
      var level = RST_LEVEL.query().where("competition_id", competition_id).where("level", level_code).first();
      if(level == null)
        throw("Le niveau n'existe pas pour cette compétition");
      // De plus, il va falloir attribuer un numéro de manche "temporaire par défaut" à l'événement en incrémentant de 1
      // le numéro de manche de la précédente épreuve portant sur le même niveau et le même type d'épreuve dans la même compétition
      var sameEvents = RST_REF_EVENT.query().where("competition_id", competition_id).where("level", level_code).where("event", event_code).get();
      var round_number = 1;
      if(sameEvents && sameEvents.length > 0){
        sameEvents.sort((a,b) => { return b.round_number - a.round_number});
        round_number = sameEvents[0].round_number + 1;
      }
      var refEvt = REF_EVENT.query().where("code", event_code).first();
      if(this.VERBOSE) console.log('refEvt', refEvt);
      var similarEvents = RST_REF_EVENT.query().where("competition_id", competition_id).where("level", level_code)
                            .where(e => e.EVENT.hasCompulsories == refEvt.hasCompulsories || e.EVENT.hasRoutine == refEvt.hasRoutine || e.EVENT.hasBallet == refEvt.hasBallet)
                            .get()
                            .sort((a,b) => b.round_number - a.round_number);
      if(this.VERBOSE) console.log('similarEvents', { refEvt: refEvt, similarEvents: similarEvents});
      if(similarEvents && similarEvents.length > 0)
        round_number = similarEvents[0].round_number + 1;

      var ajout = {
        id: uuid(),
        competition_id: competition_id,
        round_number: round_number,
        level: level_code,
        event: event_code,
        displayOrder: displayOrder,
      };
      await this.refreshRounds(competition_id);
      if(!RST_ROUND.query().where("competition_id", competition_id).where("round_number", round_number).exists()){
        var roundAjout = {
          id: uuid(),
          competition_id: competition_id,
          round_number: round_number,
        };
        RST_ROUND.insertOrUpdate({ data : roundAjout });
        operationsIds.push(SynchroHelper.LogOperation("INSERT", "RST_ROUND", null, roundAjout));
      }
      RST_REF_EVENT.insertOrUpdate({ data : ajout });
      operationsIds.push(SynchroHelper.LogOperation("INSERT", "RST_REF_EVENT", null, ajout));
      return await SynchroHelper.TryToSynchronizeAsync(operationsIds);
    },

    // ***************************************************************************************
    // Supprime un niveau (et ces données connexes) d'une compétition, toute manche confondue.
    // ---------------------------------------------------------------------------------------
    /// [OBSOLETE => RoundsConfigurationHelper.deleteRefEventAsync]
    async deleteEventAsync(competition_id, event_code, level_code){
      // L'objectif, ici, est de supprimer la dernière épreuve créée pour ce niveau, type d'épreuve et compéition
      // et à condition qu'elle ne soit pas commencée ou terminée.
      // Pour assurer ces vérifications, on commence par rechercher toutes les épreuves correspondant à nos critères
      var sameEvents = RST_REF_EVENT.query().where("competition_id", competition_id).where("level", level_code).where("event", event_code).get();
      // puis on les tri par ordre décroissant du numéro de manche
      if(sameEvents && sameEvents.length > 0){
        sameEvents.sort((a,b) => { return b.round_number - a.round_number});
        // On vérifie maintenant l'état de la première épreuve de la liste (donc celle avec le plus fort n° de manche)
        if(sameEvents[0].isCompleted)
          throw ("Impossible de supprimer la dernière épreuve car elle est terminée");
        if(sameEvents[0].isStarted || sameEvents[0].isCompleted)
          throw ("Impossible de supprimer la dernière épreuve car elle est commencée");
        RST_REF_EVENT.delete(sameEvents[0].id);
        if(this.VERBOSE) console.log('After delete', RST_REF_EVENT.query().where('competition_id', competition_id).where('event', event_code).where('level', level_code).get());
        var operationsIds = [];
        operationsIds.push(SynchroHelper.LogOperation("DELETE", "RST_REF_EVENT", sameEvents[0].id, null));
        if(!RST_REF_EVENT.query().where("competition_id", competition_id).where("round_number", sameEvents[0].round_number).exists())
        {
          var rnd = RST_ROUND.query().where("competition_id", competition_id).where("round_number", sameEvents[0].round_number).first();
          if(rnd != null)
          {
            RST_ROUND.delete(rnd.id);
            operationsIds.push(SynchroHelper.LogOperation("DELETE", "RST_ROUND", rnd.id, null));
          }
        }
        return await SynchroHelper.TryToSynchronizeAsync(operationsIds);
      } else {
        throw ("Impossible de retrouver l'épreuve correspondant aux critères");
      }
    },

    async addDelegateAsync(competition_id, people_id, delegate_type='C'){
      if(this.VERBOSE) console.log('addDelegateAsync', competition_id, people_id, delegate_type);
      var operationsIds = [];
      // Pour traiter cette demande on aura besoin de données situées au niveau de l'entité COMPETITION.
      // C'est également l'occasion de vérifier que la compétition est bien définie.
      var competition = RST_COMPETITION.query().where("id", competition_id ).first();
      if(competition == null)
        throw("La compétition n'existe pas!");
      // De même pour le niveau (Level)
      var people = PEOPLE.query().where("id", people_id).first();
      if(people == null)
        throw("La personne n'existe pas!");
      // De plus la délégation ne doit pas déjà exister
      if(RST_DELEGATE.query().where("competition_id", competition_id).where('people_id', people_id).where("delegate_type", delegate_type).exists())
        throw("Cette délégation existe déjà!");
      var ajout = {
        id: uuid(),
        competition_id: competition_id,
        people_id: people_id,
        delegate_type: delegate_type
      };
      RST_DELEGATE.insertOrUpdate({ data: ajout })
      operationsIds.push(SynchroHelper.LogOperation("INSERT", "RST_DELEGATE", null, ajout));
      return await SynchroHelper.TryToSynchronizeAsync(operationsIds);
    },
    async removeDelegateAsync(competition_id, people_id, delegate_type='C'){
      var operationIds=[];
      RST_DELEGATE.query().where("competition_id", competition_id).where("people_id", people_id).where("delegate_type", delegate_type).get().forEach(delegate => {
        RST_DELEGATE.delete(delegate.id);
        operationIds.push(SynchroHelper.LogOperation("DELETE", "RST_DELEGATE", delegate.id, null));
      });
      return await SynchroHelper.TryToSynchronizeAsync(operationIds);
    },
    async addAdminAsync(competition_id, visaAdmin){
      var operationsIds = [];
      // Pour traiter cette demande on aura besoin de données situées au niveau de l'entité COMPETITION.
      // C'est également l'occasion de vérifier que la compétition est bien définie.
      var competition = RST_COMPETITION.query().where("id", competition_id ).first();
      if(competition == null)
        throw("La compétition n'existe pas!");
      // De même pour le niveau (Level)
       // De plus la délégation ne doit pas déjà exister
      if(RST_COMPETITION_ADMIN.query().where("competition_id", competition_id).where('admin', visaAdmin).exists())
        throw("Cette personne a déjà été désignée comme administrateur de la compétition!");
      var ajout = {
        id: uuid(),
        competition_id: competition_id,
        visa: visaAdmin,
      };
      RST_COMPETITION_ADMIN.insertOrUpdate({ data: ajout })
      operationsIds.push(SynchroHelper.LogOperation("INSERT", "RST_COMPETITION_ADMIN", null, ajout));
      return SynchroHelper.TryToSynchronizeAsync(operationsIds);
    },
    async removeAdminAsync(competition_id, visaAdmin){
      if(this.VERBOSE) console.log('removeAdminAsync', competition_id, visaAdmin);
      var operationIds = [];
      RST_COMPETITION_ADMIN.query().where("competition_id", competition_id).where("admin", visaAdmin).get().forEach(admin => {
        if(this.VERBOSE) console.log('delete', admin);
        RST_COMPETITION_ADMIN.delete(admin.id);
        operationIds.push(SynchroHelper.LogOperation("DELETE", "RST_COMPETITION_ADMIN", admin.id, null));
      });
      return SynchroHelper.TryToSynchronizeAsync(operationIds);
    },
    async changeOwnerAsync(competition_id, visaOwner){
      // Pour traiter cette demande on aura besoin de données situées au niveau de l'entité COMPETITION.
      // C'est également l'occasion de vérifier que la compétition est bien définie.
      var competition = RST_COMPETITION.query().where("id", competition_id ).first();
      if(competition == null)
        throw("La compétition n'existe pas!");
      var data = {
        owner: visaOwner
      };
      RST_COMPETITION.update({where: competition_id, data: data});
      var operationId = SynchroHelper.LogOperation("UPDATE", "RST_COMPETITION", competition_id, data, "CHANGE_OWNER");
      return await SynchroHelper.TryToSynchronizeAsync([operationId]);
    },

    async getCompetitionsResults(competitionType_Code, forTestMode)
    {
      if(forTestMode == null)
      {
        forTestMode = store.getters["auth/userHasRole"]( RolesEnum.Tester );
      }
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/getCompetitionsResultsList.php';
      try{
        const response = await axios.post(url, { testMode: forTestMode, competitionType: competitionType_Code });
        if(this.VERBOSE) console.log('getCompetitionsResults =>', response.data);
        return response.data.competitionsList;
      }
      catch(error){
        if(this.VERBOSE) console.error('getCompetitionsResults ERROR => ' + error);
      }
    },

    async refreshGeneralData(competition_id){
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/competitions/getGeneralData.php';
      try{
        const response = await axios.post(url, { competition_id: competition_id });
        if(this.VERBOSE) console.log('refreshGeneralData =>', response);
        if(response.data && response.data.competition){
          var alreadySync = RST_COMPETITION.query().where("competition_id", competition_id).where("isSync", true).get();
          alreadySync.forEach(item => { RST_COMPETITION.delete(item.id)});
          RST_COMPETITION.insertOrUpdate({ data: response.data.competition });
          return true;
        }
        return false;
      }
      catch(error){
        if(this.VERBOSE) console.error('refreshGeneralData => error : ', error);
        return false;
      }
    },

    async refreshLevels(competition_id){
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/competitions/getLevels.php';
      try{
        const response = await axios.post(url, { competition_id: competition_id });
        if(this.VERBOSE) console.log('refreshLevels =>', response);
        if(response.data && response.data.levels ){
          var alreadySync = RST_LEVEL.query().where("competition_id", competition_id).where("isSync", true).get();
          alreadySync.forEach(item => { RST_LEVEL.delete(item.id)});
          RST_LEVEL.insertOrUpdate({ data: response.data.levels });
          return true;
        }
        return false
      }
      catch(error){
        if(this.VERBOSE) console.error('refreshLevels => error : ', error);
        return false;
      }
    },

    async refreshLevelsAsync(competition_id, includeCategories=false, includeCompetitors=false, includeComposition=false){
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/competitions/getLevels.php';
      try{
        const response = await axios.post(url, { competition_id: competition_id, inclucde_categories: includeCategories, include_competitors: includeCompetitors, include_competitors_composition: includeComposition });
        if(this.VERBOSE) console.log('refreshLevels =>', response);
        if(response.data){
          var alreadySync = [];
          if(response.data.levels ){
            alreadySync = RST_LEVEL.query().where("competition_id", competition_id).where("isSync", true).get();
            alreadySync.forEach(item => { RST_LEVEL.delete(item.id)});
            RST_LEVEL.insertOrUpdate({ data: response.data.levels });
          }
          if(response.data.categories){
            alreadySync = RST_REF_CATEGORY.query().where("competition_id", competition_id).where("isSync", true).get();
            alreadySync.forEach(item => { RST_REF_CATEGORY.delete(item.id)});
            RST_REF_CATEGORY.insertOrUpdate({data: response.data.categories});
          }
          if(response.data.rst_competitors){
            alreadySync = RST_COMPETITOR.query().where("competition_id", competition_id).where("isSync", true).get();
            alreadySync.forEach(item => { RST_COMPETITOR.delete(item.id)});
            RST_COMPETITOR.insertOrUpdate({ data: response.data.rst_competitors});

            if(response.data.competitors){
              COMPETITOR.insertOrUpdate({ data: response.data.competitors})
            }

            if(response.data.rst_competitors_composition){
              alreadySync = RST_COMPETITOR_COMPOSITION.query().where("competition_id", competition_id).where("isSync", true).get();
              alreadySync.forEach(item => { RST_COMPETITOR_COMPOSITION.delete(item.id)});
              RST_COMPETITOR_COMPOSITION.insertOrUpdate({ data: response.data.rst_competitors_composition});
            }

            if(response.data.people){
              PEOPLE.insertOrUpdate({ data: response.data.people});
            }

            if(response.data.licensees){
              LICENSEE.insertOrUpdate({ data: response.data.licensees});
            }
          }
          return true;
        }
        return false
      }
      catch(error){
        if(this.VERBOSE) console.error('refreshLevels => error : ', error);
        return false;
      }
    },
    async refreshFields(competition_id)
    {
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/competitions/getFields.php';
      try{
        const response = await axios.post(url, { competition_id: competition_id });
        if(response.data && response.data.fields ){
          var alreadySync = RST_FIELD.query().where("competition_id", competition_id).where("isSync", true).get();
          alreadySync.forEach(fld => { RST_FIELD.delete(fld.id)});
          RST_FIELD.insertOrUpdate({data : response.data.fields});
          return true;
        }
        return false;
      }
      catch(error){
        if(this.VERBOSE) console.error('refreshFields ERROR ', error);
        return false;
      }
    },

    async refreshStaffMembersAync(competition_id)
    {
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/competitions/getStaffMembers.php';
      var metaUpdateKey = store.getters["synchro/getStaffUpdateKey"];
      try{
        const response = await axios.post(url, { competition_id: competition_id, staffUpdateKey: metaUpdateKey, });
        if(this.VERBOSE) console.log('refreshStaffMembers', response)
        if(response.data && response.data.staffMembers){
          var alreadySync = RST_COMPETITION_STAFF.query().where("competition_id", competition_id).where("isSync", true).get();
          alreadySync.forEach(item => { RST_COMPETITION_STAFF.delete(item.id)});
          RST_COMPETITION_STAFF.insertOrUpdate({data : response.data.staffMembers});
          return true;
        }
        return false;
      }
      catch(error){
        if(this.VERBOSE) console.error('refreshStaffMembers ERROR ', error);
        return false;
      }
    },

    async refreshCategories(competition_id)
    {
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/competitions/getCategoriesTypes.php';
      try{
        const response = await axios.post(url, { competition_id: competition_id });
        if(response.data && response.data.categories){
          var alreadySync = RST_REF_EVENT.query().where('competition_id', competition_id).where('isSync', true).get();
          alreadySync.forEach(item => { RST_REF_CATEGORY.delete(item.id)});
          if(this.VERBOSE) console.log('refreshCategories (alreadySync, response.data)', alreadySync, response.data)
          RST_REF_CATEGORY.insertOrUpdate({ data: response.data.categories})
          return true;
        }
        return false;
      }
      catch(error){
        if(this.VERBOSE) console.error('refreshCategories ERROR ', error);
        return false;
      }
    },

    async refreshLevelCategories(competition_id, level_code)
    {
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/competitions/getCategoriesTypes.php';
      try{
        const response = await axios.post(url, { competition_id: competition_id, level: level_code });
        if(response.data && response.data.categories){
          var alreadySync = RST_REF_EVENT.query().where('competition_id', competition_id).where('level', level_code).where('isSync', true).get();
          alreadySync.forEach(item => { RST_REF_CATEGORY.delete(item.id)});
          if(this.VERBOSE) console.log('refreshLevelCategories (alreadySync, response.data)', alreadySync, response.data)
          RST_REF_CATEGORY.insertOrUpdate({ data: response.data.categories})
          return true;
        }
        return false;
      }
      catch(error){
        if(this.VERBOSE) console.error('refreshLevelCategories ERROR ', error);
        return false;
      }
    },

    async refreshLevelCompetitors(competition_id, level){
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/competitions/getAllCompetitors.php';
      try{
        const response = await axios.post(url, { competition_id: competition_id, level: level });
        if(response.data && response.data.competitors){
          var alreadySync = RST_COMPETITOR.query().where('competition_id', competition_id).where('level', level).where('isSync', true).get();
          alreadySync.forEach(item => { RST_COMPETITOR.delete(item.id)});
          if(this.VERBOSE) console.log('refreshLevelCompetitors (alreadySync, response.data)', alreadySync, response.data)
          RST_COMPETITOR.insertOrUpdate({ data: response.data.competitors});
          alreadySync = RST_COMPETITOR_COMPOSITION.query()
            .where('competition_id', competition_id)
            .where(cc => cc.RST_COMPETITOR.level == level)
            .where('isSync', true).get();
          alreadySync.forEach(item => { RST_COMPETITOR_COMPOSITION.delete(item.id)});
          RST_COMPETITOR_COMPOSITION.insertOrUpdate({ data : response.data.competitorsComposition});

          if(!response.data.isLocked){
            var year = RST_COMPETITION.query().where("id", competition_id).first().year;
            alreadySync = LICENSEE.query().where("year", year).where("isSync", true).get();
            alreadySync.forEach(item => { LICENSEE.delete(item.id)});
          }
          LICENSEE.insertOrUpdate({ data: response.data.licensees });

          if(response.data.allPossibleCompetitors && response.data.allPossibleCompetitors.length > 0)
          {
            if(!response.data.isLocked){
              alreadySync = COMPETITOR.query().where("isSync", true).get();
              alreadySync.forEach(item => { COMPETITOR.delete(item.id)});
            }
            COMPETITOR.insertOrUpdate({ data: response.data.allPossibleCompetitors });
          }

          if(response.data.allPossiblePeople && response.data.allPossiblePeople.length > 0)
          {
            if(!response.data.isLocked){
              alreadySync = PEOPLE.query().where("isSync", true).get();
              alreadySync.forEach(item => { PEOPLE.delete(item.id)});
            }
            PEOPLE.insertOrUpdate({ data: response.data.allPossiblePeople });
          }
          
          return true;
        }
        return false;
      }
      catch(error){
        if(this.VERBOSE) console.error('refreshLevelCompetitor ERROR ', error);
        return false;
      }
    },
    async refreshLevelCategoryCompetitors(competition_id, level, category){
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/competitions/getCompetitors.php';
      try{
        const response = await axios.post(url, { competition_id: competition_id, level: level, category: category });
        if(response.data && response.data.competitors){
          var alreadySync = RST_COMPETITOR.query().where('competition_id', competition_id).where('level', level).where('category', category).where('isSync', true).get();
          alreadySync.forEach(item => { RST_COMPETITOR.delete(item.id)});
          if(this.VERBOSE) console.log('refreshLevelCategoryCompetitors (alreadySync, response.data)', alreadySync, response.data)
          RST_COMPETITOR.insertOrUpdate({ data: response.data.competitors})
          response.data.competitors.forEach(c => {
            alreadySync = RST_COMPETITOR_COMPOSITION.query().where('competition_id', competition_id).where('competitor_id', c.competitor_id).where('isSync', true).get();
            alreadySync.forEach(item => { RST_COMPETITOR_COMPOSITION.delete(item.id)});
          })
          RST_COMPETITOR_COMPOSITION.insertOrUpdate({ data : response.data.competitorsComposition});
          var year = RST_COMPETITION.query().where("id", competition_id).first().year;
          alreadySync = LICENSEE.query().where("year", year).where("isSync", true).get();
          alreadySync.forEach(item => { LICENSEE.delete(item.id)});
          LICENSEE.insertOrUpdate({ data: response.data.licensees });

          if(response.data.allPossibleCompetitors && response.data.allPossibleCompetitors.length > 0)
          {
            alreadySync = COMPETITOR.query().where("isSync", true).get();
            alreadySync.forEach(item => { COMPETITOR.delete(item.id)});
            COMPETITOR.insertOrUpdate({ data: response.data.allPossibleCompetitors });
          }

          if(response.data.allPossiblePeople && response.data.allPossiblePeople.length > 0)
          {
            alreadySync = PEOPLE.query().where("isSync", true).get();
            alreadySync.forEach(item => { PEOPLE.delete(item.id)});
            PEOPLE.insertOrUpdate({ data: response.data.allPossiblePeople });
          }
          
          return true;
        }
        return false;
      }
      catch(error){
        if(this.VERBOSE) console.error('refreshLevelCategoryCompetitor ERROR ', error);
        return false;
      }
    },

    async refreshCompetitors(competition_id){
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/competitions/getAllCompetitors.php';
      try{
        const response = await axios.post(url, { competition_id: competition_id });
        if(response.data && response.data.competitors){
          var alreadySync = RST_COMPETITOR.query().where('competition_id', competition_id).where('isSync', true).get();
          alreadySync.forEach(item => { RST_COMPETITOR.delete(item.id)});
          if(this.VERBOSE) console.log('refreshCompetitors (alreadySync, response.data)', alreadySync, response.data)
          RST_COMPETITOR.insertOrUpdate({ data: response.data.competitors})
          response.data.competitors.forEach(c => {
            alreadySync = RST_COMPETITOR_COMPOSITION.query().where('competition_id', competition_id).where('competitor_id', c.competitor_id).where('isSync', true).get();
            alreadySync.forEach(item => { RST_COMPETITOR_COMPOSITION.delete(item.id)});
          })
          RST_COMPETITOR_COMPOSITION.insertOrUpdate({ data : response.data.competitorsComposition});
          var year = RST_COMPETITION.query().where("id", competition_id).first().year;
          alreadySync = LICENSEE.query().where("year", year).where("isSync", true).get();
          alreadySync.forEach(item => { LICENSEE.delete([item.federal_number, item.year])});
          LICENSEE.insertOrUpdate({ data: response.data.licensees });
          return true;
        }
        return false;
      }
      catch(error){
        if(this.VERBOSE) console.error('refreshCompetitors ERROR ', error);
        return false;
      }
    },

    async refreshRounds(competition_id)
    {
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/competitions/getRounds.php';
      try{
        const response = await axios.post(url, { competition_id: competition_id });
        if(response.data && response.data.rounds){
          var alreadySync = RST_ROUND.query().where('competition_id', competition_id).where('isSync', true).get();
          alreadySync.forEach(item => { RST_ROUND.delete(item.id)});
          if(this.VERBOSE) console.log('refreshRounds (alreadySync, response.data)', alreadySync, response.data)
          RST_ROUND.insertOrUpdate({ data: response.data.rounds});
          alreadySync = RST_EVENT_COMPULSORY.query().where('competition_id', competition_id).where('isSync', true).get();
          alreadySync.forEach(item => { RST_EVENT_COMPULSORY.delete(item.id)});
          RST_EVENT_COMPULSORY.insertOrUpdate({ data: response.data.roundsCompulsories });
          return true;
        }
        return false;
      }
      catch(error){
        if(this.VERBOSE) console.error('refreshRounds ERROR ', error);
        return false;
      }
    },

    async refreshEvents(competition_id)
    {
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/competitions/getEventsTypes.php';
      try{
        const response = await axios.post(url, { competition_id: competition_id });
        if(response.data && response.data.events){
          var alreadySync = RST_REF_EVENT.query().where('competition_id', competition_id).where('isSync', true).get();
          alreadySync.forEach(item => { RST_REF_EVENT.delete(item.id)});
          if(this.VERBOSE) console.log('refreshEvents (alreadySync, response.data)', alreadySync, response.data)
          RST_REF_EVENT.insertOrUpdate({ data: response.data.events});
          return true;
        }
        return false;
      }
      catch(error){
        if(this.VERBOSE) console.error('refreshEvents ERROR ', error);
        return false;
      }
    },

    async refreshLauncherMapDataAsync(competition_id){
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/competitions/getDataForLauncherMap.php';
      try{
        const response = await axios.post(url, { competition_id: competition_id });
        if(this.VERBOSE) console.log('refrehsLauncherMapData =>', response);
          if(response.data && response.data.competition){
          // Traitement de la compétition
          var alreadySync = RST_COMPETITION.query().where("competition_id", competition_id).where("isSync", true).get();
          alreadySync.forEach(item => { RST_COMPETITION.delete(item.id)});
          RST_COMPETITION.insertOrUpdate({ data: response.data.competition });

          // Traitement des niveaux
          alreadySync = RST_LEVEL.query().where("competition_id", competition_id).where("isSync", true).get();
          alreadySync.forEach(item => { RST_LEVEL.delete(item.id)});
          RST_LEVEL.insertOrUpdate({ data: response.data.levels });

          // Traitement des Events (types)
          alreadySync = RST_REF_EVENT.query().where("competition_id", competition_id).where("isSync", true).get();
          alreadySync.forEach(item => { RST_REF_EVENT.delete(item.id)});
          RST_REF_EVENT.insertOrUpdate({ data: response.data.eventsTypes });

          // Traitement des catégories
          alreadySync = RST_REF_CATEGORY.query().where("competition_id", competition_id).where("isSync", true).get();
          alreadySync.forEach(item => { RST_REF_CATEGORY.delete(item.id)});
          RST_REF_CATEGORY.insertOrUpdate({ data: response.data.categories });

          // Traitement des épreuves déjà initiées
          alreadySync = RST_EVENT.query().where("competition_id", competition_id).where("isSync", true).get();
          alreadySync.forEach(item => { RST_EVENT.delete(item.id)});
          RST_EVENT.insertOrUpdate({ data: response.data.events });
          return true;
        }
        return false;
      }

      catch(error){
        return false;
      }
    },

    async refreshNotationData(competition_id){
      console.warn('refreshNotationData => NOT IMPLEMENTED', competition_id);
      return true;
    },

    async getCompetitionResult(competition_id)
    {
      console.warn('competitionConfigurationHelper.getCompetitionResult => NOT IMPLEMENTED', competition_id);
    },

    refreshEntity(entity, competition_id, newData)
    {
      var alreadySync = entity.query().where('competition_id', competition_id).where('isSync', true).get();
      alreadySync.forEach(item => entity.delete(item.id));
      entity.insertOrUpdate({ data: newData});
    },

    async refreshCompetition(competition_id){
      if(this.VERBOSE) console.log('refreshCompetition', competition_id);
      var baseUrl = store.getters["config/serverBaseUrl"];
      var url = baseUrl + '/api/competitions/getAllCompetitionData.php';
      try{
        const response = await axios.post(url, { competition_id: competition_id });
        if(response.data && response.data.competition){
          var alreadySync = RST_COMPETITION.query().where('competition_id', competition_id).where('isSync', true).first();
          var result = (!alreadySync || alreadySync == null);
          if(alreadySync){
            RST_COMPETITION.delete(competition_id);
            RST_COMPETITION.insertOrUpdate({data: response.data.competition});
          }
     
          this.refreshEntity(RST_BALLET_NOTE, competition_id, response.data.ballet_notes);
          this.refreshEntity(RST_COMPETITION_ADMIN, competition_id, response.data.admins );
          this.refreshEntity(RST_COMPETITION_STAFF, competition_id, response.data.staff_members);
          this.refreshEntity(RST_COMPETITOR, competition_id, response.data.competitors);
          this.refreshEntity(RST_COMPETITOR_COMPOSITION, competition_id, response.data.competitors_composition);
          this.refreshEntity(RST_COMPULSORY_NOTE, competition_id, response.data.compulsory_notes);
          this.refreshEntity(RST_DELEGATE, competition_id, response.data.delegates);
          this.refreshEntity(RST_EVENT, competition_id, response.data.events);
          this.refreshEntity(RST_EVENT_COMPETITOR, competition_id, response.data.events_competitors);
          this.refreshEntity(RST_EVENT_COMPULSORY, competition_id, response.data.events_compulsories);
          this.refreshEntity(RST_EVENT_STAFF, competition_id, response.data.events_staff);
          this.refreshEntity(RST_FIELD, competition_id, response.data.fields);
          this.refreshEntity(RST_LEVEL, competition_id, response.data.levels);
          this.refreshEntity(RST_REF_CATEGORY, competition_id, response.data.categories);
          this.refreshEntity(RST_REF_EVENT, competition_id, response.data.event_types);
          this.refreshEntity(RST_ROUND, competition_id, response.data.rounds);
          this.refreshEntity(RST_ROUND_COMPETITOR, competition_id, response.data.rounds_competitors);
          this.refreshEntity(RST_ROUTINE_NOTE, competition_id, response.data.routine_notes);

          return result;
        }
        return false;
      }
      catch(error){
        if(this.VERBOSE) console.error('refreshEvents ERROR ', error);
        return false;
      }
    }


}

export default CompetitionsConfigurationHelper;
