import Vue from 'vue'
import Router from 'vue-router'
import VueRouter from 'vue-router'
import { store } from '../store'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

var debugRoutes = false;

 Vue.use(Loading);

Vue.use(Router)

const redirectUserHome = (to, from, next) => {
  if (to.path === "/") {
    next();
  }
  next();
};

const routesDef = [
  { path: '/' , name:'Home', component: 'views/Home' },
  { path: '/dev-tools/test-api', name: 'TestApi', component: 'views/DevTools/TestApi'},
  { path: '/dev-tools/graphical-charter', name:'GraphicalCharter', component:'view/DevTools/GraphicalCharter'},
  { path: '/dev-tools/js-tips', name:'JsTips', component:'views/DevTools/JsTips'},
  { path: '/dev-tools/useful-links', name:'DevUsefulLinks', component:'views/DevTools/UsefulLinks'},
  { path: '/dev-tools/testings', name:'Testings', component:'views/DevTools/Testings'},
  { path: '/configuration', name:'ConfigurationHome', component:'views/Configuration/Index'},
  { path: '/access-denied', name:'AccessDenied', component:'views/AccessDenied'},
  { path: '/security/operations', name:'SecurityOperationsList', component:'views/Security/Operations/Index' },
  { path: '/security/operation/:id', name:'SecurityOperationEdit', component:'views/Security/Operations/Edit'},
  { path: '/security/users/list', name:'SecurityUsersList', component: 'views/Security/Users/Index'},
  { path: '/security/user/:id', name:'SecurityUserEdit', component: 'views/Security/Users/Edit'},
  { path: '/security/roles/list', name:'SecurityRolesList', component: 'views/Security/Roles/Index'},
  { path: '/security/roles/:id', name:'SecurityRoleEdit', component: 'views/Security/Roles/Edit'},
  { path: '/security/login', name:'login', component: 'views/Security/login'},
  { path: '/competitions-old/create', name:'CompetitionCreate', component: 'views/Competitions/CreateWizard'},
  { path: '/competitions-old/', name:'CompetitionsRunning', component:'views/Competitions/Running'},
  { path: '/competitions-old/edit', name:'CompetitionEdit', component:'views/Competitions/EditWizard'},
  { path: '/competitions-old/edit-or-run', name:'CompetitionEditOrRun', component:'views/Competitions/EditOrRun'},
  { path: '/competitions-old/view', name:'CompetitionView', component:'views/Competitions/View'},
  { path: '/competitions-old/results', name:'CompetitionResults', component:'views/Competitions/Results'},
  { path: '/competitions-old/round/Prepare', name: 'RoundPrepare', component:'views/Competitions/Round/Prepare'},
  { path: '/competitions-old/round/Briefing', name: 'RoundBriefing', component:'views/Competitions/Round/Briefing'},
  { path: '/competitions-old/round/Results', name: 'RoundResults', component:'views/Competitions/Round/Results'},
  { path: '/competitions-old/events/run', name:'EventRun', component:'views/Competitions/Events/Run'},
  { path: '/competitions-old/events/prepare', name:'EventPrepare', component:'views/Competitions/Events/PrepareWizard'},
  { path: '/competitions-old/events/results', name:'EventResults', component:'views/Competitions/Events/Results'},
  { path: '/championnat-de-france-cva', name:'CdfCVA', component:'views/ChampionnatDeFranceCVA/View'},
  { path: '/competitions/view', name: 'HomeRunningCompetitionDetailsView', component: 'views/HomeRunningCompetitionDetailsView'},
  { path: '/downloads', name: 'Downloads', component: 'views/Downloads'},
  { path: '/admin/staff-members/', name: 'StaffListAdmin', component: 'views/Administration/StaffList'},
  { path: '/admin/staff-member/', name: 'Staff', component: 'views/Administration/Staff'},
  { path: '/admin/master-data/global-vuew', name:'MasterDataGlobalView', component: 'views/Administration/MasterData/GlobalView'},
  { path: '/admin/season-compulsories/', name:'SeasonCompulsoriesAdmin', component: 'views/Administration/SeasonCompulsories'},
  { path: '/competitions/admin/', name:'compet-admin-list', component:'Pages/Competition/Admin/List'},
  { path: '/competitions/admin/create', name:'compet-admin-create', component:'Pages/Competition/Admin/Create'},
  { path: '/competitions/admin/:id/config', name:'compet-admin-config', component:'Pages/Competition/Admin/Config'},
  { path: '/competitions/admin/:id/staff', name:'compet-admin-staff', component:'Pages/Competition/Admin/StaffMembers'},
  { path: "/competitions/admin/:id/staff/new", name:'compet-admin-staff-new', component:'Pages/Competition/Admin/StaffmembersNew'},
  { path: '/competitions/admin/:id/:level', name:'compet-admin-competitors', component:'Pages/Competition/Admin/Competitors'},
].map(p => { return { path: p.path, name: p.name, componentPath: p.component, component: () => import(`@/${p.component}.vue`)}});



const buildedRoutes = routesDef;
if(debugRoutes) console.log(buildedRoutes);


let loader = null;
function hideLoader(){
  if(loader){
    loader.hide();
    loader = null;
  }
}
function showLoader(){
  return new Promise((accept) => {
    if(loader == null){
      loader = Vue.$loading.show({
        loader: 'dots',
        color: '#f58a0f',
        height: 200,
        width: 200,
        backgroundColor: "#6c6c6c",
        opacity: 0.5,
        enforceFocus: true,
        lockScroll: true,
      });
    }
    setTimeout(accept(true), 100);
  });
}

let refresher = null;
function hideRefresher(){
  if(refresher){
    refresher.hide();
    refresher = null;
  }
}

function showRefresher(){
  return new Promise((accept) => {
    if(refresher == null){
      refresher = Vue.$loading.show({
        loader: 'spinner',
        color: '#f58a0f',
        height: 100,
        width: 100,
        backgroundColor: "#6c6c6c",
        opacity: 0.5,
        enforceFocus: true,
        lockScroll: true,
      });
    }
    setTimeout(accept(true), 100);
  });
}

let saver = null;
function hideSaver(){
  if(saver){
    saver.hide();
    saver = null;
  }
}

function showSaver(){
  return new Promise((accept) => {
    if(saver == null){
      saver = Vue.$loading.show({
        loader: 'dots',
        color: '#ff0000',
        height: 100,
        width: 100,
        backgroundColor: "#6c6c6c",
        opacity: 0.5,
        enforceFocus: true,
        lockScroll: true,
      });
    }
    setTimeout(accept(true), 100);
  });
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: buildedRoutes,
  })

  router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth || (record.meta.requiresRole != null) )){
      if(store.getters['auth/isAuthenticated'] || store.getters["auth/noAuthRequiredOnThisDevice"]){
        if(to.meta.requiresRole != null && !store.getters['auth/userHasOneOfRoles'](to.meta.requiresRole)){
          Vue.prototype.$showLoader = showLoader;
          Vue.prototype.$hideLoader = hideLoader;
          Vue.prototype.$showRefresher = showRefresher;
          Vue.prototype.$hideRefresher = hideRefresher;
          Vue.prototype.$showSaver = showSaver;
          Vue.prototype.$hideSaver = hideSaver;

          Vue.prototype.showLoader = showLoader;
          Vue.prototype.hideLoader = hideLoader;
          Vue.prototype.showRefresher = showRefresher;
          Vue.prototype.hideRefresher = hideRefresher;
          Vue.prototype.showSaver = showSaver;
          Vue.prototype.hideSaver = hideSaver;

          next('/accessDenied');
        } else {
          Vue.prototype.$showLoader = showLoader;
          Vue.prototype.$hideLoader = hideLoader;
          Vue.prototype.$showRefresher = showRefresher;
          Vue.prototype.$hideRefresher = hideRefresher;
          Vue.prototype.$showSaver = showSaver;
          Vue.prototype.$hideSaver = hideSaver;

          Vue.prototype.showLoader = showLoader;
          Vue.prototype.hideLoader = hideLoader;
          Vue.prototype.showRefresher = showRefresher;
          Vue.prototype.hideRefresher = hideRefresher;
          Vue.prototype.showSaver = showSaver;
          Vue.prototype.hideSaver = hideSaver;

          next()
        }
      } else {
        Vue.prototype.$showLoader = showLoader;
        Vue.prototype.$hideLoader = hideLoader;
        Vue.prototype.$showRefresher = showRefresher;
        Vue.prototype.$hideRefresher = hideRefresher;
        Vue.prototype.$showSaver = showSaver;
        Vue.prototype.$hideSaver = hideSaver;

        Vue.prototype.showLoader = showLoader;
        Vue.prototype.hideLoader = hideLoader;
        Vue.prototype.showRefresher = showRefresher;
        Vue.prototype.hideRefresher = hideRefresher;
        Vue.prototype.showSaver = showSaver;
        Vue.prototype.hideSaver = hideSaver;

        next('/login');
      }
    } else {
      Vue.prototype.$showLoader = showLoader;
      Vue.prototype.$hideLoader = hideLoader;
      Vue.prototype.$showRefresher = showRefresher;
      Vue.prototype.$hideRefresher = hideRefresher;
      Vue.prototype.$showSaver = showSaver;
      Vue.prototype.$hideSaver = hideSaver;

      Vue.prototype.showLoader = showLoader;
      Vue.prototype.hideLoader = hideLoader;
      Vue.prototype.showRefresher = showRefresher;
      Vue.prototype.hideRefresher = hideRefresher;
      Vue.prototype.showSaver = showSaver;
      Vue.prototype.hideSaver = hideSaver;

      next();
    }
  })

  router.beforeResolve(redirectUserHome);

  // Ré-écriture de la méthode "push" du router pour éviter les erreurs de type 'NavigationDuplicated'.s
  const originalPush = Router.prototype.push;
  Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
      console.log('router.push error', err, 'for location', location);
      if (err.name !== 'NavigationDuplicated') throw err
    });
  }
  export default router
