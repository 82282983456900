<template>
    <div v-if="isDebug" >A DEVELOPPER : ContactMeInfoDialog</div>
</template>

<script>
import config from '@/services/store/modules/config';

export default {
    computed:{
        isDebug(){
            console.log('maritalStatusDialog isDebug', config);
            return config.environment == 'DEBUG';
        }
    }
}
</script>
