<template>
    <b-modal
      v-model="showDialog"
      centered
      :static="false"
      :lazy="true"
      size="sm"
      button-size="lg"
      ref="dialog"
    >
      <template #modal-title><capitalize>{{ $t('dialogs.login.title') }}</capitalize></template>
      <slot name="prepend"></slot>
      <fieldset class="fieldset">
          <b-form-group :label="$t('dialogs.login.username')">
              <b-form-input type="text" v-model="identifiant" @keypress="cleanError"/>
          </b-form-group>

          <b-form-group :label="$t('dialogs.login.password')">
              <b-form-input type="password" v-model="password" @keypress="cleanError"/>
          </b-form-group>

          <b-alert
            :show="lastLoginError != null && lastLoginError != ''"
            variant="danger"
          >{{ dispLastLoginError }}</b-alert>
      </fieldset>
      <slot name="postpend"></slot>
          <template #modal-footer="{cancel}">
            <b-button @click="cancel()" variant="secondary">{{ $t('button.cancel')}}</b-button>
            <b-button :disabled="!canLogin" @click="submitCredentials" variant="primary">{{ $t('dialogs.login.connect-button')}}</b-button>
          </template>
    </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Capitalize from '../Utilities/Capitalize.vue';

export default {
  components: { Capitalize },
    props:{ value: { type:Boolean, default:false }
    },
    data(){
        return {
            identifiant: '',
            password: '',
        }
    },
    computed: {
        ...mapGetters('auth', ['lastLoginError', 'isAuthenticated']),
        canLogin(){
            return this.identifiant.trim() != '' && this.password.trim() != '';
        },
        showDialog:{
          get(){
            return this.value && !this.isAuthenticated;
          },
          set(){
          }
        },
        dispLastLoginError(){
          var t = (this.lastLoginError != null && this.lastLoginError != '')
              ? this.$t("dialogs.login." + this.lastLoginError)
              : '';
           return (t == null || t == '') ? (this.lastLoginError + ' ?') : t;
        }
    },
    methods:{
        ...mapActions('auth', ['login']),
        ...mapMutations('auth', ['setLastLoginError']),
        async submitCredentials(){
         const user = { loginName: this.identifiant, password: this.password };
          if(!(await this.login(user))){
            if(this.lastLoginError == null || this.lastLoginError == '')
            this.hide();
          }
          else
            this.hide();
        },
        cleanError(){
            this.setLastLoginError('');
        },
        show(){
          this.$refs.dialog.show();
        },
        hide(){
          this.$refs.dialog.hide();
        },
    },
    mounted(){
        this.cleanError();
    }
}
</script>
